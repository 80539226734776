import React from "react";
import { useMutation, gql } from "@apollo/client";

import { IOrder } from "../../types/order";
import { Link } from "react-router-dom";

export const TEST_ID_PACKED_ORDER_BUTTON = "PackedOrderButton";

export const ORDER_PACKED_MUTATION = gql`
  mutation PackOrder($order: PackOrderInput!) {
    packOrder(order: $order)
  }
`;

interface NonKitOrderPackedCardProps {
  order: IOrder;
  onPacked: () => void;
}

const NonKitOrderPackedCard = (props: NonKitOrderPackedCardProps): JSX.Element => {
  const { order, onPacked } = props;
  const [confirmOrderIsPackedMutation, { loading }] = useMutation(ORDER_PACKED_MUTATION, {
    update(cache, { data: { confirmOrderIsPackedMutation } }) {
      cache.modify({
        fields: {
          getOrders(existingOrderRefs = []) {
            const newOrderRef = cache.writeQuery({
              query: ORDER_PACKED_MUTATION,
              data: confirmOrderIsPackedMutation,
            });
            return [...existingOrderRefs, newOrderRef];
          },
        },
      });
    },
  });

  const packedOrder = () => {
    confirmOrderIsPackedMutation({
      variables: {
        order: { orderId: String(order.orderId) },
      },
    }).then(() => {
      onPacked();
    });
  };

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box">
      <div className="field is-grouped">
        <div className="control">
          <button
            className={`button is-primary ${loading ? "is-loading" : ""}`}
            onClick={packedOrder}
            data-testid={TEST_ID_PACKED_ORDER_BUTTON}
          >
            Confirm Order is Packed
          </button>
        </div>

        <div className="control">
          <Link className="button is-danger is-inverted" to="/orders">
            Back to orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NonKitOrderPackedCard;
