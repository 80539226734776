export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function getPotSerialNumberFromBarcode(barcode: string): string {
  // The serial number after (21) is the last segment of a UDI barcode
  return barcode.split("(21)").pop() ?? barcode;
}

export const potSerialNumberRegex = /\d{2}P\d{5}$/;
