import React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

interface IGoBack extends RouteComponentProps {
  referrer: string;
  current: string;
  destinationComponent?: string;
}

const GoBack = (props: IGoBack) => {
  const history = useHistory();
  const { referrer, current, destinationComponent } = props;

  const goBack = () => {
    if (props.referrer) {
      const location = {
        pathname: referrer,
        state: { from: current, destinationComponent },
      };
      history.push(location);
    } else {
      history.goBack();
    }
  };

  return (
    <button className="button level-item is-success is-outlined" onClick={goBack}>
      <span className="icon is-small">
        <i className="fas fa-arrow-to-left"></i>
      </span>
      <span>Go back</span>
    </button>
  );
};

export default withRouter(GoBack);
