import React from "react";
import { LABEL_STYLE } from ".";
import { AddressFormInput } from "./AddressFormInput";

export const TEST_ID_ADDRESS_FIRST_NAME_INPUT = "AddressFirstNameInput";
export const TEST_ID_ADDRESS_LAST_NAME_INPUT = "AddressLastNameInput";
export const TEST_ID_ADDRESS_DEPT_INPUT = "AddressDeptInput";
export const TEST_ID_ADDRESS_LINE_1_INPUT = "AddressLine1Input";
export const TEST_ID_ADDRESS_LINE_2_INPUT = "AddressLine2Input";
export const TEST_ID_ADDRESS_TOWN_INPUT = "AddressTownInput";
export const TEST_ID_ADDRESS_COUNTY_INPUT = "AddressCountyInput";
export const TEST_ID_ADDRESS_POSTCODE_INPUT = "AddressPostcodeInput";

interface AddressFormComponentProps {
  firstName: string;
  lastName: string;
  dept: string;
  lineOne: string;
  lineTwo: string;
  town: string;
  county: string;
  postcode: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setDept: (value: string) => void;
  setLineOne: (value: string) => void;
  setLineTwo: (value: string) => void;
  setTown: (value: string) => void;
  setCounty: (value: string) => void;
  setPostcode: (value: string) => void;
}

export const AddressFormComponent = (props: AddressFormComponentProps): JSX.Element => {
  const {
    firstName,
    lastName,
    dept,
    lineOne,
    lineTwo,
    town,
    county,
    postcode,
    setFirstName,
    setLastName,
    setDept,
    setLineOne,
    setLineTwo,
    setTown,
    setCounty,
    setPostcode,
  } = props;
  return (
    <>
      <div className="field is-horizontal">
        <div className="field-label is-normal" style={LABEL_STYLE}>
          <label className="label mb-3" htmlFor="delivery-address">
            Delivery address:
          </label>
        </div>
        <div className="field-body" id="delivery-address">
          {/* Left blank for correct spacing */}
        </div>
      </div>

      <AddressFormInput
        labelName="First Name"
        value={firstName}
        required={false}
        onChange={(event) => {
          setFirstName(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_FIRST_NAME_INPUT}
      />
      <AddressFormInput
        labelName="Last Name"
        value={lastName}
        required={true}
        onChange={(event) => {
          setLastName(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_LAST_NAME_INPUT}
      />
      <AddressFormInput
        labelName="Department"
        value={dept}
        required={false}
        onChange={(event) => {
          setDept(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_DEPT_INPUT}
      />
      <AddressFormInput
        labelName="Building and street"
        value={lineOne}
        required={true}
        onChange={(event) => {
          setLineOne(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_LINE_1_INPUT}
      />
      <AddressFormInput
        labelName=""
        value={lineTwo}
        required={false}
        onChange={(event) => {
          setLineTwo(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_LINE_2_INPUT}
      />
      <AddressFormInput
        labelName="Town or city"
        value={town}
        required={true}
        onChange={(event) => {
          setTown(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_TOWN_INPUT}
      />
      <AddressFormInput
        labelName="County"
        value={county}
        required={false}
        onChange={(event) => {
          setCounty(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_COUNTY_INPUT}
      />
      <AddressFormInput
        labelName="Postcode"
        value={postcode}
        required={true}
        onChange={(event) => {
          setPostcode(event.target.value);
        }}
        dataTestId={TEST_ID_ADDRESS_POSTCODE_INPUT}
      />
    </>
  );
};
