import React, { useState } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { IOrder, OrderStatus } from "../../types/order";
import OrderListCard from "./OrderListCard";
import ListPage from "../Common/ListPage";
import FilterSelector, { Filter } from "../../atoms/FilterSelector";
import { useUrlQuery } from "../../helpers/query";
import { sortOrdersByDate } from "../../helpers/sort";

export const TEST_ID_NEW_ORDER_BUTTON = "NewOrderButton";
export const TEST_ID_ORDER_LIST_PAGE_WRAPPER = "OrderListPageWrapper";
export const TEST_ID_NEW_ORDERS_LIST = "NewOrdersListPanel";
export const TEST_ID_CURRENT_ORDERS_LIST = "CurrentOrdersListPanel";

export const ORDERS_LIST_QUERY = gql`
  query(
    $showUnacknowledged: Boolean!
    $showOpen: Boolean!
    $showPartiallyFulfilled: Boolean!
    $showFulfilled: Boolean!
    $showShipped: Boolean!
    $showClosed: Boolean!
    $showError: Boolean!
  ) {
    pendingOrders: getOrders(status: ${OrderStatus.UNKNOWN}) {
      __typename
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      status
      shippingDeadline
      healthcareProvider {
        name
      }
    }
    unacknowledgedOrders: getOrders(status: ${OrderStatus.UNACKNOWLEDGED})
      @include(if: $showUnacknowledged) {
      __typename
      orderId
      slug
      oesophagealCellSampleCollectionKitQuantity
      status
      shippingDeadline
      healthcareProvider {
        name
      }
    }
    openOrders: getOrders(status: ${OrderStatus.OPEN}) @include(if: $showOpen) {
      __typename
      orderId
      slug
      oesophagealCellSampleCollectionKitQuantity
      status
      shippingDeadline
      healthcareProvider {
        name
      }
    }
    partiallyFulfilledOrders: getOrders(status: ${OrderStatus.PARTIALLY_FULFILLED})
      @include(if: $showPartiallyFulfilled) {
      __typename
      orderId
      slug
      oesophagealCellSampleCollectionKitQuantity
      status
      shippingDeadline
      healthcareProvider {
        name
      }
    }
    fulfilledOrders: getOrders(status: ${OrderStatus.FULFILLED}) @include(if: $showFulfilled) {
      __typename
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      status
      shippingDeadline
      healthcareProvider {
        name
      }
    }
    shippedOrders: getOrders(status: ${OrderStatus.SHIPPED}) @include(if: $showShipped) {
      __typename
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      shippingDeadline
      status
      healthcareProvider {
        name
      }
    }
    closedOrders: getOrders(status: ${OrderStatus.CLOSED}) @include(if: $showClosed) {
      __typename
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      shippingDeadline
      status
      healthcareProvider {
        name
      }
    }
    erroredOrders: getOrders(status: ${OrderStatus.ERROR}) @include(if: $showError) {
      __typename
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      shippingDeadline
      status
      healthcareProvider {
        name
      }
    }
  }
`;

interface QueryIface {
  showUnacknowledged: boolean;
  showOpen: boolean;
  showPartiallyFulfilled: boolean;
  showFulfilled: boolean;
  showShipped: boolean;
  showClosed: boolean;
  showError: boolean;
}

const DEFAULT_FILTERS: Filter[] = [
  {
    value: OrderStatus.UNACKNOWLEDGED,
    name: "Unacknowledged",
    isActive: true,
  },
  {
    value: OrderStatus.OPEN,
    name: "Open Orders",
    isActive: true,
  },
  {
    value: OrderStatus.PARTIALLY_FULFILLED,
    name: "Orders in Progress",
    isActive: true,
  },
  {
    value: OrderStatus.FULFILLED,
    name: "Orders Ready for Shipping",
    isActive: true,
  },
  {
    value: OrderStatus.SHIPPED,
    name: "Shipped Orders",
    isActive: false,
  },
  {
    value: OrderStatus.CLOSED,
    name: "Closed Orders",
    isActive: false,
  },
  {
    value: OrderStatus.ERROR,
    name: "Orders with Problems",
    isActive: true,
  },
];

const loadUrlFilters = (params: URLSearchParams): Filter[] => {
  const filters = params.get("active_filters");
  // Expect "filters" to be a list of strings e.g. SHIPPED,OPEN,
  if (filters !== null) {
    // If active_filters is present, only enable those filters.
    const active_filters = filters.split(",");

    return DEFAULT_FILTERS.map((filter) => {
      return {
        ...filter,
        isActive: active_filters.includes(filter.value),
      };
    });
  } else {
    // Otherwise, use the default configuration
    return DEFAULT_FILTERS;
  }
};

const isFilterActive = (filters: Filter[], filterName: OrderStatus): boolean => {
  return filters.filter((f) => f.value === filterName).map((f) => f.isActive)[0] || false;
};

const filtersToQuery = (filters: Filter[]): QueryIface => {
  return {
    showUnacknowledged: isFilterActive(filters, OrderStatus.UNACKNOWLEDGED),
    showOpen: isFilterActive(filters, OrderStatus.OPEN),
    showPartiallyFulfilled: isFilterActive(filters, OrderStatus.PARTIALLY_FULFILLED),
    showFulfilled: isFilterActive(filters, OrderStatus.FULFILLED),
    showShipped: isFilterActive(filters, OrderStatus.SHIPPED),
    showClosed: isFilterActive(filters, OrderStatus.CLOSED),
    showError: isFilterActive(filters, OrderStatus.ERROR),
  };
};

const OrdersListPage = (props: RouteComponentProps) => {
  const queryFilters = useUrlQuery();
  const [filters, setFilters] = useState(loadUrlFilters(queryFilters));

  return (
    <ListPage
      query={ORDERS_LIST_QUERY}
      variables={filtersToQuery(filters)}
      buttons={
        <>
          <FilterSelector
            filters={filters}
            updateFilter={(f: Filter, newState: boolean) => {
              const newFilters = filters.reduce((acc, item) => {
                if (item.value === f.value) {
                  return [...acc, { ...item, isActive: newState }];
                } else {
                  return [...acc, item];
                }
              }, [] as Filter[]);

              setFilters(newFilters);
            }}
          />
          <Link
            className="ml-3 button level-item is-success is-outlined"
            to="/new-order"
            data-testid={TEST_ID_NEW_ORDER_BUTTON}
          >
            <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span>
            <span>New order</span>
          </Link>
        </>
      }
      preListHeading="New Orders"
      preListPanelTestId={TEST_ID_NEW_ORDERS_LIST}
      preListItemsMapper={(data: Record<string, unknown>) => {
        return sortOrdersByDate([
          ...((data.pendingOrders || []) as IOrder[]),
          ...((data.unacknowledgedOrders || []) as IOrder[]),
        ] as IOrder[]);
      }}
      heading="Current Orders"
      listPanelTestId={TEST_ID_CURRENT_ORDERS_LIST}
      containerTestId={TEST_ID_ORDER_LIST_PAGE_WRAPPER}
      itemsMapper={(data: Record<string, unknown>) => {
        return sortOrdersByDate([
          ...((data.openOrders || []) as IOrder[]),
          ...((data.partiallyFulfilledOrders || []) as IOrder[]),
          ...((data.fulfilledOrders || []) as IOrder[]),
          ...((data.shippedOrders || []) as IOrder[]),
          ...((data.closedOrders || []) as IOrder[]),
          ...((data.erroredOrders || []) as IOrder[]),
        ] as IOrder[]);
      }}
      itemRenderer={(item: IOrder) => (
        <OrderListCard order={item} key={`${item.orderId}`} {...props} />
      )}
    />
  );
};

export default withRouter(OrdersListPage);
