import React from "react";

export const AsanaLink = (): JSX.Element => (
  // Link to the Asana form that adds tickets to Platform Support Requests board
  <a
    href="https://form.asana.com/?k=cgDTk4qIsOMvhWO4TBYcqw&d=1187390650939223"
    target="_blank"
    rel="noopener noreferrer"
  >
    here
  </a>
);
