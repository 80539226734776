import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { IAttachedItem, IOrder, OrderStatus } from "../../types/order";
import ScanBarcodeForm from "../../atoms/ScanBarcodeForm";
import AttachmentCard from "../Common/AttachmentCard";
import OrderDetailAttachmentSummary from "./OrderDetailAttachmentSummary";
import BigError from "../../atoms/BigError";
import { OrderStatusTag, getOrderStatusSettings } from "./getOrderStatusSettings";

export const TEST_ID_ATTACHMENTS_LIST = "AttachmentsList";

export const ADD_NEW_ATTACHMENT = gql`
  mutation ScanBarcode($barcode: ScanBarcodeInput!) {
    scanBarcode(barcode: $barcode) {
      barcode
      itemType
      newStatus
      attachmentId
    }
  }
`;

interface AttachmentsListProps {
  order: IOrder;
  onAttachmentAdd?: () => void;
}

export default function AttachmentsList(props: AttachmentsListProps): JSX.Element {
  const { order, onAttachmentAdd } = props;

  const attachments = order.attachedItems;
  const [addAttachmentMutation] = useMutation(ADD_NEW_ATTACHMENT);
  const settings = getOrderStatusSettings(order.status);
  const [isErrorMessageVisible, setErrorMessageVisible] = useState({
    visible: false,
    message: undefined,
  });

  const hideErrorMessage = () => {
    setErrorMessageVisible({ visible: false, message: undefined });
  };

  const addAttachment = (barcode: string) => {
    if (!isErrorMessageVisible.visible) {
      addAttachmentMutation({
        variables: { barcode: { orderId: order.orderId, barcode } },
      })
        .then(() => {
          onAttachmentAdd && onAttachmentAdd();
        })
        .catch((error) => {
          console.log("Caught error!", { error: error.message });
          setErrorMessageVisible({ visible: true, message: error.message });
        });
    }
  };

  return (
    /* TODO: could make this collapsible using bulmaCollapsible */
    <nav className="panel" data-testid={TEST_ID_ATTACHMENTS_LIST}>
      <BigError {...isErrorMessageVisible} dismiss={hideErrorMessage} />
      <p className="panel-heading">Barcodes</p>
      <div className="panel-block">
        <OrderDetailAttachmentSummary order={order} />
      </div>
      <div className="panel-block">
        {![OrderStatus.FULFILLED, OrderStatus.SHIPPED, OrderStatus.CLOSED].includes(
          order.status
        ) && (
          <div className="level">
            <div className="level-left">
              <div className="mr-4">
                <OrderStatusTag settings={settings} orderId={String(order.orderId)} />
              </div>
              <ScanBarcodeForm addBarcode={addAttachment} />
            </div>
          </div>
        )}
      </div>
      <p className="panel-tabs">
        <Link to="#" className="is-active" onClick={(event) => event.preventDefault()}>
          All
        </Link>
      </p>
      {attachments.map((attachment: IAttachedItem, index: number) => (
        // The "key" prop is required for elements in iterator
        <AttachmentCard key={index} attachment={attachment} />
      ))}{" "}
    </nav>
  );
}
