import React from "react";
import { IOrder } from "../../types/order";

interface OrderDetailAttachmentSummaryProps {
  order: IOrder;
}

const OrderDetailAttachmentSummary = (
  props: OrderDetailAttachmentSummaryProps
): JSX.Element => {
  const { order } = props;
  const attachments = order.attachedItems;

  const numPots = attachments.filter((a) => a.itemType === "PROCEDURE_POT").length;

  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Type</th>
          <th>Scanned</th>
          <th>Required</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Pots</th>
          <td>{numPots}</td>
          <td>{order.oesophagealCellSampleCollectionKitQuantity}</td>
          {/* We could put an success icon here when all scanned*/}
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailAttachmentSummary;
