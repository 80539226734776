import React, { useState, ChangeEvent, FormEvent } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import LoadingSpinner from "../../atoms/Spinner";
import { Link, withRouter } from "react-router-dom";
import { INewUser } from "../../types/admin";
import { Organisation } from "../../types/entities";

export const TEST_ID_FIRST_NAME_INPUT = "FirstNameInput";
export const TEST_ID_LAST_NAME_INPUT = "LastNameInput";
export const TEST_ID_EMAIL_INPUT = "EmailInput";
export const TEST_ID_PASSWORD_INPUT = "PasswordInput";
export const TEST_ID_ORGANISATION_SELECT = "OrganisationSelect";
export const TEST_ID_IS_STAFF_CHECKBOX = "IsStaffCheckbox";
export const TEST_ID_IS_SUPERUSER_CHECKBOX = "IsSuperuserCheckbox";
export const TEST_ID_CREATE_USER_BUTTON = "CreateUserButton";
export const TEST_ID_CREATE_NEW_USER_SUCCESS = "CreateNewUserSuccess";

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($newUser: CreateUserInput!) {
    createUser(newUser: $newUser) {
      firstName
      lastName
      email
      id
      isSuperuser
      isStaff
    }
  }
`;

export const ORGS_LIST = gql`
  query Organisations {
    organisations {
      id
      name
    }
  }
`;

function NewUserForm() {
  const [newUser, setNewUser] = useState({
    isStaff: false,
    isSuperuser: false,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    organisationId: "",
  } as INewUser);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    function isInputType(
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): e is ChangeEvent<HTMLInputElement> {
      return e.target instanceof HTMLInputElement;
    }

    const key = event.target.name;
    let value;
    if (isInputType(event)) {
      value = key.startsWith("is") ? event.target.checked : event.target.value;
    } else {
      // if HTMLSelectElement then just use value
      value = event.target.value;
    }

    setNewUser({ ...newUser, [key]: value });
  };

  function validateForm() {
    return (
      newUser.email &&
      newUser.password &&
      newUser.firstName &&
      newUser.lastName &&
      newUser.organisationId
    );
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql CreateUser mutation
    createUserMutation({
      variables: {
        newUser,
      },
    })
      .then(() => {
        const msg = (
          <p data-testid={TEST_ID_CREATE_NEW_USER_SUCCESS} className="help is-success">
            User created successfully
          </p>
        );
        setFormMessage(msg);

        // clear form
        setNewUser({
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          organisationId: "",
          isStaff: false,
          isSuperuser: false,
        } as INewUser);
      })
      .catch((error) => {
        const msg = (
          <p className="help is-danger">
            There was an error: {JSON.stringify(error.message)}
          </p>
        );
        setFormMessage(msg);
      });
  };

  //   get list of orgs from graphql
  const { loading, error, data } = useQuery(ORGS_LIST);
  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  const OrgsInputList = data.organisations;

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">New User</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">First name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="firstName"
                    value={newUser.firstName}
                    onChange={onChange}
                    data-testid={TEST_ID_FIRST_NAME_INPUT}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Last name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="lastName"
                    value={newUser.lastName}
                    onChange={onChange}
                    data-testid={TEST_ID_LAST_NAME_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    name="email"
                    value={newUser.email}
                    onChange={onChange}
                    data-testid={TEST_ID_EMAIL_INPUT}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="password"
                    value={newUser.password}
                    onChange={onChange}
                    data-testid={TEST_ID_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field">
            <label className="label">Organisation</label>
            <div className="control">
              <div className="select">
                <select
                  name="organisationId"
                  value={newUser.organisationId}
                  onChange={onChange}
                  data-testid={TEST_ID_ORGANISATION_SELECT}
                >
                  <option value="">---------</option>
                  {OrgsInputList.map((org: Organisation) => (
                    <option key={String(org.id)} value={String(org.id)}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="checkbox label">
              <input
                type="checkbox"
                name="isStaff"
                checked={newUser.isStaff}
                onChange={onChange}
                data-testid={TEST_ID_IS_STAFF_CHECKBOX}
              />{" "}
              Staff
            </label>
          </div>
          <div className="field">
            <label className="checkbox label">
              <input
                type="checkbox"
                name="isSuperuser"
                checked={newUser.isSuperuser}
                onChange={onChange}
                data-testid={TEST_ID_IS_SUPERUSER_CHECKBOX}
              />{" "}
              Superuser
            </label>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
                data-testid={TEST_ID_CREATE_USER_BUTTON}
              >
                Create User
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/orders">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewUserForm);
