import { PropsWithChildren } from "react";
import { RouteComponentProps } from "react-router";
import { v4 as UUID } from "uuid";

export enum OrderStatus {
  UNACKNOWLEDGED = "UNACKNOWLEDGED",
  OPEN = "OPEN",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  FULFILLED = "FULFILLED",
  SHIPPED = "SHIPPED",
  CLOSED = "CLOSED",
  UNKNOWN = "UNKNOWN",
  ERROR = "ERROR",
}

export interface IContainer extends IAttachedItem {
  displayBarcode: string;
}

export interface IAttachedItem {
  __typename: string;
  barcode: string;
  displayBarcode?: string;
  itemType: string;
  attachmentId: string;
}

export interface IAttachment {
  attachment: IAttachedItem;
}

export interface IScanBarcodeForm {
  addBarcode: (x: string) => void;
  testId?: string;
}

export interface IOrderProvider extends PropsWithChildren<unknown> {
  order: IOrder;
}

export interface IOrderStatusCard extends RouteComponentProps {
  order: IOrder;
}

export interface IHealthcareProvider {
  name: string;
}

export interface DeliveryAddress {
  firstName: string;
  lastName: string;
  dept: string;
  lineOne: string;
  lineTwo: string;
  town: string;
  county: string;
  postcode: string;
}

export enum Couriers {
  CITYSPRINT = "CitySprint",
  UPS = "UPS",
}

export interface OrderConfiguration {
  courier: Couriers | null;
  oesophagealCellSampleCollectionKitQuantity: number;
  canisters?: number | null;
  requestForms: number;
  shippingRecordForms?: number | null;
  // DX no longer fulfils new orders, but the interface supports historic orders
  dxReturnBoxes?: number | null;
  dxGreenBags?: number | null;
  cytedSampleReturnBags?: number | null;
  upsReturnBoxes?: number | null;
}

export interface IOrder {
  orderId: string;
  status: OrderStatus;
  slug: string;
  oesophagealCellSampleCollectionKitQuantity: number;
  attachedItems: IAttachedItem[];
  healthcareProvider: IHealthcareProvider;
  deliveryAddress?: DeliveryAddress;
  shippingDeadline?: Date;
  orderConfiguration?: OrderConfiguration;
}

export interface InewContextInfo {
  [key: string]: string | typeof UUID | number | IAttachedItem[] | IHealthcareProvider;
}

export interface IOrderStatusSettingsMap {
  [key: string]: OrderStatusSettings;
}

export interface OrderStatusSettings {
  icon: string;
  prettyStatus: string;
  indicatorColour: string;
  buttonIcon: string;
  buttonText: string;
  longPrettyStatus: string;
  instructionMessage: string;
}
