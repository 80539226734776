import React from "react";
import { Couriers, IOrder, OrderConfiguration } from "../../types/order";

export const TEST_ID_ORDER_CONFIG_DETAILS_CARD = "OrderConfigDetailsCard";

type OrderConfigurationKey = keyof OrderConfiguration;
type OrderConfigurationValue = OrderConfiguration[OrderConfigurationKey];

interface OrderConfigurationDetailsLineProps {
  label: string;
  value: OrderConfigurationValue;
}

const OrderConfigurationDetailsLine = (
  props: OrderConfigurationDetailsLineProps
): JSX.Element => {
  const { label, value } = props;
  return (
    <tr>
      <th>{label}:</th>
      <td>{value}</td>
    </tr>
  );
};

interface OrderConfigurationDetailsCardProps {
  order: IOrder;
}

const packingItemsList: [string, OrderConfigurationKey][] = [
  [
    "Oesophageal cell sample collection kits",
    "oesophagealCellSampleCollectionKitQuantity",
  ],
  ["Canisters", "canisters"],
  ["Request Forms", "requestForms"],
  ["Shipping Record Forms", "shippingRecordForms"],
  ["DX Return Boxes", "dxReturnBoxes"],
  ["DX Green Bags", "dxGreenBags"],
  ["Cyted Sample Return Bags", "cytedSampleReturnBags"],
  [`${Couriers.UPS} Pre-labeled courier return box`, "upsReturnBoxes"],
];

const OrderConfigurationDetailsCard = (
  props: OrderConfigurationDetailsCardProps
): JSX.Element => {
  const { orderConfiguration } = props.order;
  return (
    // Only show box if at least one field has data
    orderConfiguration ? (
      <div className="box" data-testid={TEST_ID_ORDER_CONFIG_DETAILS_CARD}>
        {orderConfiguration.courier && (
          <div className="container">
            <p className="subtitle is-size-4">Packaging</p>
            <p>
              <span className="has-text-weight-bold">Return courier:</span>{" "}
              {orderConfiguration.courier}
            </p>
            <hr></hr>
          </div>
        )}
        <div className="container">
          <p className="subtitle is-size-4">Items required</p>
          <table className="table is-striped">
            <tbody>
              {
                // Dynamically generate the packing list,
                // only show items with value >= 0
                packingItemsList.map(([label, key]) => {
                  // Check if orderConfiguration[key] is null or undefined
                  const value = orderConfiguration[key] ?? undefined;
                  // Show label & value if value is number >= 0
                  if ((value && typeof value === "number") || value === 0) {
                    return (
                      <OrderConfigurationDetailsLine
                        label={label}
                        value={value}
                        key={key}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export default OrderConfigurationDetailsCard;
