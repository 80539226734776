import React from "react";
import { IAttachment } from "../../types/order";

export const TEST_ID_ATTACHMENT_CARD = "AttachmentCard";

export default function Attachment({ attachment }: IAttachment): JSX.Element {
  const attachmentLabel: string = attachment.displayBarcode ?? attachment.barcode;

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div
      className="panel-block is-active"
      data-testid={`${TEST_ID_ATTACHMENT_CARD}${attachmentLabel}`}
    >
      <div className="column">
        <div>
          <span className="icon is-left">
            <i className="far fa-barcode-alt"></i>{" "}
          </span>
          <span className="has-text-weight-bold"> {attachment.itemType}: </span>
          <span data-testid={`barcode-${attachmentLabel}`}>{attachmentLabel}</span>
        </div>
      </div>
    </div>
  );
}
