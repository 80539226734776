import React, { useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";

import AttachmentsList from "./AttachmentsList";
import OrderStatusCard from "./OrderStatusCard";
import OrderDetailMainCard from "./OrderDetailMainCard";
import LoadingSpinner from "../../atoms/Spinner";
import OrderDetailTimeline from "./OrderDetailTimeline";
import OrderDeliveryDetailsCard from "./OrderDeliveryDetails";
import OrderConfigurationDetailsCard from "./OrderConfigurationDetail";
import OrderAcknowledgementCard from "./OrderAcknowledgementCard";
import { IOrder, OrderStatus } from "../../types/order";
import OrderCloseCard, { CLOSE_ORDER_MUTATION } from "./OrderCloseCard";
import NonKitOrderPackedCard from "./NonKitOrderPackedCard";

export const TEST_ID_ORDER_DETAIL_CONTAINER = "OrderDetailContainer";

export const ORDER_QUERY = gql`
  query GetOrder($orderId: String!) {
    getOrder(orderId: $orderId) {
      orderId
      oesophagealCellSampleCollectionKitQuantity
      slug
      status
      healthcareProvider {
        name
      }
      attachedItems {
        barcode
        itemType
        attachmentId
      }

      deliveryAddress {
        firstName
        lastName
        dept
        lineOne
        lineTwo
        town
        county
        postcode
      }

      shippingDeadline

      orderConfiguration {
        courier
        oesophagealCellSampleCollectionKitQuantity
        canisters
        requestForms
        shippingRecordForms
        dxReturnBoxes
        dxGreenBags
        cytedSampleReturnBags
        upsReturnBoxes
      }
    }
  }
`;

function OrderDetailPage() {
  const { orderId } = useParams<{ orderId: string }>();
  const [isCloseOrderLoading, setIsCloseOrderLoading] = useState(false);

  const { loading, error, data, refetch } = useQuery(ORDER_QUERY, {
    variables: { orderId },
    pollInterval: 2000,
  });

  const [closeOrderMutation] = useMutation(CLOSE_ORDER_MUTATION, {
    update(cache, { data: { closeOrderMutation } }) {
      cache.modify({
        fields: {
          getOrders(existingOrderRefs = []) {
            const newOrderRef = cache.writeQuery({
              query: CLOSE_ORDER_MUTATION,
              data: closeOrderMutation,
            });
            return [...existingOrderRefs, newOrderRef];
          },
        },
      });
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <span>Error: {error.message}</span>;
  }

  const closeOrder = () => {
    setIsCloseOrderLoading(true);
    closeOrderMutation({
      variables: {
        order: { orderId: String(order.orderId) },
      },
    }).then(() => {
      refetch();
    });
  };

  const order: IOrder = data.getOrder;

  if (order) {
    // Return the order detail page, with the list of current attachments
    return (
      <div
        id="orderDetail"
        className="container"
        data-testid={TEST_ID_ORDER_DETAIL_CONTAINER}
      >
        <OrderDetailMainCard order={order} />
        <div className="container columns">
          <div className="container column is-two-thirds">
            <OrderStatusCard
              order={order}
              onConfirm={() => refetch()}
              closeOrder={closeOrder}
              isCloseOrderLoading={isCloseOrderLoading}
            />
            <OrderConfigurationDetailsCard order={order} />
            {
              // Show OrderAcknowledgementCard when status is UNACKNOWLEDGED
              order.status === OrderStatus.UNACKNOWLEDGED && (
                <OrderAcknowledgementCard order={order} onAcknowledge={() => refetch()} />
              )
            }
            {
              // Show AttachmentsList when status is ACKNOWLEDGED
              // and order is for cytosponge pots
              order.status !== OrderStatus.UNACKNOWLEDGED &&
                order.oesophagealCellSampleCollectionKitQuantity > 0 && (
                  <AttachmentsList
                    order={order}
                    onAttachmentAdd={() => {
                      refetch();
                    }}
                  />
                )
            }
            {order.status === OrderStatus.SHIPPED && (
              <OrderCloseCard isLoading={isCloseOrderLoading} onClose={closeOrder} />
            )}
            {
              // Show Confirm Order Packed button when order is OPEN
              // and is NOT for Sample collection kits
              order.status === OrderStatus.OPEN &&
                order.oesophagealCellSampleCollectionKitQuantity === 0 && (
                  <NonKitOrderPackedCard order={order} onPacked={() => refetch()} />
                )
            }
          </div>
          <div className="container column">
            <OrderDeliveryDetailsCard order={order} />
            <OrderDetailTimeline orderId={orderId} />
          </div>
        </div>
      </div>
    );
  } else {
    return <h2>Sorry there was an error. That order doesn&apos;t exist.</h2>;
  }
}

export default withRouter(OrderDetailPage);
