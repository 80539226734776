import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import axios, { AxiosResponse } from "axios";
import { getCurrentUser } from "../../services/auth.service";
import {
  getBatchStatusSettings,
  BatchStatusIndicatorLarge,
} from "./getBatchStatusSettings";
import { ContainerType, IBatch } from "../../types/batch";

export const TEST_ID_CONFIRM_BATCH_BUTTON = "confirm-batch-button";
export const TEST_ID_FINALIZE_BATCH_BUTTON = "finalize-batch-button";
export const TEST_ID_POT_BATCH_CONFIRM_MODAL = "pot-batch-confirm-modal";
export const TEST_ID_DOWNLOAD_BATCH_LABELS_BUTTON = "download-batch-labels-button";
export const TEST_ID_DOWNLOAD_BATCH_SUMMARY_BUTTON = "download-batch-summary-button";
export const TEST_ID_LONG_PRETTY_STATUS = "long-pretty-status";
// TODO: add mutation
export const CONFIRM_BATCH_MUTATION = gql`
  mutation ConfirmBatch($batchId: String!) {
    confirmBatch(batchId: $batchId) {
      batchId
      batchIdentifier
      potLotNumber
      bdSolutionLotNumber
      bdSolutionExpiryDate
      status
      pots {
        barcode
        itemType
      }
    }
  }
`;

export type CsvType = "labels" | "summary";

interface BatchStatusCardProps {
  batch: IBatch;
  onConfirm: () => void;
}

const BATCH_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/batches`;
const BATCH_API_PATH: Record<CsvType, string> = {
  labels: "export-for-label-printer",
  summary: "export-for-salesforce",
};

const BatchStatusCard = (props: BatchStatusCardProps): JSX.Element => {
  const { batch, onConfirm } = props;
  const settings = getBatchStatusSettings(batch);

  const [isModalActive, setModalActive] = useState("");
  const [confirmBatchMutation] = useMutation(CONFIRM_BATCH_MUTATION);

  const isCsvLabelsButtonVisible: boolean =
    batch.containerType === ContainerType.ENDOSIGN && batch.status === "OPEN";
  const isConfirmBatchButtonVisible: boolean = batch.status === "IN_PROGRESS";
  const isCsvSummaryButtonVisible: boolean =
    batch.containerType === ContainerType.ENDOSIGN && batch.status === "CONFIRMED";

  const toggleModalActive = () => {
    setModalActive(isModalActive ? "" : "is-active");
  };

  const handleOnConfirm = () => {
    toggleModalActive();
    confirmBatchMutation({
      variables: {
        batchId: batch.batchId,
      },
    }).then(() => {
      onConfirm();
    });
  };

  const handleDownloadClick = async (csvType: CsvType): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.get(
        `${BATCH_API_URL}/${BATCH_API_PATH[csvType]}/${batch.batchId}`,
        {
          headers: { Authorization: `Bearer ${getCurrentUser().token}` },
          responseType: "blob",
        }
      );
      downloadCsvFromResponse(response, csvType);
    } catch (error) {
      console.error(error);
      window.alert(
        "Sorry, file download failed.\nIf the problem persists, please contact system support."
      );
    }
  };

  const downloadCsvFromResponse = (response: AxiosResponse, csvType: CsvType): void => {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([response.data]));
    link.href = url;
    link.download = `${batch.manufacturerBatchNumber}_${csvType}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box">
      <div className="media">
        <div className="media-left">
          <BatchStatusIndicatorLarge settings={settings} />
        </div>
        <div className="media-content content">
          <h2 data-testid={TEST_ID_LONG_PRETTY_STATUS} className="subtitle is-size-4">
            {settings.longPrettyStatus}
          </h2>
          <div className="mb-3">{settings.instructionMessage}</div>
          <div className="buttons">
            {isCsvLabelsButtonVisible && (
              <button
                type="button"
                className="button is-outlined"
                onClick={() => handleDownloadClick("labels")}
                data-testid={TEST_ID_DOWNLOAD_BATCH_LABELS_BUTTON}
              >
                <span className="icon is-medium">
                  <i className="fas fa-download"></i>
                </span>
                <span>Download labels as CSV</span>
              </button>
            )}
            {isConfirmBatchButtonVisible && (
              <button
                className="button is-success"
                type="submit"
                onClick={toggleModalActive}
                data-testid={TEST_ID_CONFIRM_BATCH_BUTTON}
              >
                <span className="icon is-medium">
                  <i className="fas fa-check"></i>
                </span>
                <span>Confirm Batch</span>
              </button>
            )}
            {isCsvSummaryButtonVisible && (
              <button
                type="button"
                className="button is-outlined"
                onClick={() => handleDownloadClick("summary")}
                data-testid={TEST_ID_DOWNLOAD_BATCH_SUMMARY_BUTTON}
              >
                <span className="icon is-medium">
                  <i className="fas fa-download"></i>
                </span>
                <span>Download batch as CSV</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={`modal ${isModalActive}`}
        data-testid={TEST_ID_POT_BATCH_CONFIRM_MODAL}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Please confirm</p>
            <button
              className="delete"
              aria-label="close"
              onClick={toggleModalActive}
            ></button>
          </header>
          <section className="modal-card-body">
            Please confirm that you wish to proceed. This will finalise the state of the
            batch, and sign off with your name.
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={handleOnConfirm}
              data-testid={TEST_ID_FINALIZE_BATCH_BUTTON}
            >
              Finalise batch
            </button>
            <button className="button is-danger is-inverted" onClick={toggleModalActive}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default BatchStatusCard;
