import React from "react";
import { RouteComponentProps, useHistory, useParams, withRouter } from "react-router-dom";
import PotDetail from "../ScanIncomingProcedures/PotDetail";
import { Feature } from "../../../atoms/FeatureFlag";
import GoBack from "../../../atoms/GoBack";

function ProcedureDetailPage(props: RouteComponentProps) {
  const { potBarcode } = useParams<{ potBarcode: string }>();
  const history = useHistory<{
    from: string;
    fromComponent: string;
  }>();

  return (
    <>
      <div className="mb-2">
        <GoBack
          referrer={history.location.state?.from}
          current={"ProcedureDetailPage"}
          destinationComponent={history.location.state?.fromComponent}
        />
      </div>
      {/* Pot detail summary */}
      <Feature flag="PRINT_REQUEST_FORM" {...props}>
        <PotDetail barcode={potBarcode} {...props} />
      </Feature>
    </>
  );
}

export default withRouter(ProcedureDetailPage);
