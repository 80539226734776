import React, { useState, ChangeEvent, FormEvent } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useMutation, gql } from "@apollo/client";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ContainerType } from "../../types/batch";

export const TEST_ID_BATCH_SIZE_INPUT = "batchSize";
export const TEST_ID_POT_LOT_INPUT = "potLotNumber";
export const TEST_ID_BD_SOLUTION_LOT_INPUT = "bdSolutionLotNumber";
export const TEST_ID_BD_SOLUTION_EXPIRY_DATE_INPUT = "bdSolutionExpiryDate";
export const TEST_ID_CREATE_BATCH_BUTTON = "createBatchButton";

// TODO PLAT-2800: Remove when transition to EndoSign is complete
export const CREATE_BATCH_MUTATION = gql`
  mutation CreatePotBatch($batch: CreatePotBatchInput!) {
    createPotBatch(batch: $batch) {
      batchId
      status
    }
  }
`;

export const CREATE_ENDOSIGN_BATCH_MUTATION = gql`
  mutation CreateEndosignPreserveBatch($batch: CreateEndosignPreserveBatchInput!) {
    createEndosignPreserveBatch(batch: $batch) {
      batchId
      batchExpiryDate
      status
      manufacturerBatchNumber
      allocatedPots
    }
  }
`;

export const BatchTypeLabelMap: Record<ContainerType, string> = {
  [ContainerType.CYTOSPONGE]: "Oesophageal cell collection kit",
  [ContainerType.ENDOSIGN]: "EndoSign® cell preservation kit",
};

interface INewBatch {
  batchType: ContainerType;
  batchSize?: number;
  potLotNumber: string;
  bdSolutionLotNumber: string;
  bdSolutionExpiryDate: Date | null;
}

export const MIN_BATCH_SIZE = 1;
export const MAX_BATCH_SIZE = 300;

function NewBatchForm(props: RouteComponentProps) {
  const [newBatch, setNewBatch] = useState({
    batchType: ContainerType.ENDOSIGN,
    batchSize: undefined,
    potLotNumber: "",
    bdSolutionLotNumber: "",
    bdSolutionExpiryDate: null,
  } as INewBatch);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  // TODO PLAT-2800: Remove when transition to EndoSign is complete
  const [createBatchMutation, { loading: creatingCytospongeBatch }] = useMutation(
    CREATE_BATCH_MUTATION,
    {
      update(cache, { data: { createBatchMutation } }) {
        cache.modify({
          fields: {
            getBatches(existingBatchRefs = []) {
              const newBatchRef = cache.writeQuery({
                query: CREATE_BATCH_MUTATION,
                data: createBatchMutation,
              });
              return [...existingBatchRefs, newBatchRef];
            },
          },
        });
      },
    }
  );

  // update cache after creating a new batch
  const [createEndosignBatchMutation, { loading: creatingEndosignBatch }] = useMutation(
    CREATE_ENDOSIGN_BATCH_MUTATION,
    {
      update(cache, { data: { createEndosignBatchMutation } }) {
        cache.modify({
          fields: {
            getBatches(existingBatchRefs = []) {
              const newBatchRef = cache.writeQuery({
                query: CREATE_ENDOSIGN_BATCH_MUTATION,
                data: createEndosignBatchMutation,
              });
              return [...existingBatchRefs, newBatchRef];
            },
          },
        });
      },
    }
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    setNewBatch({ ...newBatch, [key]: value });
  };

  function validateForm() {
    return (
      // Batch size is required if 'EndoSign' is selected
      (newBatch.batchSize !== undefined ||
        newBatch.batchType === ContainerType.CYTOSPONGE) &&
      newBatch.potLotNumber &&
      newBatch.bdSolutionLotNumber &&
      newBatch.bdSolutionExpiryDate
    );
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // construct date string in format 2025-05-31
    const bdSolutionExpiryDate = moment(newBatch.bdSolutionExpiryDate).format(
      "YYYY-MM-DD"
    );

    // TODO PLAT-2800: Remove when transition to EndoSign is complete
    if (newBatch.batchType === ContainerType.CYTOSPONGE) {
      // send graphql CreateBatch mutation
      createBatchMutation({
        variables: {
          batch: {
            potLotNumber: newBatch.potLotNumber,
            bdSolutionLotNumber: newBatch.bdSolutionLotNumber,
            bdSolutionExpiryDate,
          },
        },
      })
        .then(() => {
          props.history.push("/pots-management");
        })
        .catch((error) => {
          const msg = (
            <p className="help is-danger">There was an error: {error.message}</p>
          );
          setFormMessage(msg);
        });
    } else if (newBatch.batchType === ContainerType.ENDOSIGN) {
      // send graphql CreateBatch mutation
      createEndosignBatchMutation({
        variables: {
          batch: {
            potLotNumber: newBatch.potLotNumber,
            bdSolutionLotNumber: newBatch.bdSolutionLotNumber,
            bdSolutionExpiryDate,
            numberOfPots: Number(newBatch.batchSize),
          },
        },
      })
        .then(() => {
          props.history.push("/pots-management");
        })
        .catch((error) => {
          const msg = (
            <p className="help is-danger">There was an error: {error.message}</p>
          );
          setFormMessage(msg);
        });
    }
  };

  return (
    <div className="panel-block columns">
      <div className="container column is-two-thirds">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <h1 className="title">New Batch</h1>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {/* TODO PLAT-2800: Remove radio buttons when transition to EndoSign is complete */}
          <div className="field mb-5">
            <h3 className="label" id="batchTypeLabel">
              Batch type
            </h3>
            <div className="control" role="radiogroup" aria-labelledby="batchTypeLabel">
              {[ContainerType.ENDOSIGN, ContainerType.CYTOSPONGE].map(
                (batchType: ContainerType) => (
                  <label className="radio ml-0 mr-5 mb-1" key={batchType}>
                    <input
                      required
                      type="radio"
                      name="batchType"
                      value={batchType}
                      checked={newBatch.batchType === batchType}
                      onChange={onChange}
                    />
                    {BatchTypeLabelMap[batchType]}
                  </label>
                )
              )}
            </div>
          </div>
          {newBatch.batchType === ContainerType.ENDOSIGN && (
            <div className="field">
              <label className="label" htmlFor="batchSize">
                Batch size
              </label>
              <div className="control" style={{ width: 100 }}>
                <input
                  required
                  className="input"
                  type="number"
                  id="batchSize"
                  name="batchSize"
                  min={MIN_BATCH_SIZE}
                  max={MAX_BATCH_SIZE}
                  value={newBatch.batchSize ?? ""}
                  onChange={onChange}
                  data-testid={TEST_ID_BATCH_SIZE_INPUT}
                />
              </div>
              <p className="help">Up to {MAX_BATCH_SIZE}</p>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="potLotNumber">
              Container lot number
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                id="potLotNumber"
                name="potLotNumber"
                value={newBatch.potLotNumber}
                onChange={onChange}
                data-testid={TEST_ID_POT_LOT_INPUT}
              />
            </div>
            <p className="help">e.g. 03827171</p>
          </div>
          <div className="field">
            <label className="label" htmlFor="bdSolutionLotNumber">
              Preservative fluid lot number
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                id="bdSolutionLotNumber"
                name="bdSolutionLotNumber"
                value={newBatch.bdSolutionLotNumber}
                onChange={onChange}
                data-testid={TEST_ID_BD_SOLUTION_LOT_INPUT}
              />
            </div>
            <p className="help">e.g. 2144846</p>
          </div>
          <div className="field">
            <label className="label" htmlFor="bdSolutionExpiryDate">
              Preservative fluid expiry date
            </label>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control has-icons-right">
                  <DatePicker
                    className="input"
                    id="bdSolutionExpiryDate"
                    autoComplete="off"
                    customInput={
                      <input
                        type="text"
                        data-testid={TEST_ID_BD_SOLUTION_EXPIRY_DATE_INPUT}
                      />
                    }
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    placeholderText="YYYY-MM-DD"
                    selected={newBatch.bdSolutionExpiryDate}
                    onChange={(bdSolutionExpiryDate) =>
                      setNewBatch({ ...newBatch, bdSolutionExpiryDate })
                    }
                  />
                  <span className="icon is-small is-right">
                    <i className="fas fa-calendar-alt"></i>
                  </span>
                </div>
                <p className="help">Enter date in the format YYYY-MM-DD</p>
              </div>
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className={`button is-primary ${
                  creatingCytospongeBatch || creatingEndosignBatch ? "is-loading" : ""
                }`}
                disabled={!validateForm()}
                type="submit"
                data-testid="createBatchButton"
              >
                Create {BatchTypeLabelMap[newBatch.batchType]} batch
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/pots-management">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewBatchForm);
