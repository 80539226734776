import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation, gql } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import { INewStudy } from "../../types/admin";

export const TEST_ID_STUDY_NAME_INPUT = "StudyNameInput";
export const TEST_ID_STUDY_DESCRIPTION_INPUT = "StudyDescriptionInput";
export const TEST_ID_CREATE_STUDY_BUTTON = "CreateStudyButton";
export const TEST_ID_CREATE_NEW_STUDY_SUCCESS = "CreateNewStudySuccess";

const CREATE_STUDY_MUTATION = gql`
  mutation CreateStudy($study: CreateStudyInput!) {
    createStudy(study: $study) {
      name
      description
    }
  }
`;

function NewStudyForm() {
  const [newStudy, setNewStudy] = useState({
    name: "",
    description: "",
  } as INewStudy);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [createStudyMutation] = useMutation(CREATE_STUDY_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    setNewStudy({ ...newStudy, [key]: value });
  };

  function validateForm() {
    return newStudy.name && newStudy.description;
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql CreateStudy mutation
    createStudyMutation({
      variables: {
        study: newStudy,
      },
    })
      .then(() => {
        const msg = (
          <p className="help is-success" data-testid={TEST_ID_CREATE_NEW_STUDY_SUCCESS}>
            Study created successfully
          </p>
        );
        setFormMessage(msg);

        // clear form
        setNewStudy({
          name: "",
          description: "",
        } as INewStudy);
      })
      .catch((error) => {
        const msg = (
          <p className="help is-danger">
            There was an error: {JSON.stringify(error.message)}
          </p>
        );
        setFormMessage(msg);
      });
  };

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">New Study</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Study Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    data-testid={TEST_ID_STUDY_NAME_INPUT}
                    value={newStudy.name}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Study Description</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="description"
                    data-testid={TEST_ID_STUDY_DESCRIPTION_INPUT}
                    value={newStudy.description}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                data-testid={TEST_ID_CREATE_STUDY_BUTTON}
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
              >
                Create Study
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/orders">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewStudyForm);
