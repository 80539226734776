import { AxiosError, AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { getPotSerialNumberFromBarcode } from "../../../helpers/strings";
import { RequestFormApiResponse } from "../../../types/procedures";
import { getRequestForm } from "./getRequestForm";

export interface useGetRequestFormReturnType {
  loading: boolean;
  error?: AxiosError;
}

export default function useGetRequestForm(
  barcode: string,
  setErrorHook: React.Dispatch<React.SetStateAction<JSX.Element | null>>
): AxiosResponse<RequestFormApiResponse> & useGetRequestFormReturnType {
  const [result, setResult] = useState<AxiosResponse & useGetRequestFormReturnType>({
    loading: true,
  } as AxiosResponse & useGetRequestFormReturnType);

  useEffect(() => {
    function fetchData() {
      // Only use the last eight characters of an EndoSign UDI, e.g. 23P20001
      const potBarcode = getPotSerialNumberFromBarcode(barcode);
      return getRequestForm(potBarcode)
        .then((res) => {
          setResult({ loading: false, ...res });
          setErrorHook(null);
        })
        .catch((e) => {
          // Spread e.response to update status, headers, config
          // Set data as undefined as e.response incl an error msg in data
          setResult({ loading: false, ...e.response, error: e, data: undefined });
          setErrorHook(null);
        });
    }
    if (barcode.length) {
      fetchData();
    }
  }, [barcode, setErrorHook]);

  return result;
}
