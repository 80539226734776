import React from "react";
import { IOrderStatusSettingsMap, OrderStatusSettings } from "../../types/order";

export const TEST_ID_ORDER_STATUS_TAG = "OrderStatusTag";

const getOrderStatusSettings = (status: string): OrderStatusSettings => {
  return OrderStatusSettingsMap[status] || OrderStatusSettingsMap["default"];
};

export const INBOX_ICON_CLASS_NAME = "fas fa-inbox-in";
export const BOX_OPEN_ICON_CLASS_NAME = "fas fa-box-open";
export const TRUCK_LOADING_ICON_CLASS_NAME = "fas fa-truck-loading";
export const CHECK_ICON_CLASS_NAME = "fas fa-check";
export const TRUCK_ICON_CLASS_NAME = "fas fa-truck";
export const WARNING_ICON_CLASS_NAME = "fas fa-exclamation-triangle";
export const PALLET_ICON_CLASS_NAME = "fas fa-pallet";
export const ARROW_RIGHT_ICON_CLASS_NAME = "fas fa-arrow-right";
export const FLAG_CHECKERED_ICON_CLASS_NAME = "far fa-flag-checkered";

export const ATTENTION_INDICATOR_COLOR = "danger";
export const INFO_INDICATOR_COLOUR = "info";
export const WARNING_INDICATOR_COLOUR = "warning";
export const SUCCESS_INDICATOR_COLOUR = "success";
export const DARK_INDICATOR_COLOUR = "dark";
export const DANGER_INDICATOR_COLOUR = "danger";
export const WHITE_INDICATOR_COLOUR = "white";

export const ACKNOWLEDGE_BUTTON_TEXT = "Acknowledge Order";
export const PREPARE_SHIPMENT_BUTTON_TEXT = "Prepare Shipment";
export const FULFILMENT_IN_PROGRESS_BUTTON_TEXT = "Fulfilment in Progress";
export const PREPARE_OUTER_CONTAINER_BUTTON_TEXT = "Prepare Outer Container";
export const SHIPPED_BUTTON_TEXT = "Shipped";
export const CLOSED_BUTTON_TEXT = "Closed";
export const ERROR_BUTTON_TEXT = "Error";

export const OrderStatusSettingsMap: IOrderStatusSettingsMap = {
  UNACKNOWLEDGED: {
    indicatorColour: ATTENTION_INDICATOR_COLOR,
    prettyStatus: "Unacknowledged",
    longPrettyStatus: "This order needs to be acknowledged by the lab",
    instructionMessage: "",
    icon: INBOX_ICON_CLASS_NAME,
    buttonIcon: INBOX_ICON_CLASS_NAME,
    buttonText: ACKNOWLEDGE_BUTTON_TEXT,
  },
  OPEN: {
    indicatorColour: INFO_INDICATOR_COLOUR,
    prettyStatus: "Open",
    longPrettyStatus: "This order is a work in progress",
    instructionMessage: "Please scan barcodes for pots.",
    icon: BOX_OPEN_ICON_CLASS_NAME,
    buttonIcon: PALLET_ICON_CLASS_NAME,
    buttonText: PREPARE_SHIPMENT_BUTTON_TEXT,
  },
  PARTIALLY_FULFILLED: {
    indicatorColour: WARNING_INDICATOR_COLOUR,
    prettyStatus: "Fulfilment in Progress",
    longPrettyStatus: "This order is a work in progress",
    instructionMessage: "Please scan barcodes for pots.",
    icon: TRUCK_LOADING_ICON_CLASS_NAME,
    buttonIcon: PALLET_ICON_CLASS_NAME,
    buttonText: FULFILMENT_IN_PROGRESS_BUTTON_TEXT,
  },
  FULFILLED: {
    indicatorColour: SUCCESS_INDICATOR_COLOUR,
    prettyStatus: "Fulfilled",
    longPrettyStatus: "This order is ready for shipping!",
    instructionMessage:
      "Please prepare the outer container for it and move it to the outgoing shipping area.",
    icon: ARROW_RIGHT_ICON_CLASS_NAME,
    buttonIcon: ARROW_RIGHT_ICON_CLASS_NAME,
    buttonText: PREPARE_OUTER_CONTAINER_BUTTON_TEXT,
  },
  SHIPPED: {
    indicatorColour: DARK_INDICATOR_COLOUR,
    prettyStatus: "Shipped",
    longPrettyStatus: "This order needs to be closed by the operations team",
    instructionMessage: "",
    icon: TRUCK_ICON_CLASS_NAME,
    buttonIcon: TRUCK_ICON_CLASS_NAME,
    buttonText: SHIPPED_BUTTON_TEXT,
  },
  CLOSED: {
    indicatorColour: WHITE_INDICATOR_COLOUR,
    prettyStatus: "Order Closed",
    longPrettyStatus: "Order Closed",
    instructionMessage: "",
    icon: FLAG_CHECKERED_ICON_CLASS_NAME,
    buttonIcon: FLAG_CHECKERED_ICON_CLASS_NAME,
    buttonText: CLOSED_BUTTON_TEXT,
  },
  UNKNOWN: {
    indicatorColour: "light",
    prettyStatus: "Pending",
    longPrettyStatus: "This order is being processed.",
    instructionMessage:
      "We're working on this order in the background. This page should automatically update when the order is ready. This shouldn't take more than a few seconds.",
    icon: "far fa-ellipsis-h",
    buttonIcon: "fas fa-spin fa-sync",
    buttonText: "Order Pending",
  },
  default: {
    indicatorColour: DANGER_INDICATOR_COLOUR,
    prettyStatus: "Error",
    longPrettyStatus: "There's a problem with this order.",
    instructionMessage:
      "There's a problem with this order that we need to fix. In the meantime please create a new order. If the problem persists, please contact system support.",
    icon: WARNING_ICON_CLASS_NAME,
    buttonIcon: WARNING_ICON_CLASS_NAME,
    buttonText: ERROR_BUTTON_TEXT,
  },
};

const OrderStatusIndicator = ({
  settings,
}: {
  settings: OrderStatusSettings;
}): JSX.Element => {
  const { indicatorColour, icon } = settings;
  const iconColour =
    indicatorColour === WHITE_INDICATOR_COLOUR
      ? `has-text-${DARK_INDICATOR_COLOUR}`
      : `has-text-${indicatorColour}`;

  return (
    <div className="column is-narrow" data-testid="OrderStatusIndicator">
      <span className="panel-icon">
        {icon != null && (
          <span data-testid={icon} className={`icon is-small ${iconColour}`}>
            <i className={icon}></i>
          </span>
        )}
      </span>
    </div>
  );
};

const OrderStatusIndicatorLarge = ({
  settings,
}: {
  settings: OrderStatusSettings;
}): JSX.Element => {
  const { indicatorColour, icon } = settings;

  return (
    <div>
      {icon != null && (
        <span
          data-testid={`${icon}Large`}
          className={`icon is-large is-rounded tag is-${indicatorColour}`}
        >
          <i className={icon}></i>
        </span>
      )}
    </div>
  );
};

const OrderStatusTag = ({
  settings,
  orderId,
}: {
  settings: OrderStatusSettings;
  orderId: string;
}): JSX.Element => {
  const { indicatorColour, buttonIcon, buttonText } = settings;

  return (
    <div
      className={`tag is-medium is-${indicatorColour}`}
      id={String(orderId)}
      style={{ pointerEvents: "none" }}
      data-testid={TEST_ID_ORDER_STATUS_TAG}
    >
      <span className="icon is-small ml-0 mr-2">
        <i data-testid={buttonIcon} className={buttonIcon}></i>
      </span>
      <span data-testid={buttonText}>{buttonText}</span>
    </div>
  );
};

export {
  getOrderStatusSettings,
  OrderStatusIndicator,
  OrderStatusTag,
  OrderStatusIndicatorLarge,
};
