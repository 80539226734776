import React from "react";
import { getCurrentUser, isRequiredToChangePassword } from "../services/auth.service";

export default function WelcomeChangePasswordMessage(): JSX.Element | null {
  const currentUser = getCurrentUser();

  if (isRequiredToChangePassword(currentUser)) {
    return (
      <div className="media notification is-warning is-light">
        <div className="media-left">
          <span className="icon tag is-warning is-large is-rounded">
            <i className="fas fa-unlock-alt" />
          </span>
        </div>
        <div className="media-content">
          <h1 className="is-size-5">Welcome to the Cyted Sample Tracking Platform</h1>
          <p>
            As this is the first time you have logged in, please change your password.
          </p>
          <p>You will then need to login again with the new password.</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
