import React from "react";
import {
  BatchStatusSettings,
  ContainerType,
  IBatch,
  IBatchStatusSettingsMap,
} from "../../types/batch";
import { Link } from "react-router-dom";

const getBatchStatusSettings = (batch: IBatch): BatchStatusSettings => {
  const { containerType, status } = batch;
  switch (containerType) {
    case ContainerType.CYTOSPONGE:
      return CytospongeBatchStatusSettingsMap[status] ?? ErrorBatchStatusSettings;
    case ContainerType.ENDOSIGN:
      return EndosignBatchStatusSettingsMap[status] ?? ErrorBatchStatusSettings;
    default:
      return ErrorBatchStatusSettings;
  }
};

export const BOX_OPEN_ICON_CLASS_NAME = "fas fa-box-open";
export const HOURGLASS_ICON_CLASS_NAME = "fas fa-hourglass-half";
export const TRUCK_LOADING_ICON_CLASS_NAME = "fas fa-truck-loading";
export const PALLET_ICON_CLASS_NAME = "fas fa-pallet";
export const DOLLY_ICON_CLASS_NAME = "fas fa-dolly";
export const CHECK_ICON_CLASS_NAME = "fas fa-check";
export const WARNING_ICON_CLASS_NAME = "fas fa-exclamation-triangle";
export const TEST_ID_BATCH_STATUS_TEXT = "batch-status-text";

const OpenBatchStatusSettings = {
  indicatorColour: "info",
  prettyStatus: "Open",
  icon: BOX_OPEN_ICON_CLASS_NAME,
  buttonIcon: PALLET_ICON_CLASS_NAME,
  buttonText: "Prepare Batch",
};

const InProgressBatchStatusSettings = {
  indicatorColour: "warning",
  prettyStatus: "In Progress",
  icon: TRUCK_LOADING_ICON_CLASS_NAME,
  buttonIcon: HOURGLASS_ICON_CLASS_NAME,
  buttonText: "In Progress",
};

const ConfirmedBatchStatusSettings = {
  indicatorColour: "dark",
  prettyStatus: "Confirmed",
  icon: CHECK_ICON_CLASS_NAME,
  buttonIcon: DOLLY_ICON_CLASS_NAME,
  buttonText: "Confirmed",
};

const CytospongeBatchStatusSettingsMap: IBatchStatusSettingsMap = {
  OPEN: {
    ...OpenBatchStatusSettings,
    longPrettyStatus: "This batch is still a work in progress",
    instructionMessage: "Please scan barcodes for pots.",
  },
  IN_PROGRESS: {
    ...InProgressBatchStatusSettings,
    longPrettyStatus: "This batch is still a work in progress",
    instructionMessage: (
      <>
        <p>You are able to register prepared procedure pots with this batch.</p>
        <p>
          Please ensure that the procedure pots are from the correct lot, contain BD
          SurePath&trade; from the correct batch and are ready for use by healthcare
          providers.
        </p>
        <p>
          By confirming this batch you are declaring that all scanned pots have the
          correct batch information and that no further pots will be added to this batch.
        </p>
      </>
    ),
  },
  CONFIRMED: {
    ...ConfirmedBatchStatusSettings,
    longPrettyStatus: "This batch has been confirmed",
    instructionMessage: (
      <>
        <p>
          This batch has been signed-off and cannot be changed. You can now use these pots
          to fulfil outgoing orders.
        </p>
        <p>
          <Link to="/orders" className="button is-small">
            Go to Orders
          </Link>
        </p>
      </>
    ),
  },
};

const EndosignBatchStatusSettingsMap: IBatchStatusSettingsMap = {
  OPEN: {
    ...OpenBatchStatusSettings,
    longPrettyStatus: "This batch is ready for manufacturing and QC",
    instructionMessage: (
      <>
        <p>Please prepare this EndoSign batch before scanning the pot barcodes.</p>
        <p>
          You must ensure that the containers are from the correct lot and contain
          preservative fluid from the correct batch.
        </p>
      </>
    ),
  },
  IN_PROGRESS: {
    ...InProgressBatchStatusSettings,
    longPrettyStatus: "This batch is being scanned in",
    instructionMessage: (
      <>
        <p>Please do not scan any file samples or pots which have failed QC.</p>
        <p>
          By confirming this batch you are declaring that all scanned pots have the
          correct batch information and that no further pots will be added to this batch.
        </p>
      </>
    ),
  },
  CONFIRMED: {
    ...ConfirmedBatchStatusSettings,
    longPrettyStatus: "This batch has been confirmed",
    instructionMessage: (
      <>
        <p>This batch has been signed off and cannot be changed.</p>
        <p>You can now download the batch details and upload them to Salesforce.</p>
      </>
    ),
  },
};

const ErrorBatchStatusSettings: BatchStatusSettings = {
  indicatorColour: "danger",
  prettyStatus: "Error",
  longPrettyStatus: "There’s a problem with this batch.",
  instructionMessage: "",
  icon: WARNING_ICON_CLASS_NAME,
  buttonIcon: WARNING_ICON_CLASS_NAME,
  buttonText: "Error",
};

const BatchStatusIndicator = ({
  settings,
}: {
  settings: BatchStatusSettings;
}): JSX.Element => {
  const { indicatorColour, icon } = settings;

  return (
    <div className="column is-narrow">
      <span className="panel-icon">
        {icon != null && (
          <span
            data-testid={icon}
            className={`icon is-small has-text-${indicatorColour}`}
          >
            <i className={icon}></i>
          </span>
        )}
      </span>
    </div>
  );
};

const BatchStatusIndicatorLarge = ({
  settings,
}: {
  settings: BatchStatusSettings;
}): JSX.Element => {
  const { indicatorColour, icon } = settings;

  return (
    <div>
      {icon != null && (
        <span className={`icon is-large is-rounded tag is-${indicatorColour}`}>
          <i className={icon} data-testid={icon}></i>
        </span>
      )}
    </div>
  );
};

const BatchStatusTag = ({ settings }: { settings: BatchStatusSettings }): JSX.Element => {
  const { indicatorColour, buttonIcon, buttonText } = settings;

  return (
    <div
      className={`tag is-medium is-${indicatorColour}`}
      style={{ pointerEvents: "none" }}
    >
      <span className="icon is-small ml-0 mr-2">
        <i className={buttonIcon}></i>
      </span>
      <span data-testid={TEST_ID_BATCH_STATUS_TEXT}>{buttonText}</span>
    </div>
  );
};

export {
  getBatchStatusSettings,
  BatchStatusIndicator,
  BatchStatusTag,
  BatchStatusIndicatorLarge,
};
