import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import LoadingSpinner from "../../../atoms/Spinner";
import { IForm } from "../../../types/procedures";
import OutstandingPotsListCard from "./OutstandingPotsListCard";

const OUTSTANDING_PROCEDURES_LIST = gql`
  query GetOutstandingProcedures {
    getOutstandingProcedures {
      potBarcode
      episodeId
      receivedTimestamp
    }
  }
`;

const OutstandingPotsList = (props: RouteComponentProps) => {
  // get the data
  const { loading, error, data } = useQuery(OUTSTANDING_PROCEDURES_LIST);
  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  // This creates the list of request forms
  const forms_list = [...data.getOutstandingProcedures]
    // sort by date, oldest first
    .sort((a: IForm, b: IForm) => {
      const a_date = new Date(Date.parse(a.receivedTimestamp)).valueOf();
      const b_date = new Date(Date.parse(b.receivedTimestamp)).valueOf();
      return a_date - b_date;
    })
    .map((form: IForm, index: number) => {
      return <OutstandingPotsListCard form={form} key={index} {...props} />;
    });

  return (
    <div id="UnlinkedRequestFormsListPage" className="panel-block columns">
      <div className="container column">
        {/* Page Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left"></div>
        </div>

        <div>
          <nav className="panel">
            <p className="panel-heading">Unlinked Request Forms</p>
            {forms_list}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default withRouter(OutstandingPotsList);
