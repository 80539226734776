import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import "moment/locale/en-gb";

import Routes from "./Routes";
import NavBar from "./containers/Navbar";
import WorkInProgressWarning from "./atoms/WorkInProgressWarning";
import { LoginModal } from "./containers/Login";
import { loginModalRef } from "./services/auth.service";
import "./styles/app.scss";

const App = (): JSX.Element => {
  // set global locale for timestamp formatting
  moment.locale("en-gb");

  return (
    <Router>
      <LoginModal ref={loginModalRef} />
      <div>
        <NavBar />
        <div className="section columns">
          <div className="container">
            <WorkInProgressWarning />
            <Routes />
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
