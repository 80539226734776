import React, { useState, ChangeEvent, FormEvent } from "react";
import { gql, useMutation } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import WelcomeChangePasswordMessage from "../../atoms/WelcomeChangePasswordMessage";
import {
  logout,
  getCurrentUser,
  isRequiredToChangePassword,
  SSO_LOGIN_PAGE,
} from "../../services/auth.service";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $password: String!) {
    changePassword(currentPassword: $currentPassword, password: $password)
  }
`;

interface IChangePasswordObj {
  old: string;
  new: string;
  new_repeat: string;
}

const BLANK_FORM: IChangePasswordObj = {
  old: "",
  new: "",
  new_repeat: "",
};

export const TEST_ID_UPDATE_PASSWORD_BUTTON = "UpdatePasswordBtn";
export const TEST_ID_OLD_PASSWORD_INPUT = "OldPasswordInput";
export const TEST_ID_NEW_PASSWORD_INPUT = "NewPasswordInput";
export const TEST_ID_CONFIRM_NEW_PASSWORD_INPUT = "ConfirmNewPasswordInput";
export const TEST_ID_CHANGE_SELF_PASSWORD_SUCCESS = "ChangeSelfPasswordSuccess";

function ChangePasswordForm() {
  const [changePasswordObj, setChangePasswordObj] = useState(BLANK_FORM);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [changePasswordMutation, { client }] = useMutation(CHANGE_PASSWORD_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;

    setChangePasswordObj({ ...changePasswordObj, [key]: value });
  };

  function validateForm() {
    return (
      changePasswordObj.old &&
      changePasswordObj.new &&
      changePasswordObj.new_repeat &&
      changePasswordObj.new === changePasswordObj.new_repeat
    );
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql changePasswordMutation mutation
    changePasswordMutation({
      variables: {
        currentPassword: changePasswordObj.old,
        password: changePasswordObj.new,
      },
    })
      .then(() => {
        const msg = (
          <p
            className="help is-success"
            data-testid={TEST_ID_CHANGE_SELF_PASSWORD_SUCCESS}
          >
            Password changed successfully
          </p>
        );
        setFormMessage(msg);

        // clear form
        setChangePasswordObj(BLANK_FORM);

        // log the user out to get a new token if they are first time user
        if (isRequiredToChangePassword(getCurrentUser())) {
          client.resetStore().then(() => logout());
        }
      })
      .catch((error) => {
        const msg = <p className="help is-danger">There was an error: {error.message}</p>;
        setFormMessage(msg);
      });
  };

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">Change Password</p>
          </div>
        </div>
        <WelcomeChangePasswordMessage />

        {SSO_LOGIN_PAGE && (
          <div className="notification is-light">
            <h2 className="title is-size-4">Heads up!</h2>
            <p className="content">
              Changing your password here won&apos;t update your GSuite password. If you
              want to change your GSuite password, click &quot;Manage GSuite.
              Account&quot;.
            </p>
            <a
              href="https://accounts.google.com/security"
              className="button is-link"
              target="_blank"
              rel="noreferrer"
            >
              Manage GSuite Account
            </a>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="field mt-4">
            <div className="field-body">
              <div className="field">
                <label className="label">Old password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="old"
                    value={changePasswordObj.old}
                    onChange={onChange}
                    data-testid={TEST_ID_OLD_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field mt-4">
            <div className="field-body">
              <div className="field">
                <label className="label">New password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="new"
                    value={changePasswordObj.new}
                    onChange={onChange}
                    data-testid={TEST_ID_NEW_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field mt-4">
            <div className="field-body">
              <div className="field">
                <label className="label">Confirm new password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="new_repeat"
                    value={changePasswordObj.new_repeat}
                    onChange={onChange}
                    data-testid={TEST_ID_CONFIRM_NEW_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="help mb-4">
            Make sure it&apos;s at least 8 characters including one upper case, one lower
            case, one number and one special character.
          </p>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
                data-testid={TEST_ID_UPDATE_PASSWORD_BUTTON}
              >
                Update password
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/settings">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(ChangePasswordForm);
