import React from "react";
import { withRouter, useParams, Redirect } from "react-router-dom";

import PotManagementMainCard from "./PotManagementMainCard";
import BarcodesList from "./AttachmentsList";
import BatchStatusCard from "./BatchStatusCard";
import { gql, useQuery } from "@apollo/client";
import LoadingSpinner from "../../atoms/Spinner";
import { IBatch } from "../../types/batch";

export const GET_BATCH_BY_ID_QUERY = gql`
  query GetBatch($batchId: String!) {
    getBatch(batchId: $batchId) {
      batchId
      batchIdSlug
      batchIdentifier
      potLotNumber
      bdSolutionLotNumber
      bdSolutionExpiryDate
      status
      countOfPots
      batchExpiryDate
      manufacturerBatchNumber
      containerType
      allocatedPots
      pots {
        barcode
        displayBarcode
        itemType
      }
    }
  }
`;

function PotManagementPage() {
  const { batchId } = useParams<{ batchId: string }>();

  const { loading, error, data, refetch } = useQuery(GET_BATCH_BY_ID_QUERY, {
    variables: { batchId },
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <span>Error: {error.message}</span>;
  }

  const batch: IBatch = data.getBatch;

  if (!batch) {
    return <Redirect to="/pots-management" />;
  } else {
    return (
      <div id="potManagementDetail" className="container">
        <PotManagementMainCard batch={batch} />
        <div className="container columns">
          <div className="container column is-two-thirds">
            <BatchStatusCard batch={batch} onConfirm={() => refetch()} />
            <BarcodesList batch={batch} onAttachmentAdded={() => refetch()} />
          </div>
          <div className="container column"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(PotManagementPage);
