import React, {
  useState,
  FunctionComponent,
  ChangeEvent,
  FormEvent,
  ForwardedRef,
} from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getCurrentUser, login, SSO_LOGIN_PAGE, logout } from "../services/auth.service";

export const TEST_ID_LOGIN_MODAL_CURRENT_USER_EMAIL = "LOGIN_MODAL_CURRENT_USER_EMAIL";
export const TEST_ID_REFRESH_SESSION_FORM = "REFRESH_SESSION_FORM";
export const TEST_ID_SUBMIT_USER_REFRESH_FORM = "SUBMIT_USER_REFRESH_FORM";
export const TEST_ID_LOGIN_AS_DIFFERENT_USER = "LOGIN_AS_DIFFERENT_USER";

const SsoBox = ({ loginPage }: { loginPage: string }) => (
  <div className="section column is-half is-offset-one-quarter">
    <div className="box">
      <h1 className="title is-size-4">Sign in with GSuite</h1>
      <p className="section">
        You are able to use this application using your Cyted GSuite credentials. If you
        normally log in with your username and password and do not have a Cyted GSuite
        account you are still able to login as before below.
      </p>
      <a href={loginPage} className="button is-fullwidth">
        <span className="icon is-small">
          <i className="fab fa-google"></i>
        </span>
        <span>Sign in with GSuite</span>
      </a>
    </div>
  </div>
);

export const LoginModal = React.forwardRef(function LoginModal(
  _props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const user = getCurrentUser();
  if (!user?.token) return null;

  return (
    <div ref={ref} className="modal">
      <div className="modal-background"></div>
      <div className="modal-content box">
        <div className="notification section">
          <h4 className="title is-4 mb-3">Please re-enter your password to continue</h4>
          <p className="has-text-weight-light">
            You are currently signed in as&nbsp;
            <span
              className="has-text-weight-bold"
              data-testid={TEST_ID_LOGIN_MODAL_CURRENT_USER_EMAIL}
            >
              {user?.email}.
            </span>
          </p>
        </div>
        <UserRefresh />
      </div>
    </div>
  );
});

export const UserRefresh = (): JSX.Element => {
  // Requires the user to log in with the same account or logout entirely.
  const [password, setPassword] = useState("");
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const currentUser = getCurrentUser();

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();

    if (currentUser?.email) {
      setPassword("");
      setErrorMessage("");
      setIsContinueLoading(true);
      login(currentUser.email, password)
        .catch((error) => {
          const errorMessage = `${error.message}: Please check your details and try again.`;
          setErrorMessage(errorMessage);
          // Log error to console until we have better error handling
          console.error(errorMessage);
        })
        .finally(() => {
          setIsContinueLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleLogin} data-testid={TEST_ID_REFRESH_SESSION_FORM}>
      <div className="field">
        <p
          className={"control has-icons-left " + (isContinueLoading ? "is-loading" : "")}
        >
          <input
            type="password"
            className="input"
            name="refreshPassword"
            placeholder="Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"></i>
          </span>
        </p>
      </div>
      {errorMessage && <p className="help is-danger my-5">{errorMessage}</p>}
      <div className="buttons">
        <button
          data-testid={TEST_ID_SUBMIT_USER_REFRESH_FORM}
          className={"button is-primary " + (isContinueLoading ? "is-loading" : "")}
          type="submit"
        >
          Continue
        </button>
        <button
          id="signInAsDifferentUser"
          className="button is-text"
          onClick={logout}
          data-testid={TEST_ID_LOGIN_AS_DIFFERENT_USER}
        >
          Sign in as a different user
        </button>
      </div>
    </form>
  );
};

const Login: FunctionComponent<RouteComponentProps & { skipRedirect?: boolean }> = ({
  history,
  skipRedirect,
}: RouteComponentProps & { skipRedirect?: boolean }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resMessage, setresMessage] = useState("");

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPassword(password);
  };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();

    login(email, password)
      .then(() => {
        if (!skipRedirect) {
          // This needs to be configurable
          history.push("/orders");
          window.location.reload();
        }
      })
      .catch((error) => {
        const resMessage = `${error.message}: Please check your details and try again.`;
        setresMessage(resMessage);
        // Log error to console until we have better error handling
        console.error(resMessage);
      });
  };

  return (
    <>
      {SSO_LOGIN_PAGE && <SsoBox loginPage={SSO_LOGIN_PAGE} />}
      <div className="column is-half is-offset-one-quarter">
        <form onSubmit={handleLogin}>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="email"
                name="email"
                placeholder="Email"
                autoFocus
                value={email}
                onChange={onChangeEmail}
              />

              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fas fa-check"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={onChangePassword}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
            </p>
            <p className="help is-danger">{resMessage}</p>
          </div>
          <div className="buttons">
            <button
              id="loginButton"
              className="button is-primary"
              disabled={!validateForm()}
              type="submit"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default withRouter(Login);
