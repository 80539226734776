import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {
  getOrderStatusSettings,
  OrderStatusIndicator,
  OrderStatusTag,
} from "./getOrderStatusSettings";
import moment from "moment";

import { IOrderStatusCard } from "../../types/order";

export const TEST_ID_ORDER_LIST_CARD_PREFIX = "OrderListCardPrefix";
export const TEST_ID_ORDER_LIST_CARD_KIT_QUANTITY = "OrderListCardKitQuantity";

const OrderStatusCard: FunctionComponent<IOrderStatusCard> = ({
  order,
}: IOrderStatusCard) => {
  const orderIdStr = String(order.orderId);
  const orderStatusSettings = getOrderStatusSettings(order.status);

  return (
    <Link
      className="panel-block is-active"
      to={`/orders/${order.orderId}`}
      data-testid={`${TEST_ID_ORDER_LIST_CARD_PREFIX}-${order.orderId}`}
    >
      <OrderStatusIndicator settings={orderStatusSettings} />
      <div className="column">
        <div>
          <p className="has-text-weight-bold">{order.healthcareProvider.name}</p>
          <p>
            <span className="has-text-weight-bold">{order.slug}</span> -{" "}
            <span data-testid={TEST_ID_ORDER_LIST_CARD_KIT_QUANTITY}>
              Oesophageal cell sample collection kits:{" "}
              {order.oesophagealCellSampleCollectionKitQuantity}
            </span>
          </p>
          {order.shippingDeadline && (
            <>
              <p className="tag is-medium my-2">
                Shipping deadline: {moment(order.shippingDeadline).format("DD MMM YYYY")}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="mr-4">
        <OrderStatusTag settings={orderStatusSettings} orderId={orderIdStr} />
      </div>
    </Link>
  );
};

export default OrderStatusCard;
