import React, { useState } from "react";

export const FILTERS_DROPDOWN_TRIGGER_TEST_ID = "FilterDropdown";
export const FILTERS_FILTER_SELECTOR_TEST_ID_PREFIX = "FilterSelector";
export const FILTERS_ACTIVE_FILTER_CLASS = "is-filter-active";

export interface Filter {
  value: string;
  name?: string;
  isActive: boolean;
}

const FilterControl = ({
  name,
  isActive,
  value,
  action,
}: {
  name: string;
  isActive: boolean;
  value: string;
  action: () => void;
}): JSX.Element => {
  return (
    <>
      <button
        className={`button is-fullwidth is-small py-2 my-2 level ${
          isActive && FILTERS_ACTIVE_FILTER_CLASS
        }`}
        onClick={action}
        data-testid={`${FILTERS_FILTER_SELECTOR_TEST_ID_PREFIX}-${value}`}
      >
        {name}
        <input
          readOnly={true}
          type="checkbox"
          className="checkbox ml-3"
          checked={isActive}
        />
      </button>
    </>
  );
};

export default function FilterSelector({
  filters,
  updateFilter,
}: {
  filters: Filter[];
  updateFilter: (f: Filter, newState: boolean) => void;
}): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  const filterCount = filters.filter((f) => f.isActive).length;

  return (
    <div className={`dropdown ${isVisible ? "is-active" : ""}`}>
      <button
        className="button dropdown-trigger"
        data-testid={FILTERS_DROPDOWN_TRIGGER_TEST_ID}
        onClick={() => setIsVisible(!isVisible)}
      >
        <span>Filters</span>
        <span className="tag is-rounded is-family-monospace is-info ml-1">
          {filterCount}
        </span>
      </button>
      <div className="dropdown-menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <p>Select which order statuses to view</p>
          </div>
          <div className="dropdown-item">
            {filters.map((filter, idx) => (
              <FilterControl
                name={filter.name || filter.value}
                value={filter.value}
                isActive={filter.isActive}
                key={idx}
                action={() => {
                  updateFilter(filter, !filter.isActive);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
