import React from "react";
import { AsanaLink } from "./AsanaLink";

export const TEST_ID_SCAN_ERROR_MODAL = "scan-error-modal";
export const TEST_ID_ERROR_MESSAGE = "error-message";

export default function BigError({
  visible,
  message,
  dismiss,
}: {
  visible: boolean;
  message?: string;
  dismiss: () => unknown;
}): JSX.Element | null {
  if (!visible) {
    return null;
  }

  return (
    <div className="modal is-active" data-testid={TEST_ID_SCAN_ERROR_MODAL}>
      <div className="modal-background"></div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={dismiss}
      ></button>
      <div className="modal-content message is-danger">
        <div className="p-3 message-body has-text-black">
          <div className="container">
            <div className="section">
              <h1 className="title">We&apos;ve stopped you from scanning this item.</h1>
              <p>
                We&apos;ve detected an issue with this barcode scan and have not been able
                to complete this operation.
              </p>
              <br />
              <p>
                Please check that the item you&apos;re attempting to scan is in the
                correct state.
              </p>
              <br />
              <p data-testid={TEST_ID_ERROR_MESSAGE}>
                Error message: <strong>{message}</strong>
              </p>
              <br />
              <p>
                For technical support please report this issue <AsanaLink />
              </p>
              <div className="container mt-4">
                <button className="button is-danger is-inverted" onClick={dismiss}>
                  Dismiss and Resume Scanning
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
