import React from "react";
import { useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";

import LoadingSpinner from "../../atoms/Spinner";
import List from "../../atoms/List";

export interface ListPageProps<T, V> {
  preListHeading?: string;
  preListPanelTestId?: string;
  preListItemsMapper?: (data: Record<string, unknown>) => T[];
  heading: string;
  query: DocumentNode;
  buttons: JSX.Element;
  listPanelTestId?: string;
  containerTestId?: string;
  variables?: V;
  itemsMapper: (data: Record<string, unknown>) => T[];
  itemRenderer: (item: T) => JSX.Element;
}

function ListPage<T, V>(props: ListPageProps<T, V>): JSX.Element {
  const {
    preListHeading,
    preListPanelTestId,
    preListItemsMapper,
    heading,
    query,
    buttons,
    listPanelTestId,
    containerTestId,
    itemsMapper,
    itemRenderer,
  } = props;

  const { loading, error, data } = useQuery(query, {
    fetchPolicy: "cache-and-network",
    pollInterval: 2000,
    variables: props.variables,
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  const items = itemsMapper(data);
  const preListItems = preListItemsMapper && preListItemsMapper(data);

  return (
    <div className="container" data-testid={containerTestId}>
      {/* <!-- Main container --> */}
      <nav className="level">
        {/* <!-- Left side (need to keep this even though it is empty) --> */}
        <div className="level-left"></div>
        {/* <!-- Right side --> */}
        <div className="level-right">
          <div className="level-item">{buttons}</div>
        </div>
      </nav>
      {preListHeading && preListItems && preListItems?.length > 0 && (
        <nav className="panel" data-testid={preListPanelTestId}>
          <p className="panel-heading">{preListHeading}</p>
          <List items={preListItems} itemRenderer={itemRenderer} />
        </nav>
      )}

      <nav className="panel" data-testid={listPanelTestId}>
        <p className="panel-heading">{heading}</p>
        {(items?.length > 0 && <List items={items} itemRenderer={itemRenderer} />) || (
          <p className="panel-block is-disabled">No results</p>
        )}
      </nav>
    </div>
  );
}

export default ListPage;
