import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import ScanBarcodeForm from "../../../atoms/ScanBarcodeForm";
import PotDetail from "./PotDetail";
import ScanSessionSummary from "./ScanSessionSummary";
import { Feature } from "../../../atoms/FeatureFlag";
import BigError from "../../../atoms/BigError";
import { getPotSerialNumberFromBarcode } from "../../../helpers/strings";

export const SCAN_INCOMING_ITEM = gql`
  mutation ScanIncomingItem($barcode: ScanIncomingItemInput!) {
    scanIncomingItem(item: $barcode) {
      itemType
    }
  }
`;

const ScanIncomingProcedures = (props: RouteComponentProps) => {
  const [scannedCounts, setScannedCounts] = useState({
    numPots: 0,
    numBoxes: 0,
  });

  const [potBarcode, setPotBarcode] = useState("");

  const invisible_msg = <p className="help is-invisible">...</p>;
  const [formMessage, setFormMessage] = useState(invisible_msg);

  const [errorMessagePopUp, setErrorMessagePopUp] = useState<{
    visible: boolean;
    message: string | undefined;
  }>({
    visible: false,
    message: undefined,
  });

  const hideErrorMessage = () => {
    setErrorMessagePopUp({ visible: false, message: undefined });
  };

  const scanIncomingItem = (barcode: string) => {
    if (!errorMessagePopUp.visible) {
      scanIncomingItemMutation({
        variables: { barcode: { barcode } },
      })
        .then((result) => {
          const { itemType } = result.data.scanIncomingItem;
          setFormMessage(invisible_msg);

          function makeSuccessMessage(itemType: string, barcode: string) {
            const displayBarcode = getPotSerialNumberFromBarcode(barcode);
            const msg =
              itemType === "PROCEDURE_POT"
                ? `New procedure created for pot with barcode ${displayBarcode}`
                : `Return box with barcode ${displayBarcode} logged`;

            return (
              <div className="panel-block">
                <p className="has-text-weight-bold">
                  <span className="icon is-small is-rounded tag is-success">
                    <i className="fas fa-check" />
                  </span>{" "}
                  {msg}
                </p>
              </div>
            );
          }

          if (itemType === "PROCEDURE_POT") {
            scannedCounts.numPots++;
            setPotBarcode(barcode);
            setFormMessage(makeSuccessMessage(itemType, barcode));
          } else if (itemType === "RETURN_BOX") {
            scannedCounts.numBoxes++;
            setPotBarcode("");
            setFormMessage(makeSuccessMessage(itemType, barcode));
          }
          setScannedCounts(scannedCounts);
        })
        .catch((error) => {
          if (error.message.includes("Attachment already marked")) {
            // If Attachment already marked as received, just show warning
            setPotBarcode(barcode);
            const error_msg = (
              <div className="panel-block">
                <p className="has-text-weight-bold">
                  <span className="icon is-small is-rounded tag is-warning">
                    <i className="fas fa-exclamation" />
                  </span>{" "}
                  {`${error.message} ${getPotSerialNumberFromBarcode(barcode)}`}
                </p>
              </div>
            );
            setFormMessage(error_msg);
          } else {
            // Otherwise, show error modal
            setPotBarcode("");
            setFormMessage(invisible_msg);
            setErrorMessagePopUp({
              visible: true,
              message: `${error.message} for barcode ${getPotSerialNumberFromBarcode(
                barcode
              )}.`,
            });
          }
        });
    }
  };

  // get the data
  const [scanIncomingItemMutation] = useMutation(SCAN_INCOMING_ITEM);
  return (
    <div id="ScanIncomingProcedures" className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">Scan Incoming Procedures</p>
          </div>
        </div>
        <BigError {...errorMessagePopUp} dismiss={hideErrorMessage} />

        {/* Summary table of pots & boxes scanned this session */}
        <ScanSessionSummary scannedCounts={scannedCounts} />

        {/* Scan form for incoming items */}
        <div className="column">
          <ScanBarcodeForm addBarcode={scanIncomingItem} />
        </div>

        {/* Success or error message */}
        {formMessage}

        {/* Pot detail summary */}
        <Feature flag="PRINT_REQUEST_FORM" {...props}>
          {potBarcode && <PotDetail barcode={potBarcode} {...props} />}
        </Feature>
      </div>
    </div>
  );
};

export default withRouter(ScanIncomingProcedures);
