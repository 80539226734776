import React, { useState, ChangeEvent, FormEvent } from "react";
import moment from "moment";
import { useMutation, useQuery, gql } from "@apollo/client";
import LoadingSpinner from "../../../atoms/Spinner";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Organisation, Study } from "../../../types/entities";
import { Couriers } from "../../../types/order";
import { AddressFormComponent } from "./AddressFormComponent";
import {
  OrderConfigComponentCourier,
  OrderConfigComponentNonKits,
} from "./OrderConfigComponent";
import { SelectFormInput } from "./SelectFormInput";

export const TEST_ID_STUDY_SELECT = "StudySelect";
export const TEST_ID_HEALTHCARE_PROVIDER_SELECT = "HealthcareProviderSelect";
export const TEST_ID_SAMPLE_COLLECTION_KIT_QUANTITY_INPUT =
  "SampleCollectionKitQuantityInput";
export const TEST_ID_NON_KITS_ORDER_INPUT = "NonKitsOrderInput";
export const TEST_ID_CREATE_ORDER_BUTTON = "CreateOrderButton";
export const TEST_ID_SHIPPING_DEADLINE_INPUT = "ShippingDeadlineInput";
export const TEST_ID_COURIER_INPUT = "CourierInput";
export const TEST_ID_EDIT_CONFIG_BUTTON = "EditConfigButton";

// CSS style to override default label width and prevent text wrapping.
export const LABEL_STYLE = { flexGrow: 3 };
// CSS style to force better alignment of radio buttons with label.
const RADIO_STYLE = { marginTop: "8px" };
// CSS style to force better alignment of checkboxes with label.
const CHECKBOX_STYLE = { marginTop: "6px" };
// Markers for required/optional fields
const STAR_STYLE = { display: "inline-block", width: "8px" };
export const REQUIRED_FIELD_STAR = (
  <span className="has-text-danger" style={STAR_STYLE}>
    *
  </span>
);
export const OPTIONAL_FIELD = <span style={STAR_STYLE}></span>;

// Default order config ratios
// This is used to set the initial values of these fields
// It is also used when determining if a warning should be displayed
// for canisters and upsReturnBoxes
export const orderDefaultConfigRatios = (n: number): Record<string, number> => ({
  oesophagealCellSampleCollectionKits: n,
  canisters: Math.ceil(n / 2),
  requestForms: n,
  shippingRecordForms: 0,
  cytedSampleReturnBags: 10,
  upsReturnBoxes: Math.ceil(n / 4),
});

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($order: CreateOrderInput!) {
    createOrder(order: $order) {
      orderId
      studyId
      status
      healthcareProvider {
        name
      }
      oesophagealCellSampleCollectionKitQuantity
      shippingDeadline
    }
  }
`;

export const STUDIES_LIST = gql`
  query Studies {
    studies {
      id
      name
      healthcareProviders {
        id
        name
      }
    }
  }
`;

function NewOrderForm(props: RouteComponentProps) {
  const [studyId, setStudyId] = useState("");
  const [hcpId, setHCPId] = useState("");
  const [sampleCollectionKitsQuantity, setSampleCollectionKitsQuantity] = useState<
    number | undefined
  >(undefined);
  const [isNonKitsOrder, setIsNonKitsOrder] = useState(false);
  const [hcpInputList, setHCPInputList] = useState([] as Organisation[]);
  const [shippingDeadline, setShippingDeadline] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dept, setDept] = useState("");
  const [lineOne, setLineOne] = useState("");
  const [lineTwo, setLineTwo] = useState("");
  const [town, setTown] = useState("");
  const [county, setCounty] = useState("");
  const [postcode, setPostcode] = useState("");

  const [courier, setCourier] = useState("");
  const [isConfigEditable, setIsConfigEditable] = useState(false);
  const [canisters, setCanisters] = useState<number | undefined>(undefined);
  const [requestForms, setRequestForms] = useState<number | undefined>(undefined);
  const [shippingRecordForms, setShippingRecordForms] = useState<number | undefined>(
    undefined
  );
  const [cytedSampleReturnBags, setCytedSampleReturnBags] = useState<number | undefined>(
    undefined
  );
  const [upsReturnBoxes, setUPSReturnBoxes] = useState<number | undefined>(undefined);

  const [createOrderMutation, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER_MUTATION,
    {
      update(cache, { data: { createOrderMutation } }) {
        cache.modify({
          fields: {
            getOrders(existingOrderRefs = []) {
              const newOrderRef = cache.writeQuery({
                query: CREATE_ORDER_MUTATION,
                data: createOrderMutation,
              });
              return [...existingOrderRefs, newOrderRef];
            },
          },
        });
      },
    }
  );

  const onChangeStudy = (event: ChangeEvent<HTMLSelectElement>) => {
    const studyId = event.target.value;
    setStudyId(studyId);

    const current_study = studiesInputList.find((s: Study) => String(s.id) === studyId);

    if (current_study) {
      setHCPInputList(current_study.healthcareProviders);
    } else {
      setHCPInputList([] as Organisation[]);
    }
  };

  function updateOrderConfig(quantity: number | undefined, courier: string) {
    // This function sets the values for all of the relevant
    // order configuration items, using the ratios defined in the
    // orderDefaultConfigRatios map.
    // It also enables the values to be reset to undefined if
    // required, e.g when the user selects the isNonKitsOrder option
    if (quantity && quantity > 0) {
      setRequestForms(orderDefaultConfigRatios(quantity)["requestForms"]);

      if (courier === Couriers.CITYSPRINT) {
        setUPSReturnBoxes(undefined);
        setCanisters(orderDefaultConfigRatios(quantity)["canisters"]);
        setShippingRecordForms(orderDefaultConfigRatios(quantity)["shippingRecordForms"]);
        setCytedSampleReturnBags(
          orderDefaultConfigRatios(quantity)["cytedSampleReturnBags"]
        );
      } else if (courier === Couriers.UPS) {
        setCanisters(undefined);
        setShippingRecordForms(undefined);
        setCytedSampleReturnBags(undefined);
        setUPSReturnBoxes(orderDefaultConfigRatios(quantity)["upsReturnBoxes"]);
      }
    } else {
      setCanisters(undefined);
      setRequestForms(undefined);
      setShippingRecordForms(undefined);
      setCytedSampleReturnBags(undefined);
      setUPSReturnBoxes(undefined);
    }
  }

  const onChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(event.target.value);
    setSampleCollectionKitsQuantity(newQuantity);
    updateOrderConfig(newQuantity, courier);
    if (newQuantity > 0) {
      setIsNonKitsOrder(false);
    }
  };

  const onChangeNonKitOrder = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsNonKitsOrder(checked);
    setCourier("");
    // If NonKitsOrderInput is checked,
    // and the current Sample collection kits  quantity > 0
    // then we reset quantity to zero since you cannot
    // have Sample collection kits  in a NonKitsOrder
    if (checked && sampleCollectionKitsQuantity && sampleCollectionKitsQuantity > 0) {
      // Note: must set to zero (not undefined)
      // as otherwise input component doesn't update
      setSampleCollectionKitsQuantity(0);
      updateOrderConfig(0, "");
    }
  };

  const onChangeCourier = (event: ChangeEvent<HTMLInputElement>) => {
    const newCourier = event.target.value;
    setCourier(newCourier);
    updateOrderConfig(sampleCollectionKitsQuantity, newCourier);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const oesophagealCellSampleCollectionKitQuantity = sampleCollectionKitsQuantity || 0; // As backend expects a number >= 0
    createOrderMutation({
      variables: {
        order: {
          studyId,
          hcpId,
          oesophagealCellSampleCollectionKitQuantity,
          deliveryAddress: {
            dept,
            firstName,
            lastName,
            lineOne,
            lineTwo,
            town,
            county,
            postcode,
          },
          shippingDeadline,
          orderConfiguration: {
            courier,
            oesophagealCellSampleCollectionKitQuantity,
            canisters,
            requestForms,
            shippingRecordForms,
            cytedSampleReturnBags,
            upsReturnBoxes,
          },
        },
      },
    }).then(() => {
      props.history.push("/orders");
    });
  };

  const { loading, error, data } = useQuery(STUDIES_LIST);
  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;
  const studiesInputList = data.studies;

  return (
    <div className="column is-8 is-offset-2">
      {/* Form Header */}
      <div className="level">
        {/* <!-- Left side --> */}
        <div className="level-left">
          <h1 className="title is-size-2">Create new order</h1>
        </div>
      </div>

      <hr></hr>

      <form onSubmit={handleSubmit}>
        <SelectFormInput
          labelName="Study / Procurement"
          value={studyId}
          inputList={studiesInputList}
          onChange={onChangeStudy}
          required={true}
          dataTestId={TEST_ID_STUDY_SELECT}
        />
        <SelectFormInput
          labelName="Healthcare Provider"
          value={hcpId}
          inputList={hcpInputList}
          onChange={(event) => {
            setHCPId(event.target.value);
          }}
          required={true}
          dataTestId={TEST_ID_HEALTHCARE_PROVIDER_SELECT}
        />

        {/* The Sample collection kit quantity input is required unless the order is for non kit items */}
        <div className="field is-horizontal">
          <div className="field-label is-normal" style={LABEL_STYLE}>
            <label className="label" htmlFor="sampleCollectionKitsQuantity">
              Oesophageal cell sample collection kit quantity{" "}
              {isNonKitsOrder ? OPTIONAL_FIELD : REQUIRED_FIELD_STAR}
            </label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input
                  className="input"
                  type="number"
                  id="sampleCollectionKitsQuantity"
                  name="sampleCollectionKitsQuantity"
                  placeholder={"0"}
                  min={isNonKitsOrder ? 0 : 1}
                  value={sampleCollectionKitsQuantity ?? ""}
                  onChange={onChangeQuantity}
                  data-testid={TEST_ID_SAMPLE_COLLECTION_KIT_QUANTITY_INPUT}
                  required={!isNonKitsOrder}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal" style={LABEL_STYLE}>
            <label className="label checkbox" htmlFor="nonKitsOrder">
              Order does not contain oesophageal cell sample collection kits
            </label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control" style={CHECKBOX_STYLE}>
                <label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    id="nonKitsOrder"
                    name="nonKitsOrder"
                    onChange={onChangeNonKitOrder}
                    data-testid={TEST_ID_NON_KITS_ORDER_INPUT}
                    checked={isNonKitsOrder}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <AddressFormComponent
          firstName={firstName}
          lastName={lastName}
          dept={dept}
          lineOne={lineOne}
          lineTwo={lineTwo}
          town={town}
          county={county}
          postcode={postcode}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setDept={setDept}
          setLineOne={setLineOne}
          setLineTwo={setLineTwo}
          setTown={setTown}
          setCounty={setCounty}
          setPostcode={setPostcode}
        />
        <hr></hr>

        <div className="field is-horizontal">
          <div className="field-label is-normal" style={LABEL_STYLE}>
            <label className="label" htmlFor="shipping-deadline">
              Shipping deadline {REQUIRED_FIELD_STAR}
            </label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input
                  className="input"
                  type="date"
                  id="shipping-deadline"
                  name="shipping-deadline"
                  value={shippingDeadline}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setShippingDeadline(event.target.value);
                  }}
                  data-testid={TEST_ID_SHIPPING_DEADLINE_INPUT}
                  required
                />
              </div>
              <p className="help">Enter date in the format dd/mm/yyyy</p>
            </div>
          </div>
        </div>

        <hr></hr>
        {!isNonKitsOrder && (
          // Courier radio buttons are not shown, and not required
          // if the order is for non kit items
          <div className="field is-horizontal">
            <div className="field-label is-normal" style={LABEL_STYLE}>
              <label className="label">Return courier {REQUIRED_FIELD_STAR}</label>
            </div>
            <div className="field-body">
              <div className="field is-grouped">
                <div className="control">
                  <label
                    className="radio"
                    style={RADIO_STYLE}
                    htmlFor={Couriers.CITYSPRINT}
                  >
                    <input
                      type="radio"
                      id={Couriers.CITYSPRINT}
                      name="courier"
                      value={Couriers.CITYSPRINT}
                      onChange={onChangeCourier}
                      required={!isNonKitsOrder}
                    />
                    {Couriers.CITYSPRINT}
                  </label>
                  <label className="radio" style={RADIO_STYLE} htmlFor={Couriers.UPS}>
                    <input
                      type="radio"
                      id={Couriers.UPS}
                      name="courier"
                      value={Couriers.UPS}
                      onChange={onChangeCourier}
                      required={!isNonKitsOrder}
                    />
                    {Couriers.UPS}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {courier && !isConfigEditable && (
          // Customise order button is only shown if a
          // courier is selected and the customise order button
          // has not been clicked
          <div className="field is-horizontal">
            <div className="field-label is-normal" style={LABEL_STYLE}></div>
            <div className="field-body">
              <button
                type="button"
                className="button"
                onClick={() => setIsConfigEditable(true)}
                data-testid={TEST_ID_EDIT_CONFIG_BUTTON}
              >
                <span className="icon">
                  <i className="fas fa-edit"></i>
                </span>
                <span>Customise order</span>
              </button>
            </div>
          </div>
        )}
        {courier && !isNonKitsOrder && (
          <OrderConfigComponentCourier
            sampleCollectionKitsQuantity={sampleCollectionKitsQuantity}
            canisters={canisters}
            requestForms={requestForms}
            shippingRecordForms={shippingRecordForms}
            cytedSampleReturnBags={cytedSampleReturnBags}
            upsReturnBoxes={upsReturnBoxes}
            setCanisters={setCanisters}
            setRequestForms={setRequestForms}
            setShippingRecordForms={setShippingRecordForms}
            setCytedSampleReturnBags={setCytedSampleReturnBags}
            setUPSReturnBoxes={setUPSReturnBoxes}
            isConfigEditable={isConfigEditable}
            courier={courier}
          />
        )}
        {isNonKitsOrder && !courier && (
          <OrderConfigComponentNonKits
            canisters={canisters}
            requestForms={requestForms}
            shippingRecordForms={shippingRecordForms}
            cytedSampleReturnBags={cytedSampleReturnBags}
            upsReturnBoxes={upsReturnBoxes}
            setCanisters={setCanisters}
            setRequestForms={setRequestForms}
            setShippingRecordForms={setShippingRecordForms}
            setCytedSampleReturnBags={setCytedSampleReturnBags}
            setUPSReturnBoxes={setUPSReturnBoxes}
          />
        )}
        <hr></hr>

        <div className="field is-horizontal">
          <div className="field-label is-normal" style={LABEL_STYLE}>
            {/* <!-- Left empty for spacing --> */}
          </div>
          <div className="field-body">
            <div className="field is-grouped">
              <div className="control">
                <button
                  className={`button is-primary ${
                    createOrderLoading ? "is-loading" : ""
                  }`}
                  type="submit"
                  data-testid={TEST_ID_CREATE_ORDER_BUTTON}
                >
                  Create
                </button>
              </div>
              <div className="control">
                <Link className="button is-danger is-inverted" to="/orders">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withRouter(NewOrderForm);
