import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import LoadingSpinner from "../../atoms/Spinner";
import { Study, Organisation } from "../../types/entities";
import { ILinkStudyOrg } from "../../types/admin";

export const TEST_ID_STUDY_SELECT = "StudySelect";
export const TEST_ID_ORG_SELECT = "OrgSelect";
export const TEST_ID_LINK_BUTTON = "LinkButton";

export const LINK_STUDY_ORG_MUTATION = gql`
  mutation LinkStudyToOrganisation($organisationId: String!, $studyId: String!) {
    linkStudyToOrganisation(organisationId: $organisationId, studyId: $studyId)
  }
`;

export const ORGS_INPUT_LIST = gql`
  query Organisations {
    organisations {
      id
      name
    }
  }
`;

export const STUDIES_INPUT_LIST = gql`
  query Studies {
    studies {
      id
      name
    }
  }
`;

function LinkStudyOrgForm() {
  const [mutationData, setMutationData] = useState({} as ILinkStudyOrg);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [linkStudyOrgMutation] = useMutation(LINK_STUDY_ORG_MUTATION);

  const {
    loading: org_loading,
    error: org_error,
    data: org_query_data,
  } = useQuery(ORGS_INPUT_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: study_loading,
    error: study_error,
    data: study_query_data,
  } = useQuery(STUDIES_INPUT_LIST, {
    fetchPolicy: "cache-and-network",
  });

  if (org_loading || study_loading) return <LoadingSpinner />;
  if (org_error || study_error) return <p>Error: {org_error || study_error}</p>;

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    setMutationData({ ...mutationData, [key]: value });
  };

  function validateForm() {
    return mutationData.organisationId && mutationData.studyId;
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql CreateOrg mutation
    linkStudyOrgMutation({
      variables: mutationData,
    })
      .then(() => {
        const msg = (
          <p className="help is-success">Study successfully linked to organisation</p>
        );
        setFormMessage(msg);

        // clear form
        setMutationData({
          organisationId: "",
          studyId: "",
        } as ILinkStudyOrg);
      })
      .catch((error) => {
        const msg = (
          <p className="help is-danger">
            There was an error: {JSON.stringify(error.message)}
          </p>
        );
        setFormMessage(msg);
      });
  };

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">Link Study and Organisation</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Study</label>
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select
                      name="studyId"
                      value={mutationData.studyId}
                      onChange={onChange}
                      data-testid={TEST_ID_STUDY_SELECT}
                    >
                      <option value="">---------</option>
                      {study_query_data.studies.map((study: Study, index: number) => (
                        <option key={index} value={String(study.id)}>
                          {study.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Organisation</label>
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select
                      name="organisationId"
                      value={mutationData.organisationId}
                      onChange={onChange}
                      data-testid={TEST_ID_ORG_SELECT}
                    >
                      <option value="">---------</option>
                      {org_query_data.organisations.map(
                        (org: Organisation, index: number) => (
                          <option key={index} value={String(org.id)}>
                            {org.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
                data-testid={TEST_ID_LINK_BUTTON}
              >
                Link Study to Organisation
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/orders">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(LinkStudyOrgForm);
