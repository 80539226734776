import * as React from "react";

export interface ListProps<T> {
  items: T[];
  itemRenderer: (item: T) => JSX.Element;
}

function List<T>(props: ListProps<T>): JSX.Element {
  const { items, itemRenderer } = props;
  return <>{items.map(itemRenderer)}</>;
}

export default List;
