import React from "react";
import { Redirect } from "react-router";
import { setAuthToken } from "../auth.service";
import { useUrlFragment } from "../../helpers/query";

export const SsoLogin = (): JSX.Element => {
  // Take the token (and other metadata) from the URL parameters
  // Update the internal state.
  const queryString = useUrlFragment();

  const idToken = queryString.get("id_token");

  if (idToken)
    // If there's no idToken in the query params, redirect to the index page.
    setAuthToken(idToken);

  return <Redirect to={"/"} />;
};
