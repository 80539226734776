import { gql, useQuery } from "@apollo/client";
import React, { PropsWithChildren } from "react";
import NotFound from "../containers/NotFound";
import LoadingSpinner from "./Spinner";

export const FEATURES_LIST = gql`
  query GetMe {
    me {
      id
      features {
        featureName
        enabled
      }
    }
  }
`;

interface IFeatureProps extends PropsWithChildren<unknown> {
  flag: string;
  // If the component is a page, a 404 will be returned if the feature is not active
  // and the user tries to access the page
  page?: boolean;
}

interface featuresListElement {
  featureName: string;
  enabled: boolean;
}

export function useIsFeatureActive(flag: string): boolean {
  const { data } = useQuery(FEATURES_LIST);

  if (data) {
    const featuresList = data.me.features;
    const feature = featuresList.find(
      (element: featuresListElement) => element.featureName === flag
    );
    if (feature && feature.enabled) {
      return true;
    }
  }
  return false;
}

export const Feature: React.FunctionComponent<IFeatureProps> = (
  props: IFeatureProps
): JSX.Element | null => {
  const { loading, error, data } = useQuery(FEATURES_LIST);
  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  if (data) {
    const featuresList = data.me.features;
    const feature = featuresList.find(
      (element: featuresListElement) => element.featureName === props.flag
    );

    if (!feature) {
      return (
        <p>
          ERROR: The feature {props.flag} could not be found. Please check if it should be
          flagged.
        </p>
      );
    } else if (feature.enabled) {
      return props.children as JSX.Element;
    }
  }

  return props.page ? <NotFound /> : null;
};
