import React from "react";

export const TEST_ID_LOWER_WARNING = "ItemQuantityLowerThanExpectedWarning";
export const TEST_ID_HIGHER_WARNING = "ItemQuantityHigherThanExpectedWarning";
export const TEST_ID_MUST_BE_MULTIPLE_WARNING = "ItemMustBeMultipleOfTenWarning";

export const ItemQuantityLowerThanExpected = (): JSX.Element => {
  return (
    <p className="help has-text-danger" data-testid={TEST_ID_LOWER_WARNING}>
      Warning: Quantity is lower than required for the number of Oesophageal cell sample
      collection kits ordered.
    </p>
  );
};

export const ItemQuantityHigherThanExpected = (): JSX.Element => {
  return (
    <p className="help has-text-danger" data-testid={TEST_ID_HIGHER_WARNING}>
      Warning: Quantity is higher than required for the number of Oesophageal cell sample
      collection kits ordered.
    </p>
  );
};

export const ItemMustBeMultipleOfTen = (): JSX.Element => {
  return (
    <p className="help has-text-danger" data-testid={TEST_ID_MUST_BE_MULTIPLE_WARNING}>
      Warning: Cyted sample return bags must be ordered in bundles of 10.
    </p>
  );
};
