import React, { FormEvent, useState } from "react";
import Skeleton from "react-skeleton-loader";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import LoadingSpinner from "../../atoms/Spinner";
import {
  getPotSerialNumberFromBarcode,
  potSerialNumberRegex,
} from "../../helpers/strings";
import { PotDetailsModal } from "./PotDetailsModal";
import classnames from "classnames";

export const TEST_ID_VIEW_PROCEDURE_DETAILS_INPUT_FORM =
  "VIEW_PROCEDURE_DETAILS_INPUT_FORM";
export const statusRowTestId = (barcode: string): string => `pot-status-row-${barcode}`;
export const statusIndicatorTestId = (barcode: string): string =>
  `pot-status-row-indicator-${barcode}`;

const LIST_POTS_QUERY = gql`
  query ($date: String) {
    getUpdatedPotsByDate(date: $date)
  }
`;

const POT_STATUS_QUERY = gql`
  query ($potBarcode: String!) {
    getProcedure(potBarcode: $potBarcode) {
      potBarcode
      procedureStatus
      order {
        orderId
        healthcareProvider {
          name
        }
      }
      batch {
        batchId
      }
      history {
        action
        performedBy {
          firstName
          lastName
        }
        timestamp
      }
    }
  }
`;

interface IPotStatusQuery {
  potBarcode: string;
  procedureStatus: "RECEIVED" | "ACCEPTED" | "ERROR" | "DEFERRED";
  order?: { orderId: string; healthcareProvider: { name: string } };
  batch?: { batchId: string };
  history: Array<{
    action: string;
    performedBy: { firstName: string; lastName: string };
    timestamp: string;
  }>;
}

interface IPotListing {
  barcode: string;
  lastUpdate?: string;
  lastUpdated?: string;
  lastUpdateBy?: string;
  orderId?: string;
  batchId?: string;
  healthcareProvider?: string;
}

const mapPotData = (queryResult: IPotStatusQuery): IPotListing => {
  const lastHistoryItem = queryResult?.history[queryResult?.history.length - 1];

  return {
    barcode: queryResult?.potBarcode,
    lastUpdate: lastHistoryItem?.action,
    lastUpdated: lastHistoryItem?.timestamp,
    healthcareProvider: queryResult?.order?.healthcareProvider.name,
    lastUpdateBy:
      lastHistoryItem &&
      `${lastHistoryItem?.performedBy.firstName} ${lastHistoryItem?.performedBy.lastName}`,
    orderId: queryResult?.order?.orderId,
    batchId: queryResult?.batch?.batchId,
  };
};

const LoadingRow = ({ cols }: { cols: number }): JSX.Element => {
  return (
    <tr>
      {[...Array(cols)].map((c, i) => (
        <td key={i}>
          <Skeleton />
        </td>
      ))}
    </tr>
  );
};

const TableRow = ({
  potBarcode,
  setModalPot,
}: {
  potBarcode: string;
  setModalPot: (barcode: string) => unknown;
}): JSX.Element => {
  const { error, loading, data } = useQuery(POT_STATUS_QUERY, {
    variables: { potBarcode },
  });

  const displayBarcode = getPotSerialNumberFromBarcode(potBarcode);

  if (!displayBarcode.match(potSerialNumberRegex))
    return (
      <tr className="notification is-danger is-light">
        <td>{displayBarcode}</td>
        <td colSpan={4}> Error: Please enter a valid pot number </td>
      </tr>
    );

  if (error)
    return (
      <tr className="notification is-danger is-light">
        <td>{displayBarcode}</td>
        <td colSpan={4}> Error: {error?.message} </td>
      </tr>
    );

  if (loading) return <LoadingRow cols={6} />;

  const potListing = mapPotData(data?.getProcedure);
  return (
    <>
      <tr
        data-testid={statusRowTestId(displayBarcode)}
        className="is-clickable"
        onClick={() => {
          setModalPot(potBarcode);
        }}
      >
        <th>{displayBarcode}</th>
        <th>{potListing?.healthcareProvider}</th>
        <td>
          <span
            className={classnames("tag has-text-weight-bold is-capitalized", {
              "is-danger": potListing?.lastUpdate === "DEFERRED",
            })}
            data-testid={statusIndicatorTestId(displayBarcode)}
          >
            {potListing?.lastUpdate?.toLowerCase()}
          </span>
        </td>
        <td>{potListing?.lastUpdated && moment(potListing?.lastUpdated).fromNow()}</td>
        <td>{potListing?.lastUpdateBy}</td>
        <td>
          <span className="tag has-text-weight-bold is-primary is-light">View</span>
        </td>
      </tr>
    </>
  );
};

export const IncomingPotsPage = (): JSX.Element => {
  const [targetDate, setTargetDate] = useState(moment().format("YYYY-MM-DD"));
  const [targetPot, setTargetPot] = useState<string | undefined>("");
  const [modalPot, setModalPot] = useState<string | undefined>(undefined);

  const { error, loading, data } = useQuery(LIST_POTS_QUERY, {
    pollInterval: 4000,
    variables: { date: targetDate },
  });

  if (error)
    return <div className="notification is-danger is-light">Error: {error?.message}</div>;
  if (loading) return <LoadingSpinner />;

  const pots = [...data.getUpdatedPotsByDate];
  pots.sort().reverse();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setModalPot(targetPot);
  };

  return (
    <div className="container">
      <div className="level-left">
        <p className="title mb-5">Look Up Received Procedures</p>
      </div>
      <div className="section box">
        <div className="level-left">
          <p className="subtitle is-size-5">By Pot Barcode</p>
        </div>
        <div className="py-3">
          <div className="field">
            <span>
              <form className="control has-icons-left mt-3" onSubmit={handleSubmit}>
                <span className="icon is-small is-left">
                  <i className="fas fa-barcode-alt"></i>
                </span>
                <div className="is-left">
                  <input
                    placeholder="Scan or enter pot barcode to view procedure details"
                    data-testid={TEST_ID_VIEW_PROCEDURE_DETAILS_INPUT_FORM}
                    name="updatePotInput"
                    id="potInput"
                    className="input"
                    type="text"
                    value={targetPot}
                    onChange={(ev) => setTargetPot(ev.target.value.toUpperCase())}
                  />
                </div>
              </form>
            </span>
          </div>
        </div>
      </div>
      <div className="section box">
        {/* <!-- Left side --> */}
        <div className="level-left">
          <p className="subtitle is-size-5">By Date</p>
        </div>
        <div>
          <div className="level field is-grouped is-grouped-right">
            <div className="control level-left">
              <label className="label">Last updated:</label>
            </div>
            <div className="control">
              <input
                name="updateDateInput"
                className="input"
                type="date"
                value={targetDate}
                max={moment().format("YYYY-MM-DD")}
                onChange={(ev) => setTargetDate(ev.target.value)}
              />
            </div>
          </div>
        </div>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Sample Barcode</th>
              <th>Healthcare Provider</th>
              <th colSpan={2}>Last Updated</th>
              <th>Updated By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pots.map((potBarcode: string, idx: number) => (
              <TableRow potBarcode={potBarcode} key={idx} setModalPot={setModalPot} />
            ))}
          </tbody>
        </table>
        <PotDetailsModal
          potBarcode={modalPot}
          closeAction={() => {
            setModalPot(undefined);
          }}
        />
      </div>
    </div>
  );
};
