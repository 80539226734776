import React from "react";
import { gql, useQuery } from "@apollo/client";
import LoadingSpinner from "../../atoms/Spinner";
import moment from "moment";
import { IHistoryItem } from "../../types/history";

const ORDER_HISTORY_QUERY = gql`
  query GetOrderHistory($orderId: String!) {
    getOrder(orderId: $orderId) {
      orderId
      history {
        timestamp
        action
        performedBy {
          firstName
          lastName
        }
      }
    }
  }
`;

const humaniseEvents = (event: string): string => {
  const humanisedEventsMap: Record<string, string> = {
    ACKNOWLEDGED: "Order Acknowledged",
    CREATED: "Order Created",
    FULFILLMENT_STARTED: "Fulfillment Started",
    FULFILLED: "Order Fulfilled",
    SHIPPED: "Order Shipped",
    CLOSED: "Order Closed",
  };

  return humanisedEventsMap[event] || event;
};

const HistoryItem = (historyPoint: IHistoryItem) => (
  <div className="timeline-item">
    <div className="timeline-marker is-icon">
      <i className="fa fa-arrow-right"></i>
    </div>
    <div className="timeline-content">
      <p className="heading">{moment(historyPoint.timestamp).calendar()}</p>
      <h2 className="is-size-5">{humaniseEvents(historyPoint.action)}</h2>
      <p>
        by{" "}
        <strong>
          {historyPoint.performedBy?.firstName} {historyPoint.performedBy?.lastName}
        </strong>
      </p>
    </div>
  </div>
);

const OrderDetailTimeline = ({ orderId }: { orderId: string }): JSX.Element => {
  const { loading, error, data } = useQuery(ORDER_HISTORY_QUERY, {
    variables: { orderId },
    pollInterval: 2000,
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  const timelineItems = data.getOrder.history.map((item: IHistoryItem, index: number) => (
    <HistoryItem key={index} {...item} />
  ));

  return (
    <div className="">
      <div className="timeline">{timelineItems}</div>
    </div>
  );
};

export default OrderDetailTimeline;
