import React, { PropsWithChildren } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./containers/Login";
import OrdersList from "./containers/Order/OrdersList";
import NotFound from "./containers/NotFound";
import NewOrderForm from "./containers/Order/NewOrderForm";
import SecuredRoute from "./services/secured-route";
import OrderDetailPage from "./containers/Order/OrderDetail";
import IncomingProceduresPage from "./containers/Procedure/IncomingProceduresPage";
import AdminPage from "./containers/Admin/AdminPage";
import NewPotBatchForm from "./containers/PotManagement/NewPotBatchForm";
import PotBatchList from "./containers/PotManagement/PotBatchList";
import PotManagementDetailPage from "./containers/PotManagement/PotManagementDetailPage";
import ScanIncomingProcedures from "./containers/Procedure/ScanIncomingProcedures/ScanIncomingProcedures";
import ProcedureDetail from "./containers/Procedure/ProcedureDetail/ProcedureDetail";
import {
  isAuthenticated,
  getCurrentUser,
  isRequiredToChangePassword,
} from "./services/auth.service";
import AccountSettingsPage from "./containers/Settings/AccountSettingsPage";
import { Feature, useIsFeatureActive } from "./atoms/FeatureFlag";
import { SsoLogin } from "./services/sso";

export default function Routes(props: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Switch>
      <Route exact path={["/", "/home", "/login"]}>
        {isAuthenticated(getCurrentUser()) ? <Redirect to="/orders" /> : <Login />}
      </Route>
      <Route exact path="/sso-login">
        <SsoLogin />
      </Route>
      <Route exact path="/settings" component={AccountSettingsPage} />
      {isRequiredToChangePassword(getCurrentUser()) && <Redirect to={"/settings"} />}
      <SecuredRoute path="/orders/:orderId" component={OrderDetailPage} />
      <SecuredRoute exact path="/orders" component={OrdersList} />
      <SecuredRoute exact path="/new-order" component={NewOrderForm} />
      <SecuredRoute
        path="/pots-management/:batchId"
        component={PotManagementDetailPage}
      />
      <SecuredRoute exact path="/pots-management" component={PotBatchList} />
      <SecuredRoute exact path="/new-pot-batch" component={NewPotBatchForm} />

      <SecuredRoute
        exact
        path="/procedures"
        component={() => {
          return (
            <Feature flag="PRINT_REQUEST_FORM" {...props}>
              <IncomingProceduresPage />
            </Feature>
          );
        }}
      />
      {/* 
        This is the old inbound scan form. 
        TODO: remove this when the IncomingProceduresPage is no longer feature flagged
       */}
      {useIsFeatureActive("PRINT_REQUEST_FORM") ? (
        <SecuredRoute
          exact
          path="/inbound"
          component={() => <Redirect to="/procedures" />}
        />
      ) : (
        <SecuredRoute exact path="/inbound" component={ScanIncomingProcedures} />
      )}
      <SecuredRoute exact path="/procedures/:potBarcode" component={ProcedureDetail} />

      <SecuredRoute exact path="/admin" component={AdminPage} />
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
