import React, { useState } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { gql } from "@apollo/client";

import PotBatchListCard from "./PotBatchListCard";
import ListPage from "../Common/ListPage";
import { IBatch } from "../../types/batch";

export const BATCHES_PER_PAGE = 20;
export const BATCH_LIST_QUERY = gql`
  query ($limit: Int!, $page: Int!) {
    getBatches(limit: $limit, page: $page) {
      batchId
      batchIdSlug
      batchIdentifier
      status
      countOfPots
      manufacturerBatchNumber
      containerType
    }
  }
`;

const PotBatchListPage = (props: RouteComponentProps) => {
  const [page, setPage] = useState(0);
  return (
    <>
      <ListPage
        query={BATCH_LIST_QUERY}
        buttons={
          <Link
            className="button level-item is-success is-outlined"
            to="/new-pot-batch"
            id="Accounts-NewBatchForm"
          >
            <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span>
            <span>New batch</span>
          </Link>
        }
        heading="Pot Batches"
        variables={{ page, limit: BATCHES_PER_PAGE }}
        itemsMapper={(data: Record<string, unknown>) => data.getBatches as IBatch[]}
        itemRenderer={(item: IBatch) => (
          <PotBatchListCard key={item.batchId} batch={item} {...props} />
        )}
      />
      <nav className="pagination mt-3" role="navigation" aria-label="pagination">
        {page > 0 ? (
          <button
            className="pagination-previous"
            type="button"
            onClick={() => setPage(page - 1)}
          >
            Previous page
          </button>
        ) : (
          <button className="pagination-previous is-static" type="button" disabled>
            Previous page
          </button>
        )}
        <button
          className="pagination-next"
          type="button"
          onClick={() => setPage(page + 1)}
        >
          Next page
        </button>
      </nav>
    </>
  );
};

export default withRouter(PotBatchListPage);
