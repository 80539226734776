import React, { useState, ChangeEvent, FormEvent } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import LoadingSpinner from "../../atoms/Spinner";
import { Link, withRouter } from "react-router-dom";
import { v4 as UUID } from "uuid";

export const TEST_ID_USER_SELECT = "UserSelect";
export const TEST_ID_NEW_PASSWORD_INPUT = "NewPasswordInput";
export const TEST_ID_CONFIRM_NEW_PASSWORD_INPUT = "ConfirmNewPasswordInput";
export const TEST_ID_UPDATE_PASSWORD_BUTTON = "UpdatePasswordButton";
export const TEST_ID_CHANGE_ANOTHER_USER_PASSWORD_SUCCESS =
  "ChangeAnotherUserPasswordSuccess";

const CHANGE_USER_PASSWORD_MUTATION = gql`
  mutation changeUserPassword($userId: String!, $password: String!) {
    changeUserPassword(userId: $userId, password: $password)
  }
`;

export const USERS_LIST = gql`
  query Organisations {
    organisations {
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

interface IChangeUserPasswordObj {
  id: string;
  new: string;
  new_repeat: string;
}

const BLANK_FORM: IChangeUserPasswordObj = {
  id: "",
  new: "",
  new_repeat: "",
};

function ChangePasswordForm() {
  const [changeUserPasswordObj, setChangeUserPasswordObj] = useState(BLANK_FORM);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [changeUserPasswordMutation] = useMutation(CHANGE_USER_PASSWORD_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    setChangeUserPasswordObj({ ...changeUserPasswordObj, [key]: value });
  };

  function validateForm() {
    return (
      changeUserPasswordObj.id &&
      changeUserPasswordObj.new &&
      changeUserPasswordObj.new_repeat &&
      changeUserPasswordObj.new === changeUserPasswordObj.new_repeat
    );
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql changePasswordMutation mutation
    changeUserPasswordMutation({
      variables: {
        userId: changeUserPasswordObj.id,
        password: changeUserPasswordObj.new,
      },
    })
      .then(() => {
        const msg = (
          <p
            data-testid={TEST_ID_CHANGE_ANOTHER_USER_PASSWORD_SUCCESS}
            className="help is-success"
          >
            Password changed successfully
          </p>
        );
        setFormMessage(msg);

        // clear form
        setChangeUserPasswordObj(BLANK_FORM);
      })
      .catch((error) => {
        const msg = <p className="help is-danger">There was an error: {error.message}</p>;
        setFormMessage(msg);
      });
  };

  //   get list of users from graphql
  const { loading, error, data } = useQuery(USERS_LIST);
  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error: {error.message}</p>;

  interface IUser {
    __typename: string;
    id: typeof UUID;
    firstName: string;
    lastName: string;
    email: string;
  }

  interface IOrganisation {
    __typename: string;
    users: IUser[];
  }

  const UserInputList = data.organisations.reduce(
    (acc: IOrganisation[], curr: IOrganisation) => {
      return [...acc, ...curr.users];
    },
    []
  );

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">Change a user&apos;s password</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">User</label>
            <div className="control">
              <div className="select">
                <select
                  name="id"
                  value={changeUserPasswordObj.id}
                  onChange={onChange}
                  data-testid={TEST_ID_USER_SELECT}
                >
                  <option value="">---------</option>
                  {UserInputList.map((user: IUser) => (
                    <option key={String(user.id)} value={String(user.id)}>
                      {`${user.firstName} ${user.lastName} - ${user.email}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field mt-4">
            <div className="field-body">
              <div className="field">
                <label className="label">New password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="new"
                    value={changeUserPasswordObj.new}
                    onChange={onChange}
                    data-testid={TEST_ID_NEW_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field mt-4">
            <div className="field-body">
              <div className="field">
                <label className="label">Confirm new password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    name="new_repeat"
                    value={changeUserPasswordObj.new_repeat}
                    onChange={onChange}
                    data-testid={TEST_ID_CONFIRM_NEW_PASSWORD_INPUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="help mb-4">
            Make sure it&apos;s at least 8 characters including one upper case, one lower
            case, one number and one special character.
          </p>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
                data-testid={TEST_ID_UPDATE_PASSWORD_BUTTON}
              >
                Update password
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/settings">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(ChangePasswordForm);
