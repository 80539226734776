import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation, gql } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import { INewOrg } from "../../types/admin";

export const TEST_ID_ORGANISATION_NAME_INPUT = "OrganisationNameInput";
export const TEST_ID_ORGANISATION_TYPE_INPUT = "OrganisationTypeInput";
export const TEST_ID_CREATE_ORGANISATION_BUTTON = "CreateOrganisationButton";
export const TEST_ID_CREATE_NEW_ORGANISATION_SUCCESS = "CreateNewOrganisationSuccess";

const CREATE_ORG_MUTATION = gql`
  mutation CreateOrganisation($organisation: CreateOrganisationInput!) {
    createOrganisation(organisation: $organisation) {
      name
      organisationType
    }
  }
`;

export const ORGS_TYPE_INPUT_LIST = ["Admin", "Healthcare", "Lab"];

function NewOrganisationForm() {
  const [newOrg, setNewOrg] = useState({
    name: "",
    orgType: "",
  } as INewOrg);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [createOrgMutation] = useMutation(CREATE_ORG_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = event.target.name;
    const value = event.target.value;
    setNewOrg({ ...newOrg, [key]: value });
  };

  function validateForm() {
    return newOrg.name && newOrg.orgType;
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // send graphql CreateOrg mutation
    createOrgMutation({
      variables: {
        organisation: newOrg,
      },
    })
      .then(() => {
        const msg = (
          <p
            className="help is-success"
            data-testid={TEST_ID_CREATE_NEW_ORGANISATION_SUCCESS}
          >
            Organisation created successfully
          </p>
        );
        setFormMessage(msg);

        // clear form
        setNewOrg({
          name: "",
          orgType: "",
        } as INewOrg);
      })
      .catch((error) => {
        const msg = (
          <p className="help is-danger">
            There was an error: {JSON.stringify(error.message)}
          </p>
        );
        setFormMessage(msg);
      });
  };

  return (
    <div className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">New Organisation</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Organisation Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    value={newOrg.name}
                    onChange={onChange}
                    data-testid={TEST_ID_ORGANISATION_NAME_INPUT}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Organisation Type</label>
                <div className="control">
                  <div className="select">
                    <select
                      name="orgType"
                      value={newOrg.orgType}
                      onChange={onChange}
                      data-testid={TEST_ID_ORGANISATION_TYPE_INPUT}
                    >
                      <option value="">---------</option>
                      {ORGS_TYPE_INPUT_LIST.map((type: string, index: number) => (
                        <option key={index} value={type.toUpperCase()}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!validateForm()}
                type="submit"
                data-testid={TEST_ID_CREATE_ORGANISATION_BUTTON}
              >
                Create Organisation
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/orders">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewOrganisationForm);
