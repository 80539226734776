import React from "react";
import {
  IOutstandingPotStatusSettingsMap,
  OutstandingPotStatusSettings,
} from "../../../types/procedures";

const getOutstandingPotStatusSettings = (
  status: string
): OutstandingPotStatusSettings => {
  return (
    OutstandingPotStatusSettingsMap[status] || OutstandingPotStatusSettingsMap["default"]
  );
};

const OutstandingPotStatusSettingsMap: IOutstandingPotStatusSettingsMap = {
  RECENT: {
    indicatorColour: "info",
    icon: "fas fa-notes-medical",
    buttonIcon: "fas fa-notes-medical",
    buttonText: "Recent",
  },
  LATE: {
    indicatorColour: "warning",
    icon: "fas fa-notes-medical",
    buttonIcon: "fas fa-exclamation-triangle",
    buttonText: "Late",
  },
  default: {
    indicatorColour: "danger",
    icon: "fas fa-exclamation-triangle",
    buttonIcon: "fas fa-exclamation-triangle",
    buttonText: "Error",
  },
};

const OutstandingPotStatusIndicator = ({
  settings,
}: {
  settings: OutstandingPotStatusSettings;
}): JSX.Element => {
  const { indicatorColour, icon } = settings;

  return (
    <div className="column is-narrow">
      <span className="panel-icon">
        {icon != null && (
          <span className={`icon is-small has-text-${indicatorColour}`}>
            <i className={icon}></i>
          </span>
        )}
      </span>
    </div>
  );
};

const OutstandingPotStatusTag = ({
  settings,
  barcode,
}: {
  settings: OutstandingPotStatusSettings;
  barcode: string;
}): JSX.Element => {
  const { indicatorColour, buttonIcon, buttonText } = settings;

  return (
    <div
      className={`tag is-medium is-${indicatorColour}`}
      id={String(barcode)}
      style={{ pointerEvents: "none" }}
    >
      <span className="icon is-small ml-0 mr-2">
        <i className={buttonIcon}></i>
      </span>
      <span>{buttonText}</span>
    </div>
  );
};

export {
  getOutstandingPotStatusSettings,
  OutstandingPotStatusIndicator,
  OutstandingPotStatusTag,
};
