import React, { useEffect, useState } from "react";
import { Couriers } from "../../../types/order";
import { orderDefaultConfigRatios } from ".";
import { OrderConfigInputField } from "./OrderConfigInput";
import {
  ItemMustBeMultipleOfTen,
  ItemQuantityHigherThanExpected,
  ItemQuantityLowerThanExpected,
} from "./Warnings";

export const TEST_ID_SAMPLE_COLLECTION_KITS_DISPLAY_ONLY_INPUT =
  "SampleCollectionKitsDisplayOnlyInput";
export const TEST_ID_CANISTERS_INPUT = "CanistersInput";
export const TEST_ID_REQUEST_FORMS_INPUT = "RequestFormsInput";
export const TEST_ID_SHIPPING_RECORD_FORMS_INPUT = "ShippingRecordFormsInput";
export const TEST_ID_SAMPLE_RETURN_BAGS_INPUT = "CytedSampleReturnBagsInput";
export const TEST_ID_UPS_RETURN_BOXES_INPUT = "UPSReturnBoxesInput";

interface OrderConfigComponentCourierProps {
  sampleCollectionKitsQuantity?: number;
  canisters?: number;
  requestForms?: number;
  shippingRecordForms?: number;
  cytedSampleReturnBags?: number;
  upsReturnBoxes?: number;
  setCanisters: (value: number) => void;
  setRequestForms: (value: number) => void;
  setShippingRecordForms: (value: number) => void;
  setCytedSampleReturnBags: (value: number) => void;
  setUPSReturnBoxes: (value: number) => void;
  isConfigEditable: boolean;
  courier: string;
}

export const OrderConfigComponentCourier = (
  props: OrderConfigComponentCourierProps
): JSX.Element => {
  const {
    sampleCollectionKitsQuantity,
    canisters,
    requestForms,
    shippingRecordForms,
    cytedSampleReturnBags,
    upsReturnBoxes,
    setCanisters,
    setRequestForms,
    setShippingRecordForms,
    setCytedSampleReturnBags,
    setUPSReturnBoxes,
    isConfigEditable,
    courier,
  } = props;

  const configRequired = true;

  const isLowerThanExpected = (
    variable: number | undefined,
    varNameAsStr: string // e.g. if variable is named canisters, varNameAsStr should be "canisters"
  ): boolean => {
    return (
      !!sampleCollectionKitsQuantity &&
      sampleCollectionKitsQuantity > 0 &&
      !!variable &&
      variable < orderDefaultConfigRatios(sampleCollectionKitsQuantity)[varNameAsStr]
    );
  };
  const isHigherThanExpected = (
    variable: number | undefined,
    varNameAsStr: string // e.g. if variable is named canisters, varNameAsStr should be "canisters"
  ): boolean => {
    return (
      !!sampleCollectionKitsQuantity &&
      sampleCollectionKitsQuantity > 0 &&
      !!variable &&
      variable > orderDefaultConfigRatios(sampleCollectionKitsQuantity)[varNameAsStr]
    );
  };

  return (
    // Packing item inputs are only shown when a courier
    // is selected, or if the order is for non kit items
    // If the order is for non kit items,
    // then at least one of the packing items must have a value greater than zero
    <>
      {
        // This Sample collection kits field
        // is not editable, and just mirrors the
        // Oesophageal cell sample collection kit quantity input field
        // at the top of the page
      }
      <OrderConfigInputField
        labelName="Oesophageal cell sample collection kits"
        value={sampleCollectionKitsQuantity}
        minimum={1}
        isEditable={false}
        required={false}
        dataTestId={TEST_ID_SAMPLE_COLLECTION_KITS_DISPLAY_ONLY_INPUT}
      />
      <OrderConfigInputField
        labelName="Request forms"
        value={requestForms}
        minimum={0}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setRequestForms(Number(event.target.value));
        }}
        required={configRequired}
        dataTestId={TEST_ID_REQUEST_FORMS_INPUT}
      />
      {
        // Inputs for Canisters, Shipping record forms and Cyted sample
        // return bags are only shown when the courier is CitySprint,
        // or when the order is for non-Sample collection kits
        courier && courier === Couriers.CITYSPRINT && (
          <>
            <OrderConfigInputField
              labelName="Canisters"
              value={canisters}
              minimum={1}
              isEditable={isConfigEditable}
              onChange={(event) => {
                setCanisters(Number(event.target.value));
              }}
              dataTestId={TEST_ID_CANISTERS_INPUT}
              required={configRequired}
              warning={
                <>
                  {isLowerThanExpected(canisters, "canisters") && (
                    <ItemQuantityLowerThanExpected />
                  )}
                  {isHigherThanExpected(canisters, "canisters") && (
                    <ItemQuantityHigherThanExpected />
                  )}
                </>
              }
            />
            <OrderConfigInputField
              labelName="Shipping record forms"
              value={shippingRecordForms}
              minimum={0}
              isEditable={isConfigEditable}
              onChange={(event) => {
                setShippingRecordForms(Number(event.target.value));
              }}
              required={configRequired}
              dataTestId={TEST_ID_SHIPPING_RECORD_FORMS_INPUT}
            />
            <OrderConfigInputField
              labelName="Cyted sample return bags"
              value={cytedSampleReturnBags}
              minimum={0}
              stepSize={10}
              isEditable={isConfigEditable}
              onChange={(event) => {
                setCytedSampleReturnBags(Number(event.target.value));
              }}
              required={configRequired}
              dataTestId={TEST_ID_SAMPLE_RETURN_BAGS_INPUT}
              warning={
                cytedSampleReturnBags && cytedSampleReturnBags % 10 !== 0 ? (
                  <ItemMustBeMultipleOfTen />
                ) : (
                  <></>
                )
              }
            />
          </>
        )
      }
      {
        // Inputs for UPS Return boxes are only shown
        // when the courier is UPS,
        // or when the order is for non-Sample collection kits
        courier && courier === Couriers.UPS && (
          <OrderConfigInputField
            labelName="Pre-labeled courier return box"
            value={upsReturnBoxes}
            minimum={1}
            isEditable={isConfigEditable}
            onChange={(event) => {
              setUPSReturnBoxes(Number(event.target.value));
            }}
            required={configRequired}
            dataTestId={TEST_ID_UPS_RETURN_BOXES_INPUT}
            warning={
              <>
                {isLowerThanExpected(upsReturnBoxes, "upsReturnBoxes") && (
                  <ItemQuantityLowerThanExpected />
                )}
                {isHigherThanExpected(upsReturnBoxes, "upsReturnBoxes") && (
                  <ItemQuantityHigherThanExpected />
                )}
              </>
            }
          />
        )
      }
    </>
  );
};

interface OrderConfigComponentNonKitsProps {
  canisters?: number;
  requestForms?: number;
  shippingRecordForms?: number;
  cytedSampleReturnBags?: number;
  upsReturnBoxes?: number;
  setCanisters: (value: number) => void;
  setRequestForms: (value: number) => void;
  setShippingRecordForms: (value: number) => void;
  setCytedSampleReturnBags: (value: number) => void;
  setUPSReturnBoxes: (value: number) => void;
}

export const OrderConfigComponentNonKits = (
  props: OrderConfigComponentNonKitsProps
): JSX.Element => {
  const {
    canisters,
    requestForms,
    shippingRecordForms,
    cytedSampleReturnBags,
    upsReturnBoxes,
    setCanisters,
    setRequestForms,
    setShippingRecordForms,
    setCytedSampleReturnBags,
    setUPSReturnBoxes,
  } = props;

  const isConfigEditable = true;
  const [configRequired, setConfigRequired] = useState(true);
  const minimum = 1;

  useEffect(() => {
    const gtzero = (value: number | undefined) => !!value && value > 0;
    const configFieldsList = [
      canisters,
      requestForms,
      shippingRecordForms,
      cytedSampleReturnBags,
      upsReturnBoxes,
    ];

    // If the order is non Sample collection kits,
    // and one of the packing items has value > 0,
    // then set all of the packing items as non-required
    const isAConfigInputPresent = configFieldsList.some(gtzero);
    setConfigRequired(!isAConfigInputPresent);
  }, [
    canisters,
    requestForms,
    shippingRecordForms,
    cytedSampleReturnBags,
    upsReturnBoxes,
  ]);

  return (
    // Packing item inputs are all shown if the order is for non kit
    // items, and at least one of the packing items must have a value
    // greater than zero
    <>
      <OrderConfigInputField
        labelName="Request forms"
        value={requestForms}
        minimum={configRequired ? minimum : 0}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setRequestForms(Number(event.target.value));
        }}
        required={configRequired}
        dataTestId={TEST_ID_REQUEST_FORMS_INPUT}
      />
      <OrderConfigInputField
        labelName="Canisters"
        value={canisters}
        minimum={configRequired ? minimum : 0}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setCanisters(Number(event.target.value));
        }}
        dataTestId={TEST_ID_CANISTERS_INPUT}
        required={configRequired}
      />
      <OrderConfigInputField
        labelName="Shipping record forms"
        value={shippingRecordForms}
        minimum={configRequired ? minimum : 0}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setShippingRecordForms(Number(event.target.value));
        }}
        required={configRequired}
        dataTestId={TEST_ID_SHIPPING_RECORD_FORMS_INPUT}
      />
      <OrderConfigInputField
        labelName="Cyted sample return bags"
        value={cytedSampleReturnBags}
        minimum={configRequired ? minimum * 10 : 0}
        stepSize={10}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setCytedSampleReturnBags(Number(event.target.value));
        }}
        required={configRequired}
        dataTestId={TEST_ID_SAMPLE_RETURN_BAGS_INPUT}
        warning={
          cytedSampleReturnBags && cytedSampleReturnBags % 10 !== 0 ? (
            <ItemMustBeMultipleOfTen />
          ) : (
            <></>
          )
        }
      />
      <OrderConfigInputField
        labelName={`${Couriers.UPS} Pre-labeled courier return box`}
        value={upsReturnBoxes}
        minimum={configRequired ? minimum : 0}
        isEditable={isConfigEditable}
        onChange={(event) => {
          setUPSReturnBoxes(Number(event.target.value));
        }}
        required={configRequired}
        dataTestId={TEST_ID_UPS_RETURN_BOXES_INPUT}
      />
    </>
  );
};
