import React, { ChangeEvent } from "react";
import { LABEL_STYLE } from ".";

interface OrderConfigInputFieldProps {
  labelName: string;
  value?: number;
  minimum: number;
  stepSize?: number;
  isEditable: boolean;
  dataTestId: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  warning?: JSX.Element;
  required: boolean;
}

export const OrderConfigInputField = ({
  labelName,
  value,
  minimum,
  stepSize,
  isEditable,
  dataTestId,
  onChange,
  warning,
  required,
}: OrderConfigInputFieldProps): JSX.Element => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal" style={LABEL_STYLE}>
        <label className="label" htmlFor={labelName}>
          {labelName}
        </label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="control" style={{ width: "100px" }}>
            <input
              className={`input ${isEditable ? "" : "is-static"}`}
              type="number"
              id={labelName}
              name={labelName}
              value={value}
              placeholder={"0"}
              min={minimum}
              step={stepSize}
              onChange={onChange}
              readOnly={!isEditable}
              data-testid={dataTestId}
              required={required}
            />
          </div>
        </div>
        {warning}
      </div>
    </div>
  );
};
