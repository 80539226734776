import moment from "moment";
import { IOrder } from "src/types/order";

export function sortOrdersByDate(list: IOrder[]): IOrder[] {
  return (list as IOrder[]).sort((a, b) => {
    // Orders are sorted by shippingDeadline, ascending (soonest dispatch first)
    const date_a = parseInt(moment(a.shippingDeadline).format("YYYYMMDD")) || Infinity;
    const date_b = parseInt(moment(b.shippingDeadline).format("YYYYMMDD")) || Infinity;
    return date_a - date_b;
  });
}
