import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { IFormListCard } from "../../../types/procedures";
import {
  getOutstandingPotStatusSettings,
  OutstandingPotStatusIndicator,
  OutstandingPotStatusTag,
} from "./getOutstandingPotStatusSettings";

function isDateMoreThanXDaysAgo(isoDateString: string, days: number) {
  const date = new Date(Date.parse(isoDateString));
  return Date.now() > date.setDate(date.getDate() + days);
}

const OutstandingPotsListCard: FunctionComponent<IFormListCard> = ({
  form,
}: IFormListCard) => {
  const barcode = String(form.potBarcode);

  // if the form was received more than 7 days ago it is flagged as late
  const status = isDateMoreThanXDaysAgo(form.receivedTimestamp, 7) ? "LATE" : "RECENT";
  const outstandingPotStatusSettings = getOutstandingPotStatusSettings(status);

  return (
    <Link
      className="panel-block is-active"
      to={{
        pathname: `/procedures/${form.potBarcode}`,
        state: {
          from: "/procedures",
          fromComponent: "Service Orders-ListOutstandingPots",
        },
      }}
    >
      <OutstandingPotStatusIndicator settings={outstandingPotStatusSettings} />
      <div className="column">
        <div>
          <p className="has-text-weight-bold">Pot barcode: {form.potBarcode}</p>
          <p>
            Form received: {moment(form.receivedTimestamp).calendar()} <br />
          </p>
        </div>
      </div>
      <div className="mr-4">
        {status === "LATE" && (
          <OutstandingPotStatusTag
            settings={outstandingPotStatusSettings}
            barcode={barcode}
          />
        )}
      </div>
    </Link>
  );
};

export default OutstandingPotsListCard;
