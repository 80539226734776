import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation, gql } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { getCurrentUser } from "../../../services/auth.service";

const FILE_UPLOAD_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/upload-pdf/`;

const REQUEST_REPORT_UPLOAD_MUTATION = gql`
  mutation RequestReportUpload($potBarcode: String!) {
    requestReportUpload(potBarcode: $potBarcode)
  }
`;

interface INewClinicalReport {
  file: File;
  fileName: string;
  potBarcode: string;
}

function NewClinicalReportForm(): JSX.Element {
  const [newClinicalReport, setNewClinicalReport] = useState({} as INewClinicalReport);
  const [isButtonLoading, setbuttonLoading] = useState(false);
  const [formMessage, setFormMessage] = useState(
    <p className="help is-invisible">...</p>
  );

  const [requestReportUploadMutation] = useMutation(REQUEST_REPORT_UPLOAD_MUTATION);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setNewClinicalReport({
        ...newClinicalReport,
        file: event.target.files[0],
        fileName: event.target.files[0].name,
      });
    } else {
      const key = event.target.name;
      const value = event.target.value;
      setNewClinicalReport({ ...newClinicalReport, [key]: value });
    }
  };

  function validateForm() {
    return (
      newClinicalReport.file && newClinicalReport.fileName && newClinicalReport.potBarcode
    );
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setbuttonLoading(true);

    // send graphql mutation to request file upload_id
    requestReportUploadMutation({
      variables: {
        potBarcode: newClinicalReport.potBarcode,
      },
    })
      .then((response) => {
        // send POST request to upload file
        const upload_id = response.data.requestReportUpload;
        const url = FILE_UPLOAD_API_URL + upload_id;

        const data = new FormData();
        data.append("body", newClinicalReport.file);

        const config = {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
            "content-type": "multipart/form-data",
          },
        };
        return axios.post(url, data, config).then(() => {
          const msg = (
            <p id="upload-message" className="help is-success">
              File uploaded successfully
            </p>
          );
          setFormMessage(msg);
          setbuttonLoading(false);

          // clear form
          setNewClinicalReport({
            file: {} as File,
            fileName: "",
            potBarcode: "",
          });
        });
      })
      .catch((error) => {
        const msg = (
          <p id="upload-message" className="help is-danger">
            There was an error: {error.message}
          </p>
        );
        setFormMessage(msg);
        setbuttonLoading(false);
      });
  };

  return (
    <div id="NewClinicalReportForm" className="panel-block columns">
      <div className="container column">
        {/* Form Header */}
        <div className="level">
          {/* <!-- Left side --> */}
          <div className="level-left">
            <p className="title">New Clinical Report</p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">Pot barcode</label>
            <div className="control">
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="potBarcode"
                  autoFocus
                  placeholder="Scan barcode"
                  value={newClinicalReport.potBarcode}
                  onChange={onChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-barcode-alt"></i>
                </span>
              </p>
            </div>
          </div>
          <div className="field">
            <label className="label">Clinical Report file</label>
            <div className="file has-name is-fullwidth">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="file"
                  value=""
                  accept=".pdf"
                  onChange={onChange}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
                <span className="file-name">{newClinicalReport.fileName}</span>
              </label>
            </div>
            <p className="help">Select a PDF to upload</p>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className={`button is-primary ${isButtonLoading ? "is-loading" : ""}`}
                disabled={!validateForm()}
                type="submit"
              >
                Upload Report
              </button>
            </div>
            <div className="control">
              <Link className="button is-danger is-inverted" to="/">
                Cancel
              </Link>
            </div>
          </div>
          {formMessage}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewClinicalReportForm);
