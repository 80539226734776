import React from "react";

interface IScanSessionSummary {
  scannedCounts: {
    numPots: number;
    numBoxes: number;
  };
}

export const TEST_ID_POTS_SCANNED_COUNTER = "pots-scanned-counter";

const ScanSessionSummary = (props: IScanSessionSummary): JSX.Element => {
  const scannedCounts = props.scannedCounts;
  return (
    <>
      <div className="panel-block column">
        <table className="table">
          <tbody>
            <tr>
              <th>Pots Scanned:</th>
              <td data-testid={TEST_ID_POTS_SCANNED_COUNTER}>{scannedCounts.numPots}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ScanSessionSummary;
