import React, { ChangeEvent } from "react";
import { Organisation, Study } from "src/types/entities";
import { LABEL_STYLE, REQUIRED_FIELD_STAR } from ".";

interface SelectFormInputProps {
  labelName: string;
  value: string;
  inputList: (Study | Organisation)[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  required: boolean;
  dataTestId: string;
}

export const SelectFormInput = ({
  labelName,
  value,
  inputList,
  onChange,
  required,
  dataTestId,
}: SelectFormInputProps): JSX.Element => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal" style={LABEL_STYLE}>
        <label className="label" htmlFor={labelName}>
          {labelName} {REQUIRED_FIELD_STAR}
        </label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="control">
            <div className="select">
              <select
                id={labelName}
                name={labelName}
                value={value}
                onChange={onChange}
                data-testid={dataTestId}
                required={required}
              >
                <option value="">---------</option>
                {inputList.map((obj) => (
                  <option key={obj.name} value={String(obj.id)}>
                    {obj.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
