import axios, { AxiosResponse } from "axios";
import { getCurrentUser } from "../../../services/auth.service";

const PROCEDURE_DETAIL_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/procedures/`;

// ---------------
export async function baseAxiosGet(
  url: string,
  options = {}
): Promise<AxiosResponse<unknown>> {
  const response = await axios.get(url, options);
  return await response;
}

export async function getRequestForm(path: string): Promise<AxiosResponse<unknown>> {
  const config = {
    headers: {
      Authorization: `Bearer ${getCurrentUser().token}`,
    },
  };
  return baseAxiosGet(PROCEDURE_DETAIL_API_URL + path, config);
}
