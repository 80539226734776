import React, { useState, FormEvent } from "react";
import { IScanBarcodeForm } from "../types/order";

export const SCAN_BARCODE_INPUT = "ScanBarcodeInput";

export const SCAN_POT_BARCODE_FORM_TEST_ID = "scan-pot-barcode-form";

export default function ScanBarcodeForm({
  addBarcode,
  testId,
}: IScanBarcodeForm): JSX.Element {
  const [value, setValue] = useState<string>("");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    addBarcode(value.toUpperCase());
    setValue("");
  };

  return (
    <form
      onSubmit={handleSubmit}
      data-testid={testId || SCAN_POT_BARCODE_FORM_TEST_ID}
      className="control has-icons-left"
    >
      <input
        data-testid={SCAN_BARCODE_INPUT}
        type="text"
        className="input"
        placeholder="Scan new barcode"
        autoFocus
        value={value || ""}
        onChange={(event) => setValue(event.target.value)}
      />
      <span className="icon is-small is-left">
        <i className="fas fa-barcode-alt"></i>
      </span>
    </form>
  );
}
