import { IBatch, ContainerType } from "../types/batch";

export const getBatchDisplayLabel = (batch: IBatch) => {
  const { containerType, manufacturerBatchNumber, batchIdSlug } = batch;
  if (containerType === ContainerType.CYTOSPONGE) {
    return batchIdSlug;
  } else if (containerType === ContainerType.ENDOSIGN) {
    return manufacturerBatchNumber;
  } else {
    return "ERROR: Unrecognised batch type";
  }
};
