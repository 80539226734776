import React, { ChangeEvent } from "react";
import { LABEL_STYLE, OPTIONAL_FIELD, REQUIRED_FIELD_STAR } from ".";

interface AddressFormInputProps {
  labelName: string;
  value: string;
  required: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dataTestId: string;
}

export const AddressFormInput = (props: AddressFormInputProps): JSX.Element => {
  const { labelName, value, required, onChange, dataTestId } = props;
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal" style={LABEL_STYLE}>
        <label className="label" htmlFor={labelName}>
          {labelName} {required ? REQUIRED_FIELD_STAR : OPTIONAL_FIELD}
        </label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="control">
            <input
              className="input"
              type="string"
              id={labelName}
              name={labelName}
              value={value}
              onChange={onChange}
              data-testid={dataTestId}
              required={required}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
