import React from "react";
import { useMutation, gql } from "@apollo/client";

import {
  getOrderStatusSettings,
  OrderStatusIndicatorLarge,
} from "./getOrderStatusSettings";

import ConfirmOrderButton from "../../atoms/ConfirmOrderButton";
import { IOrder, OrderStatus } from "../../types/order";
import { CloseOrderButton } from "./OrderCloseCard";

export const TEST_ID_INSTRUCTION_MESSAGE = "InstructionMessage";

export const CONFIRM_ORDER_MUTATION = gql`
  mutation ConfirmOrder($order: ConfirmOrderInput!) {
    confirmOrder(order: $order)
  }
`;

interface OrderStatusCardProps {
  order: IOrder;
  onConfirm?: () => void;
  closeOrder: () => void;
  isCloseOrderLoading: boolean;
}

const OrderStatusCard = (props: OrderStatusCardProps): JSX.Element => {
  const { order, onConfirm, closeOrder, isCloseOrderLoading } = props;

  const settings = getOrderStatusSettings(order.status);
  const [confirmOrderMutation, { loading }] = useMutation(CONFIRM_ORDER_MUTATION, {
    update(cache, { data: { confirmOrderMutation } }) {
      cache.modify({
        fields: {
          getOrders(existingOrderRefs = []) {
            const newOrderRef = cache.writeQuery({
              query: CONFIRM_ORDER_MUTATION,
              data: confirmOrderMutation,
            });
            return [...existingOrderRefs, newOrderRef];
          },
        },
      });
    },
  });

  const confirmShipment = () => {
    confirmOrderMutation({
      variables: {
        order: { orderId: String(order.orderId) },
      },
    }).then(() => {
      onConfirm && onConfirm();
    });
  };

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box">
      <div className="media">
        <div className="media-left">
          <OrderStatusIndicatorLarge settings={settings} />
        </div>
        <div className="media-content">
          <p className="subtitle is-size-4">{settings.longPrettyStatus}</p>
          {
            // Show instructionMessage for all orders
            // except when order is OPEN and is NOT for cytosponge kits
            (order.status === OrderStatus.OPEN &&
              order.oesophagealCellSampleCollectionKitQuantity === 0) || (
              <p data-testid={TEST_ID_INSTRUCTION_MESSAGE}>
                {settings.instructionMessage}
              </p>
            )
          }
          <br />
          <div className="level">
            <div className="level-left">
              {order.status === OrderStatus.FULFILLED && (
                <ConfirmOrderButton isLoading={loading} confirmOrder={confirmShipment} />
              )}
              {order.status === OrderStatus.SHIPPED && (
                <CloseOrderButton
                  is-loading={isCloseOrderLoading}
                  closeOrder={closeOrder}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusCard;
