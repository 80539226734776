import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import { IMenuSections } from "../../types/menu";
import PageWithMenu from "../../atoms/PageWithMenu";
import NewClinicalReport from "./NewClinicalReport/NewClinicalReport";
import ScanIncomingProcedures from "./ScanIncomingProcedures/ScanIncomingProcedures";
import { useIsFeatureActive } from "../../atoms/FeatureFlag";
import OutstandingPotsList from "./ListOutstandingPots/OutstandingPotsList";
import { IncomingPotsPage } from "../IncomingPots";

const IncomingProceduresPage = () => {
  const location = useLocation<{ destinationComponent: string }>();
  // Clinical Report Upload Tab is feature flagged
  const newClinicalReportTab = useIsFeatureActive("CLINICAL_REPORT_UPLOAD") && {
    "Completed Orders": {
      NewClinicalReport: {
        text: "Upload Clinical Report",
        className: "",
        component: <NewClinicalReport />,
      },
    },
  };

  // List Outstanding Pots Tab is feature flagged
  const listOutstandingPotsTab = useIsFeatureActive("OUTSTANDING_POTS_LIST") && {
    ListOutstandingPots: {
      text: "List Outstanding Pots",
      className: "",
      component: <OutstandingPotsList />,
    },
  };

  const proceduresMenuObj: IMenuSections = {
    "Service Orders": {
      ScanIncomingProcedures: {
        text: "Scan Incoming Procedures",
        className: "is-active",
        component: <ScanIncomingProcedures />,
      },
      ViewIncomingPots: {
        text: "Look Up Received Procedures",
        className: "",
        component: <IncomingPotsPage />,
      },
      ...listOutstandingPotsTab,
    },
    ...newClinicalReportTab,
  };

  const defaultComponentName =
    location.state?.destinationComponent || "Service Orders-ScanIncomingProcedures";

  return (
    <PageWithMenu
      menuStructureObj={proceduresMenuObj}
      defaultComponentName={defaultComponentName}
      menuId="procedures"
    />
  );
};

export default withRouter(IncomingProceduresPage);
