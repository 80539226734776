import { v4 as UUID } from "uuid";
import { IHistoryItem } from "./history";

export interface IFormListCard {
  form: IForm;
}

export interface IForm {
  episodeId: typeof UUID;
  potBarcode: string;
  receivedTimestamp: string;
}

export interface IOutstandingPotStatusSettingsMap {
  [key: string]: OutstandingPotStatusSettings;
}

export interface OutstandingPotStatusSettings {
  icon: string;
  indicatorColour: string;
  buttonIcon: string;
  buttonText: string;
}

// --------------------------------
// Request form definition from Request Forms service
export interface NewOrderPayload {
  episode_id: string;
  message_id: string;
  patient: PatientDetails;
  clinic_reference: ClinicReferenceDetails;
  clinical_information: ClinicalInformation;
}

export interface PatientDetails {
  patient_identifier: string;
  patient_identifier_type: string;
  date_of_birth: string;
  sex: Sex;
}

export enum Sex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

interface ClinicReferenceDetails {
  site: string;
  clinic: string;
}

export interface ClinicalInformation {
  cytosponge_procedure: CytospongeProcedureDetails;
  urgent: boolean;
  infection: InfectionDetails;
  previous_endoscopic_treatment: PreviousEndoscopicTreatmentDetails;
  screening_test_referral: boolean;
  type: ProcedureType;
  symptomatic_referral: boolean;
  on_acid_suppressants: boolean;
  care_referral: CareReferral;
  dysplasia_in_past: PastDysplasiaDetails;
  known_barretts: BarrettsCaseDetails;
  other_clinical_information: string;
  date_of_procedure: string;
  requesting_clinician: string;
  procedure_performed_by: ProcedurePerformedBy;
}

export interface CytospongeProcedureDetails {
  pot_barcode: string;
  sponge_lot: string;
}

export interface InfectionDetails {
  risk_present: boolean;
  details?: string;
}

export interface PreviousEndoscopicTreatmentDetails {
  known_procedure: boolean;
  procedure_details?: string;
}

export enum ProcedureType {
  DIAGNOSTIC = "DIAGNOSTIC",
  RESEARCH = "RESEARCH",
}

export enum CareReferral {
  PRIMARY_CARE = "PRIMARY_CARE",
  SECONDARY_CARE = "SECONDARY_CARE",
}

export interface PastDysplasiaDetails {
  known_case: boolean;
  grade?: string;
}

export interface BarrettsCaseDetails {
  known_case: boolean;
  weight?: string;
  height?: string;
  prague_classification?: PragueClassification;
}

export interface ProcedurePerformedBy {
  id: string;
  name?: string;
  email?: string;
}

interface PragueClassification {
  circumferential: string;
  maximum: string;
}

// Request Form service API response type
export interface RequestForm {
  request_form: NewOrderPayload;
  warnings: RequestFormValidationErrors;
}

export interface RequestFormApiResponse {
  request_forms: RequestForm[];
}

export interface RequestFormValidationErrors {
  json_structure_error?: string;
  json_syntax_error?: string;
  validation_errors: ValidationError[];
  duplicate_pot_barcode: boolean;
  duplicate_episode_id: boolean;
}

export interface ValidationError {
  object_path: string[];
  error: string;
}

export interface GetPotDetailsQuery {
  getProcedure: {
    potBarcode: string;
    procedureStatus: string;
    order?: {
      orderId: string;
      healthcareProvider: {
        name: string;
      };
    };
    batch: { batchId: string };
    history: IHistoryItem[];
  };
}

export enum PROCEDURE_STATUS {
  ACCEPTED = "ACCEPTED",
  DEFERRED = "DEFERRED",
  RECEIVED = "RECEIVED",
}
