import React from "react";
import { withRouter } from "react-router-dom";
import NewUserForm from "./NewUserForm";
import NewOrganisationForm from "./NewOrganisationForm";
import NewStudyForm from "./NewStudyForm";
import LinkStudiesOrgsForm from "./LinkStudiesOrgsForm";
import ChangeAnotherUserPasswordForm from "./ChangeAnotherUserPasswordForm";
import { IMenuSections } from "../../types/menu";
import PageWithMenu from "../../atoms/PageWithMenu";

const adminMenuObj: IMenuSections = {
  Accounts: {
    NewUserForm: {
      text: "New User",
      className: "is-active",
      component: <NewUserForm />,
    },
    NewOrganisationForm: {
      text: "New Organisation",
      className: "",
      component: <NewOrganisationForm />,
    },
    NewStudyForm: {
      text: "New Study",
      className: "",
      component: <NewStudyForm />,
    },
    LinkStudiesOrgsForm: {
      text: "Link Study/Org",
      className: "",
      component: <LinkStudiesOrgsForm />,
    },
    ChangeAnotherUserPasswordForm: {
      text: "Change a user's password",
      className: "",
      component: <ChangeAnotherUserPasswordForm />,
    },
  },
};

function AdminPage() {
  return (
    <PageWithMenu
      menuStructureObj={adminMenuObj}
      defaultComponentName="Accounts-NewUserForm"
      menuId="admin"
    />
  );
}

export default withRouter(AdminPage);
