import React from "react";

const LoadingSpinner = (): JSX.Element => (
  <div className="level">
    <div className="container level-item">
      <span className="tag is-light is-rounded icon is-large">
        <i className="fas fa-spin fa-sync"></i>
      </span>
    </div>
  </div>
);

export default LoadingSpinner;
