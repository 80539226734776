import React, { useState } from "react";
import createProcedureTable from "./ProcedureDetailTable";
import { NewOrderPayload, RequestFormApiResponse } from "../../../types/procedures";
import LoadingSpinner from "../../../atoms/Spinner";
import { useIsFeatureActive } from "../../../atoms/FeatureFlag";
import { getPotSerialNumberFromBarcode } from "../../../helpers/strings";
import useGetRequestForm from "./useGetRequestForm";
import { AsanaLink } from "../../../atoms/AsanaLink";

// ---------------
export const TEST_ID_PROCEDURE_DETAIL_API_URL_ERROR = "procedure-detail-api-url-error";

export default function GetIncomingProcedureDetail({
  barcode,
  setAcceptButtonDisabled,
  setRequestFormCount,
}: {
  barcode: string;
  setAcceptButtonDisabled: (x: boolean) => void;
  setRequestFormCount: (x: number) => void;
}): JSX.Element {
  const [error, setError] = useState<JSX.Element | null>(null);
  const USE_NON_UNIQUE_BARCODES = useIsFeatureActive("NON_UNIQUE_BARCODES");

  const {
    loading,
    data,
    status,
    error: request_error,
  } = useGetRequestForm(barcode, setError);

  if (loading) return <LoadingSpinner />;
  if (error) return error;
  if (request_error) {
    // Special 404 condition if the NON_UNIQUE_BARCODES feature flag is NOT being used
    // TODO: remove when NON_UNIQUE_BARCODES is standard
    if (status === 404 && !USE_NON_UNIQUE_BARCODES) {
      setError(
        RequestFormRetrievalErrorMessage({
          error_type: "NO_FORM_FOUND",
          barcode,
        })
      );
    } else {
      setAcceptButtonDisabled(true);
      request_error.name = ""; // This removes the "Error:" prefix from the error msg
      setError(
        RequestFormRetrievalErrorMessage({
          error_type: "SERVER_ERROR",
          barcode,
          request_error_message: request_error.toString(),
        })
      );
    }
  }

  if (data) {
    if (USE_NON_UNIQUE_BARCODES) {
      setRequestFormCount(data.request_forms?.length || 0);

      const { result, error } = processRequestFormData_nonunique(data, barcode);
      if (result) {
        return createProcedureTable(result);
      } else {
        setError(error);
      }
    } else {
      // If the NON_UNIQUE_BARCODES feature flag is NOT being used
      // TODO: remove when NON_UNIQUE_BARCODES is standard
      return createProcedureTable(data as unknown as NewOrderPayload);
    }
  }
  return <></>;
}

// ---------------

interface processRequestFormDataReturnType {
  result: NewOrderPayload | null;
  error: JSX.Element | null;
}

function processRequestFormData_nonunique(
  data: RequestFormApiResponse,
  barcode: string
): processRequestFormDataReturnType {
  const { request_forms } = data;
  let result = null;
  let error = null;

  if (request_forms.length === 1) {
    result = request_forms[0].request_form;
  } else if (request_forms.length === 0) {
    error = RequestFormRetrievalErrorMessage({
      error_type: "NO_FORM_FOUND",
      barcode,
    });
  } else if (request_forms.length >= 2) {
    error = RequestFormRetrievalErrorMessage({
      error_type: "MULTIPLE_FORMS_FOUND",
      barcode,
      request_forms_length: request_forms.length,
    });
  }
  return { result, error };
}

// ---------------

type ErrorTypes = "NO_FORM_FOUND" | "MULTIPLE_FORMS_FOUND" | "SERVER_ERROR";
type ErrorTypesSettingsType = {
  [key in ErrorTypes]: {
    indicatorColour: string;
    icon: string;
    error_message: string;
  };
};

export const createNoFormFoundMessage = (barcode: string): string =>
  `No electronic request form found for pot ${barcode}`;

export const createServerErrorMessage = (
  barcode: string,
  request_error_message?: string
): string =>
  `Error: Could not retrieve electronic request form for pot ${barcode}. ${request_error_message}. Please report this to technical support`;

export const createMultipleFormsFoundMessage = (
  barcode: string,
  request_forms_length?: number
): string =>
  `Error: Multiple electronic request forms found (${request_forms_length}) for pot ${barcode}. Please report this to technical support`;

function RequestFormRetrievalErrorMessage({
  error_type,
  barcode,
  request_error_message,
  request_forms_length,
}: {
  error_type: ErrorTypes;
  barcode: string;
  request_error_message?: string;
  request_forms_length?: number;
}): JSX.Element {
  const displayBarcode = getPotSerialNumberFromBarcode(barcode);
  const error_types_settings: ErrorTypesSettingsType = {
    NO_FORM_FOUND: {
      indicatorColour: "warning",
      icon: "fas fa-exclamation",
      error_message: createNoFormFoundMessage(displayBarcode),
    },
    SERVER_ERROR: {
      indicatorColour: "danger",
      icon: "far fa-times",
      error_message: createServerErrorMessage(displayBarcode, request_error_message),
    },
    MULTIPLE_FORMS_FOUND: {
      indicatorColour: "danger",
      icon: "far fa-times",
      error_message: createMultipleFormsFoundMessage(
        displayBarcode,
        request_forms_length
      ),
    },
  };

  const err_settings = error_types_settings[error_type];

  return (
    <div className="panel-block">
      <p
        className="has-text-weight-bold"
        data-testid={TEST_ID_PROCEDURE_DETAIL_API_URL_ERROR}
      >
        <span
          className={`icon is-small is-rounded tag is-${err_settings.indicatorColour}`}
        >
          <i className={err_settings.icon}></i>
          {/* IMPORTANT: spaces required for proper text spacing */}
        </span>{" "}
        {err_settings.error_message}{" "}
        {["SERVER_ERROR", "MULTIPLE_FORMS_FOUND"].includes(error_type) && <AsanaLink />}
        {/* IMPORTANT: spaces required for proper text spacing */}
      </p>
    </div>
  );
}
