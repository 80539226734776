import React from "react";

const invisible_spacer = <span className="has-text-white has-background-white">***</span>;

export const MacroReportForm = (): JSX.Element => {
  return (
    <div className="">
      <p className="is-size-5 has-text-weight-bold" id="requestFormMacroHeader">
        For lab use only
      </p>
      <div className="table-container">
        <table className="table is-size-7 is-fullwidth">
          <tbody>
            <tr>
              <th>Description:</th>
            </tr>
            <tr>
              <td>
                <span className="has-text-weight-bold">Primary sample type:</span>{" "}
                Cytology
                {invisible_spacer}
                <span className="has-text-weight-bold">
                  {" "}
                  Anatomic site of origin:
                </span>{" "}
                Oesophagus
              </td>
            </tr>
            <tr>
              <th>Macro description:</th>
            </tr>
            <tr>
              <th>{invisible_spacer}</th>
            </tr>
            <tr>
              <th>Date of sample processing: </th>
            </tr>
            <tr>
              <th>Prosector:</th>
            </tr>
            <tr>
              <th>Reporting consultant:</th>
            </tr>
            <tr>
              <th></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MacroReportForm;
