import React, { useState } from "react";
import { Link } from "react-router-dom";
import BigError from "../../atoms/BigError";
import { useMutation, gql } from "@apollo/client";
import { difference } from "lodash";
import { IContainer } from "../../types/order";
import ScanBarcodeForm from "../../atoms/ScanBarcodeForm";
import PotBarcode from "../Common/AttachmentCard";
import { ContainerType, IBatch } from "../../types/batch";

export const ADD_NEW_BARCODE = gql`
  mutation ScanBatchItem($item: ScanBatchItemInput!) {
    scanBatchItem(item: $item) {
      batchId
      batchIdentifier
      potLotNumber
      bdSolutionLotNumber
      bdSolutionExpiryDate
      status
      pots {
        barcode
        displayBarcode
        itemType
      }
    }
  }
`;

export const TEST_ID_NOT_SCANNED_TAB = "NotScannedTab";
export const TEST_ID_IN_BATCH_TAB = "InBatchTab";

interface IBarcodeList {
  batch: IBatch;
  onAttachmentAdded: () => void;
}

interface ITab {
  id: string;
  label: string;
  pots: IContainer[];
  isEmpty: boolean;
  isVisible: boolean;
  testId: string;
}

const BarcodeList = (props: IBarcodeList): JSX.Element => {
  const { batch, onAttachmentAdded } = props;
  const scannedPots: IContainer[] = batch.pots;
  const [addAttachmentMutation] = useMutation(ADD_NEW_BARCODE);
  const [isErrorMessageVisible, setErrorMessageVisible] = useState({
    visible: false,
    message: undefined,
  });

  // Computed properties needed to show a list of EndoSign pots which
  // were allocated to the batch on creation.
  const unscannedPotSerialNumbers: string[] = difference(
    batch.allocatedPots,
    scannedPots.map((pot) => pot.displayBarcode)
  );
  const unscannedPots: IContainer[] = unscannedPotSerialNumbers.map((barcode) => {
    return {
      displayBarcode: barcode,
      itemType: "PROCEDURE_POT",
    } as IContainer;
  });

  const tabs: ITab[] = [
    {
      id: "scanned",
      label: "In batch",
      pots: scannedPots,
      isEmpty: !scannedPots.length,
      isVisible: true,
      testId: TEST_ID_IN_BATCH_TAB,
    },
    {
      id: "unscanned",
      label: "Not scanned",
      pots: unscannedPots,
      isEmpty: !unscannedPots.length,
      isVisible: batch.containerType === ContainerType.ENDOSIGN,
      testId: TEST_ID_NOT_SCANNED_TAB,
    },
  ];
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);
  const currentTab = tabs.find((tab) => tab.id === activeTab) as ITab;

  const hideErrorMessage = () => {
    setErrorMessageVisible({ visible: false, message: undefined });
  };

  const addAttachment = (barcode: string) => {
    if (!isErrorMessageVisible.visible) {
      addAttachmentMutation({
        variables: {
          item: {
            itemBarcode: barcode,
            batchId: batch.batchId,
          },
        },
      })
        .then(() => {
          onAttachmentAdded();
        })
        .catch((error) => {
          console.log("Caught error!", { error: error.message });
          setErrorMessageVisible({ visible: true, message: error.message });
        });
    }
  };

  return (
    <nav className="panel">
      <BigError {...isErrorMessageVisible} dismiss={hideErrorMessage} />
      <p className="panel-heading">Add pot to batch</p>
      {!["CONFIRMED"].includes(batch.status) && (
        <div className="panel-block">
          <ScanBarcodeForm addBarcode={addAttachment} />
        </div>
      )}
      <p className="panel-tabs">
        {tabs
          .filter((tab) => tab.isVisible)
          .map((tab) => (
            <Link
              to="#"
              key={tab.id}
              className={tab.id === activeTab ? "is-active" : ""}
              onClick={() => setActiveTab(tab.id)}
              data-testid={tab.testId}
            >
              {tab.label} ({tab.pots.length})
            </Link>
          ))}
      </p>
      {currentTab.isEmpty && <p className="p-5">None</p>}
      {currentTab.pots.map((pot: IContainer) => (
        <PotBarcode key={pot.displayBarcode} attachment={pot} />
      ))}
    </nav>
  );
};

export default BarcodeList;
