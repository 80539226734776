import React, { useState, useEffect, PropsWithChildren } from "react";
import {
  isAuthenticated,
  isRequiredToChangePassword,
  getCurrentUser,
  logout,
} from "../services/auth.service";
import { User } from "../services/auth.service";
import { Link } from "react-router-dom";
import UserAvatarMenu from "../atoms/UserAvatarMenu";
import { Feature, useIsFeatureActive } from "../atoms/FeatureFlag";

export default function Navbar(props: PropsWithChildren<unknown>): JSX.Element {
  const [currentUser, setCurrentUser] = useState({} as User);

  useEffect(() => {
    const user = getCurrentUser();

    if (isAuthenticated(user)) {
      setCurrentUser(user);
    }
  }, [setCurrentUser]);

  // This is tab for the old inbound scan form.
  // TODO: remove this when the IncomingProceduresPage is no longer feature flagged
  const inboundLink = !useIsFeatureActive("PRINT_REQUEST_FORM") && (
    <Link className="navbar-item" to="/inbound">
      Inbound
    </Link>
  );

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a
          // This needs to be an anchor to link to external url
          className="navbar-item"
          href="https://cytedhealth.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/Cyted_logo_RGB_frameless.png"
            width="81"
            height="28"
            alt="Cyted logo"
          />
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          {isAuthenticated(currentUser) && !isRequiredToChangePassword(currentUser) && (
            <>
              <Link className="navbar-item" to="/orders">
                Orders
              </Link>
              {/* 
                This is the old inbound scan form. 
                TODO: remove this when the IncomingProceduresPage is no longer feature flagged
              */}
              {inboundLink}
              <Link className="navbar-item" to="/pots-management">
                Pots
              </Link>
              <Feature flag="PRINT_REQUEST_FORM" {...props}>
                <Link className="navbar-item" to="/procedures">
                  Procedures
                </Link>
              </Feature>
            </>
          )}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {isAuthenticated(currentUser) && <UserAvatarMenu logoutAction={logout} />}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
