import React, { useState, MouseEvent } from "react";
import { withRouter, Link } from "react-router-dom";
import { IMenuSections, IMenuItem, IPageView, IMenu, IPageWithMenu } from "../types/menu";

function PageView(props: IPageView): JSX.Element {
  const { currentComponentName, menuSections } = props;
  const [section, componentName] = currentComponentName.split("-");
  return menuSections[section][componentName].component;
}

function Menu(props: IMenu) {
  const { menuSections, onItemClick } = props;

  // create menuSections with blank className
  const blankState: IMenuSections = Object.entries(menuSections).reduce(
    (accum, [section, items]) => {
      const blankSection = Object.entries(items).reduce((acc, [key, value]) => {
        const blankValue = { ...value, className: "" };
        return { ...acc, [key]: blankValue };
      }, {});
      return { ...accum, [section]: blankSection };
    },
    {}
  );

  const onClickMenuItem = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const [section, id] = (event.target as HTMLAnchorElement).id.split("-");

    // set current menu item to active
    const currentActiveItem: IMenuItem = {
      [id]: {
        ...blankState[section][id],
        className: "is-active",
      },
    };

    const newSection: IMenuSections = {
      [section]: { ...blankState[section], ...currentActiveItem },
    };

    onItemClick({
      menuSections: { ...blankState, ...newSection },
      currentComponentName: (event.target as HTMLAnchorElement).id,
    });
  };

  const constructMenu = () => {
    // for each section create the links
    return Object.keys(menuSections).map((section: string) => {
      const links = Object.entries(menuSections[section]).reduce(
        (acc: JSX.Element[], [key, value]) => {
          const id = `${section}-${key}`;
          const link = (
            <Link
              id={id}
              key={key}
              className={value.className}
              to="#"
              onClick={onClickMenuItem}
            >
              {value.text}
            </Link>
          );
          return [...acc, link];
        },
        []
      );

      return (
        <React.Fragment key={section}>
          <p className="menu-label">{section}</p>
          <ul className="menu-list">
            <li>{links}</li>
          </ul>
        </React.Fragment>
      );
    });
  };

  return <aside className="menu">{constructMenu()}</aside>;
}

function PageWithMenu(props: IPageWithMenu): JSX.Element {
  const [menuSections, setMenuSections] = useState(props.menuStructureObj);
  const [currentComponentName, setCurrentComponentName] = useState(
    props.defaultComponentName
  );

  return (
    <div id={props.menuId} className="container columns">
      <div className="container column is-3 is-offset-1">
        <div className="sticky-top">
          <Menu
            menuSections={menuSections}
            currentComponentName={currentComponentName}
            onItemClick={(newInfo) => {
              setMenuSections(newInfo.menuSections);
              setCurrentComponentName(newInfo.currentComponentName);
            }}
          />
        </div>
      </div>
      <div className="container column is-8">
        <PageView
          menuSections={menuSections}
          currentComponentName={currentComponentName}
        />
      </div>
    </div>
  );
}

export default withRouter(PageWithMenu);
