import React from "react";

export const CONFIRM_ORDER_BUTTON = "ConfirmOrderButton";

export default function ConfirmOrderButton({
  confirmOrder,
  isLoading,
}: {
  isLoading?: boolean;
  confirmOrder: () => unknown;
}): JSX.Element {
  return (
    <div className="field">
      <p className="control">
        <button
          className={`button is-primary ${isLoading ? "is-loading" : ""}`}
          onClick={confirmOrder}
          data-testid={CONFIRM_ORDER_BUTTON}
        >
          <span> Confirm Order</span>
        </button>
      </p>
    </div>
  );
}
