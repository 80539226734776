import React, { useState } from "react";

export default function WorkInProgressWarning(): JSX.Element | null {
  const [visible, setVisible] = useState(process.env.REACT_APP_SHOW_DEV_MESSAGE || false);

  const hide = () => setVisible(false);

  if (visible) {
    return (
      <div className="media notification is-warning is-light">
        <div className="media-left">
          <span className="icon tag is-warning is-large is-rounded">
            <i className="fas fa-laptop-code" />
          </span>
        </div>
        <div className="media-content">
          <h1 className="is-size-5">This is Alpha Software</h1>
          <p>
            You{"'"}re using one of our development platforms. This means that the
            software running here is released ahead of the software running in our
            production platform and may contain bugs that are not present in the
            production deployment. Please feel free to play around on this deployment, and
            submit any feedback you have to the system administrator.
          </p>
          <div className="mt-4 buttons are-small">
            <a
              href="https://cytosponge-logistics.cytedhealth.com"
              className="button is-success"
            >
              Go to the Production Platform
            </a>
            <button className="button is-outlined" onClick={hide}>
              Dismiss
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
