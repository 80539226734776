import React from "react";
import PotDetail from "../Procedure/ScanIncomingProcedures/PotDetail";

export const TEST_ID_POT_DETAIL_MODAL = "POT_DETAIL_MODAL";

export const PotDetailsModal = ({
  potBarcode,
  closeAction,
}: {
  potBarcode?: string;
  closeAction: () => unknown;
}): JSX.Element | null => {
  if (!potBarcode) return null;
  if (!potBarcode.match(/\d{2}P\d{5}$/)) return null;

  return (
    <div className="modal is-active" data-testid={TEST_ID_POT_DETAIL_MODAL}>
      <div className="modal-background"></div>
      <div className="modal-content box">
        <PotDetail barcode={potBarcode} />
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeAction}
      ></button>
    </div>
  );
};
