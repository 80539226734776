import axios, { AxiosResponse } from "axios";
import React from "react";
import { jwtDecode } from "jwt-decode";

export const loginModalRef = React.createRef<HTMLDivElement>();

export const showLoginModal = (): void => {
  if (loginModalRef.current) {
    loginModalRef.current.className = "modal is-active";
  }
};

export const hideLoginModal = (): void => {
  if (loginModalRef.current) {
    loginModalRef.current.className = "modal";
  }
};

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/accounts/`;
export const SSO_LOGIN_PAGE = process.env.REACT_APP_SSO_LOGIN_PAGE || undefined;

export interface User {
  email?: string;
  token: string;
  token_expires_at: number;
  force_password_change: boolean;
}

function decodeToken(token: string) {
  try {
    return jwtDecode(token);
  } catch (error) {
    return error;
  }
}

const login = (email: string, password: string): Promise<AxiosResponse> => {
  return axios
    .post(API_URL + "auth-token/", {
      email,
      password,
    })
    .then((response) => {
      hideLoginModal();
      if (response.data.token) {
        setAuthToken(response.data.token);
      }

      return response.data;
    });
};

const setAuthToken = (stringToken: string): User => {
  const token = decodeToken(stringToken);
  const user: User = {
    email: token["cognito:username"],
    token: stringToken,
    token_expires_at: token.exp,
    force_password_change: token.force_password_change,
  };

  localStorage.setItem("user", JSON.stringify(user));

  return user;
};

const logout = (): void => {
  localStorage.removeItem("user");
  hideLoginModal();
  window.location.href = "/login";
};

const getCurrentUser = (): User => {
  return JSON.parse(localStorage.getItem("user") as string);
};

const isAuthenticated = (user: User): boolean => {
  if (user && user?.token) {
    const nowUnix = Math.round(new Date().getTime() / 1000); // cos JS time is in milliseconds
    return nowUnix < user.token_expires_at;
  }
  return false;
};

const isRequiredToChangePassword = (user: User): boolean => {
  return user && user.force_password_change;
};

export {
  login,
  logout,
  setAuthToken,
  getCurrentUser,
  isAuthenticated,
  isRequiredToChangePassword,
};
