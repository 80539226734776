import React, { FunctionComponent, ComponentClass } from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { WithRouterStatics } from "react-router";
import { getCurrentUser, isAuthenticated, logout } from "./auth.service";

interface ISecuredRoute extends RouteComponentProps {
  component:
    | (ComponentClass & WithRouterStatics<(props: RouteComponentProps) => JSX.Element>)
    | FunctionComponent;
  path: string;
  exact?: boolean;
}

const SecuredRoute: FunctionComponent<ISecuredRoute> = ({
  component: Component,
  exact,
  path,
}: ISecuredRoute) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        const user = getCurrentUser();
        if (!isAuthenticated(user)) {
          logout();
          return <div></div>;
        }
        return <Component />;
      }}
    />
  );
};

export default withRouter(SecuredRoute);
