import React from "react";
import {
  NewOrderPayload,
  BarrettsCaseDetails,
  PastDysplasiaDetails,
  PreviousEndoscopicTreatmentDetails,
  PatientDetails,
  InfectionDetails,
} from "../../../types/procedures";
import moment from "moment";

export const TEST_ID_PROCEDURE_DETAIL_TABLE = "procedure-detail-table";

const Unspecified = (): JSX.Element => (
  <span className="tag is-small has-text-weight-bold">
    <span className="icon mr-1">
      <i className="fas fa-question"></i>
    </span>
    Unspecified
  </span>
);

const FormattedDate = ({ date }: { date?: string }): JSX.Element => {
  if (date !== null || date !== undefined) {
    return (
      <span className="tag is-small has-text-weight-bold">
        <span className="icon mr-1">
          <i className="fa fa-calendar"></i>
        </span>
        {moment(date).format("DD/MM/YYYY")}
      </span>
    );
  } else {
    return <Unspecified />;
  }
};

const mapNullable = (val?: string): JSX.Element => {
  if (val === null || val === undefined) {
    return <Unspecified />;
  } else {
    return <>{val}</>;
  }
};

const mapBoolean = (val?: boolean): JSX.Element => {
  if (val === null || val === undefined) {
    return <Unspecified />;
  }

  if (val) {
    return (
      <span className="tag is-small has-text-weight-bold">
        <span className="icon mr-1">
          <i className="fas fa-check"></i>
        </span>
        Yes
      </span>
    );
  } else {
    return (
      <span className="tag is-small has-text-weight-bold">
        <span className="icon mr-1">
          <i className="fas fa-times"></i>
        </span>
        No
      </span>
    );
  }
};

function IsUrgent({ urgent }: { urgent?: boolean }) {
  if (!urgent) return <div data-testid="urgent"></div>;
  return (
    <div data-testid="urgent">
      The requesting clinician has indicated that this procedure is urgent.{" "}
    </div>
  );
}
function PragueClassification({
  known_barretts,
}: {
  known_barretts?: BarrettsCaseDetails;
}) {
  if (!known_barretts) return <Unspecified />;
  return (
    <>
      {known_barretts.known_case &&
      !(
        known_barretts.prague_classification === null ||
        known_barretts.prague_classification === undefined
      ) ? (
        <div className="is-flex is-justify-content-flex-start">
          <div className="" data-testid="prague_classification_circumferential">
            <span className="tag is-small has-text-weight-bold">C (cm): </span>
            {mapNullable(known_barretts.prague_classification?.circumferential)}
          </div>
          <div className="" data-testid="prague_classification_maximum">
            <span className="mr-1 ml-5 tag is-small has-text-weight-bold">M (cm): </span>
            {mapNullable(known_barretts.prague_classification?.maximum)}
          </div>
        </div>
      ) : (
        <Unspecified />
      )}
    </>
  );
}

function EndoscopicTreatment({
  previous_endoscopic_treatment,
}: {
  previous_endoscopic_treatment?: PreviousEndoscopicTreatmentDetails;
}) {
  if (previous_endoscopic_treatment === null) return <Unspecified />;
  return (
    <>
      <div>
        <div>{mapBoolean(previous_endoscopic_treatment?.known_procedure)}</div>
        <div
          className="to-title-case"
          data-testid="previous_endoscopic_treatment_details"
        >
          {mapNullable(previous_endoscopic_treatment?.procedure_details)}
        </div>
      </div>
    </>
  );
}

function DysplasiaGrade({
  dysplasia_in_past,
}: {
  dysplasia_in_past?: PastDysplasiaDetails;
}) {
  if (!dysplasia_in_past) return <Unspecified />;
  return (
    <>
      {dysplasia_in_past.known_case &&
      !(dysplasia_in_past.grade === null || dysplasia_in_past.grade === undefined) ? (
        <span>
          <span data-testid="dysplasia_in_past_known_case">
            {mapBoolean(dysplasia_in_past.known_case)}
          </span>
          <span className="mr-1 ml-5 tag is-small has-text-weight-bold">Grade:</span>
          <span data-testid="dysplasia_in_past_grade">
            {mapNullable(dysplasia_in_past.grade)}
          </span>
        </span>
      ) : (
        <span>
          <span data-testid="dysplasia_in_past_known_case">
            {mapBoolean(dysplasia_in_past.known_case)}
          </span>
          <span data-testid="dysplasia_in_past_grade">{dysplasia_in_past.grade}</span>
        </span>
      )}
    </>
  );
}

function InfectionRisk({ infection }: { infection?: InfectionDetails }) {
  if (infection === null) return <Unspecified />;
  return (
    <div>
      <div>{mapBoolean(infection?.risk_present)}</div>
      <div className="to-title-case" data-testid="infection_details">
        {mapNullable(infection?.details)}
      </div>
    </div>
  );
}

const KeyInformationBox = ({
  patient_details: { patient_identifier, patient_identifier_type, date_of_birth, sex },
  pot_barcode,
}: {
  patient_details: PatientDetails;
  pot_barcode: string;
}): JSX.Element => (
  <div>
    <div className="notification has-background-light is-hidden-to-print mb-3">
      <div className="has-text-grey has-text-weight-bold">Key information</div>
      <div className="columns is-flex is-justify-content-space-between is-hidden-to-print level is-size-5">
        <span className="mr-3 column level-item has-text-centred">
          <div className="has-text-grey is-size-7">Pot number: </div>
          <div className="has-text-weight-bold">{pot_barcode}</div>
        </span>
        <span className="mr-3 column level-item has-text-centred">
          <div className="has-text-grey is-size-7">Date of birth: </div>
          <div className="has-text-weight-bold is-size-5">
            {moment(date_of_birth).format("DD/MM/YYYY")}
          </div>
        </span>
        <span className="mr-3 column level-item has-text-centred">
          <div className="has-text-grey is-size-7">{patient_identifier_type} number:</div>
          <div className="has-text-weight-bold is-size-5">{patient_identifier}</div>
        </span>
        <span className="mr-3 column level-item has-text-centred">
          <div className="has-text-grey is-size-7">Sex:</div>
          <div className="has-text-weight-bold is-size-5 to-title-case is-lowercase">
            {sex}
          </div>
        </span>
      </div>
    </div>
  </div>
);

export default function createProcedureTable(obj: NewOrderPayload): JSX.Element {
  return (
    <div>
      <KeyInformationBox
        patient_details={obj.patient}
        pot_barcode={obj.clinical_information.cytosponge_procedure["pot_barcode"]}
      />
      <div
        data-testid="procedure_type"
        className="to-title-case to-title-case mb-2 has-text-grey has-text-weight-bold"
      >
        {obj.clinical_information.type} use
      </div>
      <div
        className="table-container columns"
        data-testid={TEST_ID_PROCEDURE_DETAIL_TABLE}
      >
        <div className="column is-half">
          <tbody>
            <tr>
              <th>Procedure details:</th>
              <td />
            </tr>
            <table className="table is-fullwidth is-hoverable is-striped is-size-7">
              <tr>
                <td>Date of procedure:</td>
                <td>
                  <FormattedDate date={obj.clinical_information.date_of_procedure} />
                </td>
              </tr>
              <tr>
                <td>Site:</td>
                <td>{obj.clinic_reference.site}</td>
              </tr>
              <tr>
                <td>Clinic:</td>
                <td>{obj.clinic_reference.clinic}</td>
              </tr>
              <tr>
                <td>Requesting clinician:</td>
                <td>{mapNullable(obj.clinical_information.requesting_clinician)}</td>
              </tr>
              <tr>
                <td>Nurse ID:</td>
                <td>
                  {mapNullable(obj.clinical_information.procedure_performed_by?.id)}
                </td>
              </tr>
              <tr>
                <td>Sample container barcode number:</td>
                <td>{obj.clinical_information.cytosponge_procedure.pot_barcode}</td>
              </tr>
              <tr>
                <td>Cytosponge device lot number:</td>
                <td>{obj.clinical_information.cytosponge_procedure.sponge_lot}</td>
              </tr>
            </table>
            <tr>
              <th>Patient details:</th>
              <td></td>
            </tr>
            <table className="table is-fullwidth is-hoverable is-striped is-size-7">
              <tr>
                <td> {obj.patient.patient_identifier_type} number:</td>
                <td>{obj.patient.patient_identifier}</td>
              </tr>
              <tr>
                <td>Date of birth</td>
                <td>
                  <FormattedDate date={obj.patient.date_of_birth} />
                </td>
              </tr>
              <tr>
                <td>Sex:</td>
                <td data-testid="sex" className="to-title-case is-lowercase">
                  {obj.patient.sex}
                </td>
              </tr>
            </table>
            <tr>
              <th>Clinical details:</th>
              <td></td>
            </tr>
            <table className="table is-fullwidth is-hoverable is-striped is-size-7">
              <tr>
                <td>Risk of infection:</td>
                <td>
                  <InfectionRisk infection={obj.clinical_information.infection} />
                </td>
              </tr>
              <tr>
                <td>Care referral</td>
                <td data-testid="care_referral" className="to-title-case is-lowercase">
                  {obj.clinical_information.care_referral.replace("_", " ")}
                </td>
              </tr>
              <tr>
                <td>Screening test referral</td>
                <td data-testid="screening_test_referral">
                  {mapBoolean(obj.clinical_information.screening_test_referral)}
                </td>
              </tr>
              <tr>
                <td>Symptomatic referral:</td>
                <td data-testid="symptomatic_referral">
                  {mapBoolean(obj.clinical_information.symptomatic_referral)}
                </td>
              </tr>
            </table>
          </tbody>
        </div>
        <div className="column is-half">
          <tbody>
            <tr>
              <th>Barretts oesophagus:</th>
              <td></td>
            </tr>
            <table className="table is-fullwidth is-hoverable is-striped is-size-7">
              <tr className="has-background-light has-text-weight-bold">
                <td>Known Case:</td>
                <td>{mapBoolean(obj.clinical_information.known_barretts?.known_case)}</td>
              </tr>
              <tr className="has-background-light has-text-weight-bold">
                <td>Prague classification:</td>
                <td data-testid="prague_classification_barretts">
                  <PragueClassification
                    known_barretts={obj.clinical_information.known_barretts}
                  />
                </td>
              </tr>
              <tr className="has-background-light has-text-weight-bold">
                <td>Dysplasia in past:</td>
                <td>
                  <DysplasiaGrade
                    dysplasia_in_past={obj.clinical_information.dysplasia_in_past}
                  />
                </td>
              </tr>
              <tr>
                <td>Patient weight:</td>
                <td data-testid="known_barretts_weight">
                  {mapNullable(obj.clinical_information.known_barretts?.weight)}
                </td>
              </tr>
              <tr>
                <td>Patient height:</td>
                <td data-testid="known_barretts_height">
                  {mapNullable(obj.clinical_information.known_barretts?.height)}
                </td>
              </tr>
              <tr>
                <td>Previous endoscopic treatment:</td>
                <td>
                  <EndoscopicTreatment
                    previous_endoscopic_treatment={
                      obj.clinical_information.previous_endoscopic_treatment
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>On acid suppressants:</td>
                <td data-testid="on_acid_suppressants">
                  {mapBoolean(obj.clinical_information.on_acid_suppressants)}
                </td>
              </tr>
            </table>
            <tr>
              <th>Other clinical information:</th>
              <td></td>
            </tr>
            <table className="table is-fullwidth is-hoverable is-striped is-size-7">
              <tr>
                <td>
                  <IsUrgent urgent={obj.clinical_information.urgent} />
                  {mapNullable(obj.clinical_information.other_clinical_information)}
                </td>
              </tr>
            </table>
          </tbody>
        </div>
      </div>
    </div>
  );
}
