import React from "react";
import { useMutation, gql } from "@apollo/client";

import { IOrder } from "../../types/order";
import { Link } from "react-router-dom";

export const TEST_ID_ACKNOWLEDGE_ORDER_BUTTON = "AcknowledgeOrderButton";

export const ACKNOWLEDGE_ORDER_MUTATION = gql`
  mutation AcknowledgeOrder($order: AcknowledgeOrderInput!) {
    acknowledgeOrder(order: $order)
  }
`;

interface OrderAcknowledgementCardProps {
  order: IOrder;
  onAcknowledge: () => void;
}

const OrderAcknowledgementCard = (props: OrderAcknowledgementCardProps): JSX.Element => {
  const { order, onAcknowledge } = props;
  const [acknowledgeOrderMutation, { loading }] = useMutation(
    ACKNOWLEDGE_ORDER_MUTATION,
    {
      update(cache, { data: { acknowledgeOrderMutation } }) {
        cache.modify({
          fields: {
            getOrders(existingOrderRefs = []) {
              const newOrderRef = cache.writeQuery({
                query: ACKNOWLEDGE_ORDER_MUTATION,
                data: acknowledgeOrderMutation,
              });
              return [...existingOrderRefs, newOrderRef];
            },
          },
        });
      },
    }
  );

  const acknowledgeOrder = () => {
    acknowledgeOrderMutation({
      variables: {
        order: { orderId: String(order.orderId) },
      },
    }).then(() => {
      onAcknowledge();
    });
  };

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box">
      <div className="field is-grouped">
        <div className="control">
          <button
            className={`button is-primary ${loading ? "is-loading" : ""}`}
            onClick={acknowledgeOrder}
            data-testid={TEST_ID_ACKNOWLEDGE_ORDER_BUTTON}
          >
            <span>Acknowledge Order</span>
          </button>
        </div>

        <div className="control">
          <Link className="button is-danger is-inverted" to="/orders">
            Back to orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderAcknowledgementCard;
