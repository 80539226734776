import React from "react";
import { getOrderStatusSettings, OrderStatusTag } from "./getOrderStatusSettings";
import { IOrder, OrderStatus } from "../../types/order";
import moment from "moment";

export const TEST_ID_ORDER_DETAIL_MAIN_CARD = "OrderDetailMainCard";

interface OrderDetailMainCardProps {
  order: IOrder;
}
const OrderDetailMainCard = (props: OrderDetailMainCardProps): JSX.Element => {
  const { order } = props;
  const settings = getOrderStatusSettings(order.status);

  return (
    <div className="box" data-testid={TEST_ID_ORDER_DETAIL_MAIN_CARD}>
      <div className="media">
        <div className="media-left">
          <OrderStatusTag settings={settings} orderId={String(order.orderId)} />
        </div>
        <div className="media-content">
          <div className="content">
            <p>
              <span className="has-text-weight-bold">Order ID: </span>
              {order.slug}
              <br />
              <span className="has-text-weight-bold">For:</span>{" "}
              {order.healthcareProvider.name} <br />
              <span className="has-text-weight-bold">
                Oesophageal cell sample collection kits:
              </span>{" "}
              {order.oesophagealCellSampleCollectionKitQuantity} <br />
              {![OrderStatus.SHIPPED].includes(order.status) && (
                <>
                  <span className="has-text-weight-bold">Shipping deadline:</span>{" "}
                  {moment(order.shippingDeadline).fromNow()}
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailMainCard;
