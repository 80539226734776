import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {
  getBatchStatusSettings,
  BatchStatusIndicator,
  BatchStatusTag,
} from "./getBatchStatusSettings";
import { IBatchStatusCard } from "../../types/batch";
import { getBatchDisplayLabel } from "../../helpers/batches";

export const TEST_ID_POT_BATCH_LIST_CARD = "PotBatchListCard";
export const TEST_ID_QUANTITY = "quantity";
export const TEST_ID_POT_BATCH_ID = "PotBatchId";

const BatchStatusCard: FunctionComponent<IBatchStatusCard> = ({
  batch,
}: IBatchStatusCard) => {
  const batchStatusSettings = getBatchStatusSettings(batch);

  return (
    <Link
      data-testid={`${TEST_ID_POT_BATCH_LIST_CARD}-${batch.batchId}`}
      className="panel-block is-active"
      to={`/pots-management/${batch.batchId}`}
    >
      <BatchStatusIndicator settings={batchStatusSettings} />
      <div className="column">
        <div>
          <p data-testid={TEST_ID_POT_BATCH_ID} className="has-text-weight-bold">
            {getBatchDisplayLabel(batch)}
          </p>
          <p>
            Fluid lot: {batch.batchIdentifier} -{" "}
            <span data-testid={TEST_ID_QUANTITY}>Quantity: {batch.countOfPots}</span>
          </p>
        </div>
      </div>
      <div className="mr-4">
        <BatchStatusTag settings={batchStatusSettings} />
      </div>
    </Link>
  );
};

export default BatchStatusCard;
