import { ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { IContainer } from "./order";

export interface IBatch {
  batchId: string;
  batchIdSlug: string;
  batchIdentifier: string;
  potLotNumber: string;
  bdSolutionLotNumber: string;
  bdSolutionExpiryDate: string;
  batchExpiryDate?: string;
  pots: IContainer[];
  status: string;
  countOfPots: number;
  manufacturerBatchNumber?: string;
  containerType: ContainerType;
  allocatedPots: string[];
}

export enum ContainerType {
  CYTOSPONGE = "CytospongePot",
  ENDOSIGN = "EndosignPreserve",
}

export interface IBatchStatusCard extends RouteComponentProps {
  batch: IBatch;
}

export interface IBatchStatusSettingsMap {
  [key: string]: BatchStatusSettings;
}

export interface BatchStatusSettings {
  icon: string;
  prettyStatus: string;
  indicatorColour: string;
  buttonIcon: string;
  buttonText: string;
  longPrettyStatus: string;
  instructionMessage: ReactNode;
}
