import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./Spinner";
import { gql, useQuery } from "@apollo/client";

export const TEST_ID_USER_AVATAR_MENU = "UserAvatarMenu";
export const TEST_ID_USER_AVATAR_MENU_BUTTON = "UserAvatarMenuButton";
export const TEST_ID_USER_ORGANIZATION = "UserOrganization";
export const TEST_ID_USER_NAME = "UserName";
export const TEST_ID_LOGOUT_BUTTON = "LogoutButton";
export const TEST_ID_STAFF_ICON = "StaffIcon";
export const TEST_ID_SUPERUSER_ICON = "SuperuserIcon";
export const TEST_ID_ADMIN_SETTINGS = "AdminSettings";

export const ME_QUERY = gql`
  query GetMe {
    me {
      id
      firstName
      lastName
      isStaff
      isSuperuser
      organisations {
        name
        organisationType
      }
    }
  }
`;

const OrganisationIcon = ({ organisationType }: { organisationType: string }) => {
  let icon = "fas fa-question";
  if (organisationType === "ADMIN") {
    icon = "fas fa-building";
  } else if (organisationType === "LAB") {
    icon = "fas fa-flask";
  } else if (organisationType === "HEALTHCARE") {
    icon = "fas fa-hospital";
  } else if (organisationType === "SSO") {
    icon = "fas fa-lock";
  }
  return (
    <span className="icon has-text-grey is-small mr-2">
      <i className={icon} />
    </span>
  );
};

const UserStatusView = ({
  firstName,
  lastName,
  isStaff,
  isSuperuser,
  organisations,
}: {
  firstName: string;
  lastName: string;
  isStaff: boolean;
  isSuperuser: boolean;
  organisations?: Array<{ name: string; organisationType: string }>;
}) => {
  return (
    <div className="media dropdown-item" style={{ minWidth: "500px" }}>
      <div className="media-left">
        <span className="mr-4 ml-4 mt-4 tag is-rounded icon is-large is-info">
          <i className="fas fa-user" />
        </span>
      </div>
      <div className="media-content">
        <h1 className="title" data-testid={TEST_ID_USER_NAME}>
          {firstName} {lastName}
        </h1>
        <h2 className="subtitle">
          <OrganisationIcon
            organisationType={organisations?.[0]?.organisationType || "SSO"}
          />
          <span data-testid={TEST_ID_USER_ORGANIZATION}>
            {organisations?.[0]?.name || "Cyted SSO"}
          </span>
        </h2>
        <p className="ml-4">
          {isStaff ? (
            <span className="tag mr-2" data-testid={TEST_ID_STAFF_ICON}>
              Staff
            </span>
          ) : null}
          {isSuperuser ? (
            <span className="tag mr-2 is-success" data-testid={TEST_ID_SUPERUSER_ICON}>
              Superuser
            </span>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default function UserAvatarMenu({
  logoutAction,
}: {
  logoutAction: () => void;
}): JSX.Element {
  const [isMenuActive, setMenuActive] = useState("");
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenuActive = () => {
    setMenuActive(isMenuActive ? "" : "is-active");
  };

  // close menu if user clicks outside
  const handleClickOutside = (e: MouseEvent) => {
    // cast target as Node because typings are poor
    // https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node
    // also see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/12239
    if (!menuRef.current?.contains(e.target as Node) && isMenuActive) {
      toggleMenuActive();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const { client, loading, error, data } = useQuery(ME_QUERY);

  if (loading) return <LoadingSpinner />;

  if (error)
    return (
      <span className="tag is-rounded icon is-large is-warning">
        <i className="fas fa-user-times" />
      </span>
    );

  const { isSuperuser } = data.me;

  return (
    <div
      className={`dropdown is-right ${isMenuActive}`}
      ref={menuRef}
      data-testid={TEST_ID_USER_AVATAR_MENU}
    >
      <div className="dropdown-trigger">
        <button
          id="profile-button"
          className="button is-white"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          data-testid={TEST_ID_USER_AVATAR_MENU_BUTTON}
          onClick={toggleMenuActive}
        >
          <span className="tag is-rounded icon is-large mr-0">
            <i className="fas fa-user" />
          </span>
          <span className="icon is-small ml-0">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <UserStatusView {...data.me} />
          <hr className="dropdown-divider" />
          <Link to="/settings" className="dropdown-item">
            {/* Only visible when a user is a member of staff */}
            User Settings
          </Link>
          {isSuperuser && (
            <Link
              to="/admin"
              className="dropdown-item"
              data-testid={TEST_ID_ADMIN_SETTINGS}
            >
              {/* Only visible if a user is a superuser */}
              Admin Settings
            </Link>
          )}
          <hr className="dropdown-divider" />
          <button
            id="logout"
            data-testid={TEST_ID_LOGOUT_BUTTON}
            className="dropdown-item button is-danger is-inverted"
            onClick={() => {
              client.resetStore().then(() => logoutAction());
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
