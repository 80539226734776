import React from "react";
import { getBatchStatusSettings, BatchStatusTag } from "./getBatchStatusSettings";
import { getBatchDisplayLabel } from "../../helpers/batches";
import { ContainerType, IBatch } from "../../types/batch";

export const TEST_ID_POT_BATCH_NUMBER = "potBatchNumber";

interface BatchDetailMainCardProps {
  batch: IBatch;
}

const BatchDetailMainCard = (props: BatchDetailMainCardProps): JSX.Element => {
  const { batch } = props;
  const settings = getBatchStatusSettings(batch);

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box container sticky-top">
      <h1 data-testid={TEST_ID_POT_BATCH_NUMBER} className="title is-size-2">
        Pot batch: {getBatchDisplayLabel(batch)}
      </h1>
      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <BatchStatusTag settings={settings} />
        </div>
        <dl className="column">
          <div>
            <dt>Container lot number</dt>
            <dd>{batch.potLotNumber}</dd>
          </div>
          <div>
            <dt>Preservative fluid lot number</dt>
            <dd>{batch.bdSolutionLotNumber}</dd>
          </div>
          <div>
            <dt>Preservative fluid expiry date</dt>
            <dd>{batch.bdSolutionExpiryDate}</dd>
          </div>
          {batch.containerType === ContainerType.ENDOSIGN && (
            <div>
              <dt>Batch expiry date</dt>
              <dd>{batch.batchExpiryDate}</dd>
            </div>
          )}
          {batch.status === "CONFIRMED" && (
            <div>
              <dt>Batch size</dt>
              <dd>{batch.countOfPots}</dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};

export default BatchDetailMainCard;
