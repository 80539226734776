import React from "react";
import { IOrder } from "../../types/order";
import moment from "moment";

interface OrderDeliveryDetailsCardProps {
  order: IOrder;
}

const OrderDeliveryDetailsCard = (props: OrderDeliveryDetailsCardProps): JSX.Element => {
  const { deliveryAddress, shippingDeadline } = props.order;
  return (
    // Only show box if at least one field has data
    deliveryAddress || shippingDeadline ? (
      <div className="box">
        {deliveryAddress && (
          <div className="container columns">
            <div className="container column is-one-third">
              <p className="has-text-weight-bold">Shipping address:</p>
            </div>

            <div className="container column is-two-thirds">
              <p>
                {deliveryAddress.firstName} {deliveryAddress.lastName}
              </p>
              <p>{deliveryAddress.dept}</p>
              <p>{deliveryAddress.lineOne}</p>
              <p>{deliveryAddress.lineTwo}</p>
              <p>{deliveryAddress.town}</p>
              <p>{deliveryAddress.county}</p>
              <p>{deliveryAddress.postcode}</p>
            </div>
          </div>
        )}
        {shippingDeadline && (
          <div className="container columns">
            <div className="container column is-one-third">
              <p className="has-text-weight-bold">Shipping deadline:</p>
            </div>
            <div className="container column is-two-thirds">
              <p>
                {/* Display date in format 18 Nov 2021 */}
                {moment(shippingDeadline).format("D MMM YYYY")}
              </p>
            </div>
          </div>
        )}
      </div>
    ) : (
      <></>
    )
  );
};

export default OrderDeliveryDetailsCard;
