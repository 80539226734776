import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GetPotDetailsQuery, PROCEDURE_STATUS } from "../../../types/procedures";
import { IHistoryItem } from "../../../types/history";
import { useReactToPrint } from "react-to-print";
import GetIncomingProcedureDetail from "./GetIncomingProcedureDetail";
import LoadingSpinner from "../../../atoms/Spinner";
import BigError from "../../../atoms/BigError";
import { ApolloError, gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  capitalizeFirstLetter,
  getPotSerialNumberFromBarcode,
} from "../../../helpers/strings";
import PrintablePotBarcode from "./PrintablePotBarcode";
import MacroReportForm from "./MacroReportForm";

export const TEST_ID_HEADING_PRINTED = "heading-printed";
export const TEST_ID_PROCEDURE_DETAIL_VIEW = "procedure-detail-view";
export const TEST_ID_PROCEDURE_POT_BARCODE = "procedure-pot-barcode";
export const TEST_ID_PROCEDURE_POT_BARCODE_PRINTED = "procedure-pot-barcode-printed";
export const TEST_ID_PROCEDURE_ACTUAL_POT_BARCODE_PRINTED =
  "procedure-actual-pot-barcode-printed";
export const TEST_ID_PROCEDURE_HCP_NAME = "procedure-healthcare-provider-name";
export const TEST_ID_PROCEDURE_HCP_NAME_PRINTED =
  "procedure-healthcare-provider-name-printed";
export const TEST_ID_PRINT_REQUEST_FORM_BUTTON = "print-request-form-button";
export const TEST_ID_PROCEDURE_ACCEPT_BUTTON = "procedure-accept-button";
export const TEST_ID_PROCEDURE_ACCEPT_CONFIRM_DIALOG = "procedure-accept-confirm-dialog";
export const TEST_ID_PROCEDURE_ACCEPT_CONFIRM_CHECKBOX =
  "procedure-accept-confirm-checkbox";
export const TEST_ID_PROCEDURE_ACCEPT_CONFIRM_CHECKBOX_PAPER_FORM =
  "procedure-accept-confirm-checkbox-paper-form";
export const TEST_ID_PROCEDURE_ACCEPT_CONFIRM_DIALOG_ACCEPT_BUTTON =
  "procedure-accept-confirm-dialog-accept-button";
export const TEST_ID_ACCEPTED_TAG = "accepted-tag";
export const TEST_ID_PROCEDURE_DEFER_BUTTON = "procedure-defer-button";
export const TEST_ID_PROCEDURE_DEFER_CONFIRM_DIALOG = "procedure-defer-confirm-dialog";
export const TEST_ID_DEFER_REASON_INPUT = "procedure-defer-reason-input";
export const TEST_ID_PROCEDURE_DEFER_CONFIRM_DIALOG_DEFER_BUTTON =
  "procedure-defer-confirm-dialog-defer-button";
export const TEST_ID_DEFERRED_TAG = "deferred-tag";
export const TEST_ID_ACCEPT_DEFERRED_INPUT = "accept-deferred-input";
export const TEST_ID_MACRO_REPORT_PRINTED = "macro-report-printed";

export const POT_DETAILS_QUERY = gql`
  query GetPotDetails($barcode: String!) {
    getProcedure(potBarcode: $barcode) {
      potBarcode
      procedureStatus
      order {
        orderId
        healthcareProvider {
          name
        }
      }
      batch {
        batchId
      }
      history {
        action
        performedBy {
          firstName
          lastName
        }
        comment
        timestamp
      }
    }
  }
`;

export const ACCEPT_POT_MUTATION = gql`
  mutation AcceptPot($barcode: String!, $reason: String!) {
    acceptProcedure(potBarcode: $barcode, reason: $reason) {
      potBarcode
      procedureStatus
      history {
        action
        performedBy {
          firstName
          lastName
        }
        comment
        timestamp
      }
    }
  }
`;

export const DEFER_POT_MUTATION = gql`
  mutation DeferPot($barcode: String!, $reason: String!) {
    deferProcedure(potBarcode: $barcode, reason: $reason) {
      potBarcode
      procedureStatus
      history {
        action
        performedBy {
          firstName
          lastName
        }
        comment
        timestamp
      }
    }
  }
`;

export const ME_QUERY = gql`
  query GetMe {
    me {
      firstName
      lastName
    }
  }
`;

const procedureStatus = ({
  procedureStatus,
}: {
  procedureStatus: string;
}): { isAccepted: boolean; isDeferred: boolean; isReceived: boolean } => {
  const isAccepted = procedureStatus === PROCEDURE_STATUS.ACCEPTED;
  const isDeferred = procedureStatus === PROCEDURE_STATUS.DEFERRED;
  const isReceived =
    isAccepted || isDeferred || procedureStatus === PROCEDURE_STATUS.RECEIVED;

  return {
    isAccepted,
    isDeferred,
    isReceived,
  };
};

interface IAcceptOrDeferAction extends GetPotDetailsQuery {
  isAcceptButtonDisabled: boolean;
  requestFormCount: number;
}

const AcceptOrDeferAction = ({
  getProcedure,
  isAcceptButtonDisabled,
  requestFormCount,
}: IAcceptOrDeferAction): JSX.Element | null => {
  const { potBarcode } = getProcedure;
  // Is this going to use the same query? Need to think about caching...
  const [isError, setError] = useState<ApolloError | undefined>(undefined);
  const [isAcceptConfirmVisible, setAcceptConfirmVisible] = useState(false);
  const [isDeferConfirmVisible, setDeferConfirmVisible] = useState(false);
  const [acceptanceReason, setAcceptanceReason] = useState("");
  const [deferReason, setDeferReason] = useState("");

  const [acceptPotMutation, { loading: accept_loading }] = useMutation(
    ACCEPT_POT_MUTATION,
    {
      variables: { barcode: potBarcode, reason: acceptanceReason },
    }
  );

  const [deferPotMutation, { loading: defer_loading }] = useMutation(DEFER_POT_MUTATION, {
    variables: { barcode: potBarcode, reason: deferReason },
  });

  const acceptPot = (ev: React.FormEvent) => {
    ev.preventDefault();
    acceptPotMutation()
      .then(() => {
        setAcceptConfirmVisible(false);
      })
      .catch((e: ApolloError) => setError(e));
  };

  const deferPot = (ev: React.FormEvent) => {
    ev.preventDefault();
    deferPotMutation()
      .then(() => {
        setDeferConfirmVisible(false);
      })
      .catch((e: ApolloError) => setError(e));
  };

  const { isAccepted, isDeferred, isReceived } = procedureStatus(getProcedure);

  if (isError) {
    return (
      <BigError
        visible={true}
        dismiss={() => setError(undefined)}
        message={isError.message}
      />
    );
  } else {
    return (
      <>
        <span
          className={`ml-1 dropdown ${isAcceptConfirmVisible && "is-active"}`}
          data-testid="procedure-accept-dropdown"
        >
          <span className="dropdown-trigger">
            <button
              className={`button is-success ${accept_loading ? "is-loading" : ""}`}
              disabled={
                isAcceptConfirmVisible ||
                isDeferConfirmVisible ||
                isAccepted ||
                !isReceived ||
                requestFormCount > 1 ||
                isAcceptButtonDisabled // TODO: remove this once there is functionality to select correct form
              }
              onClick={() => setAcceptConfirmVisible(!isAcceptConfirmVisible)}
              data-testid={TEST_ID_PROCEDURE_ACCEPT_BUTTON}
            >
              <span className="icon is-small">
                <i className="far fa-user-check" />
              </span>
              <span>Accept pot into lab</span>
            </button>
          </span>
          {isAcceptConfirmVisible && (
            <div
              className="dropdown-menu"
              style={{ minWidth: "400px" }}
              data-testid={TEST_ID_PROCEDURE_ACCEPT_CONFIRM_DIALOG}
            >
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <h2 className="title is-size-5">
                    Are you sure you want to accept this procedure?
                  </h2>
                </div>
                <div className="dropdown-item">
                  <p>
                    Please confirm that you wish to proceed. This will accept the pot into
                    the lab for processing, and sign off with your name.
                  </p>
                  {isDeferred && (
                    <p
                      data-testid="procedure-previous-deferred-warning"
                      className="notification is-light is-danger my-2"
                    >
                      This pot has previously been deferred. Only accept it if the issues
                      have been resolved. Please give a short description of why this pot
                      is being accepted.
                    </p>
                  )}
                </div>
                <form onSubmit={(ev) => acceptPot(ev)}>
                  <div className="dropdown-item">
                    {isDeferred && (
                      <input
                        className="input"
                        placeholder="Reason for accepting"
                        data-testid={TEST_ID_ACCEPT_DEFERRED_INPUT}
                        onChange={(ev: React.FormEvent<HTMLInputElement>) =>
                          setAcceptanceReason((ev.target as HTMLInputElement).value)
                        }
                      />
                    )}
                    {!isDeferred && requestFormCount === 0 && (
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          data-testid={
                            TEST_ID_PROCEDURE_ACCEPT_CONFIRM_CHECKBOX_PAPER_FORM
                          }
                          onChange={() =>
                            // toggle the acceptance reason
                            acceptanceReason
                              ? setAcceptanceReason("")
                              : setAcceptanceReason("Paper form present")
                          }
                        />
                        There is a paper form present
                      </label>
                    )}
                    {!isDeferred && requestFormCount === 1 && (
                      <label
                        className="checkbox"
                        data-testid={TEST_ID_PROCEDURE_ACCEPT_CONFIRM_CHECKBOX}
                      >
                        <input
                          type="checkbox"
                          onChange={() =>
                            // toggle the acceptance reason
                            acceptanceReason
                              ? setAcceptanceReason("")
                              : setAcceptanceReason(
                                  "Accepted with electronic request form"
                                )
                          }
                        />
                        Accept with the request form shown below
                      </label>
                    )}
                  </div>
                  <div className="dropdown-item">
                    <button
                      disabled={acceptanceReason.length === 0}
                      type="submit"
                      data-testid={TEST_ID_PROCEDURE_ACCEPT_CONFIRM_DIALOG_ACCEPT_BUTTON}
                      className={`button is-success ${
                        accept_loading ? "is-loading" : ""
                      }`}
                    >
                      Accept Procedure
                    </button>
                    <button
                      type="reset"
                      className="button is-outline"
                      onClick={() => setAcceptConfirmVisible(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </span>
        <span
          className={`ml-1 dropdown ${isDeferConfirmVisible && "is-active"}`}
          data-testid="procedure-defer-dropdown"
        >
          <span className="dropdown-trigger">
            <button
              className={`button is-danger ${defer_loading ? "is-loading" : ""}`}
              data-testid={TEST_ID_PROCEDURE_DEFER_BUTTON}
              disabled={
                isAcceptConfirmVisible ||
                isDeferConfirmVisible ||
                isDeferred ||
                isAccepted ||
                !isReceived
              }
              onClick={() => setDeferConfirmVisible(!isDeferConfirmVisible)}
            >
              <span className="icon is-small">
                <i className="far fa-ban" />
              </span>
              <span>Defer pot acceptance</span>
            </button>
          </span>
          {isDeferConfirmVisible && (
            <div
              className="dropdown-menu"
              style={{ minWidth: "400px" }}
              data-testid={TEST_ID_PROCEDURE_DEFER_CONFIRM_DIALOG}
            >
              <div className="dropdown-content">
                <div className="dropdown-item">
                  <h2 className="title is-size-5">
                    Are you sure you want to defer this procedure?
                  </h2>
                </div>
                <div className="dropdown-item">
                  <p>
                    Please confirm that you wish to proceed. This will flag the pot as
                    requiring attention to resolve an issue before it can be accepted for
                    processing, and sign off with your name.
                  </p>
                </div>
                <div className="dropdown-item">
                  <p>
                    Please give a short description of why this pot is being deferred.
                  </p>
                </div>
                <form onSubmit={(ev) => deferPot(ev)}>
                  <div className="dropdown-item">
                    <input
                      className="input"
                      placeholder="Reason for deferral"
                      data-testid={TEST_ID_DEFER_REASON_INPUT}
                      onChange={(ev: React.FormEvent<HTMLInputElement>) =>
                        setDeferReason((ev.target as HTMLInputElement).value)
                      }
                    />
                  </div>
                  <div className="dropdown-item">
                    <button
                      disabled={deferReason.length === 0}
                      type="submit"
                      data-testid={TEST_ID_PROCEDURE_DEFER_CONFIRM_DIALOG_DEFER_BUTTON}
                      className={`button is-danger ${defer_loading ? "is-loading" : ""}`}
                    >
                      Defer Procedure
                    </button>
                    <button
                      type="reset"
                      className="button is-outline"
                      onClick={() => setDeferConfirmVisible(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </span>
      </>
    );
  }
};

const StatusTags = ({ getProcedure }: GetPotDetailsQuery): JSX.Element | null => {
  const { isAccepted, isDeferred, isReceived } = procedureStatus(getProcedure);

  const receivedDate = getProcedure.history.filter((historyItem) => {
    return historyItem.action === "RECEIVED";
  })[0]?.timestamp;

  const acceptedDate = getProcedure.history.filter((historyItem) => {
    return historyItem.action === "ACCEPTED";
  })[0]?.timestamp;

  const deferredDate = getProcedure.history.filter((historyItem) => {
    return historyItem.action === "DEFERRED";
  })[0]?.timestamp;

  return (
    <>
      {isReceived ? (
        <div className="is-flex level-left is-flex-direction-column is-align-items-center">
          <div className="tag level-left" data-testid="received-tag">
            Received
          </div>
          <div className="is-size-7 mr-2 is-only-visible-to-print">
            {moment(receivedDate).format("l")}
          </div>
        </div>
      ) : (
        // This should never happen (unless we reuse this component in a generic "ViewPot" page)
        // However, PLAT-2812 revealed that looking up unreceived procedures crashed the front
        // end, so this tag is now visible in that scenario.
        <span className="tag level-left is-warning">Not yet received</span>
      )}
      {isAccepted ? (
        <div className="is-flex level-left is-flex-direction-column is-align-items-center">
          <div className="tag level-left is-success">
            <span className="icon is-small">
              <i className="fas fa-clipboard-check" />
            </span>
            <span data-testid={TEST_ID_ACCEPTED_TAG}>Accepted</span>
          </div>
          <div className="is-size-7 mr-2 is-only-visible-to-print">
            {moment(acceptedDate).format("l")}
          </div>
        </div>
      ) : (
        <div className="is-flex level-left is-flex-direction-column">
          <div className="tag is-warning">
            <span className="icon is-small">
              <i className="fas fa-exclamation-triangle" />
            </span>
            <span>Not yet accepted</span>
          </div>
          {/* Annoyingly required for proper spacing */}
          <div className="is-size-7 mr-2 is-only-visible-to-print">&nbsp;</div>
        </div>
      )}
      {isDeferred ? (
        <div className="is-flex level-left is-flex-direction-column">
          <div className="level-left tag is-danger">
            <span className="icon is-small">
              <i className="fas fa-ban" />
            </span>
            <span data-testid={TEST_ID_DEFERRED_TAG}>Issue must be resolved</span>
          </div>
          <div className="is-size-7 mr-2 is-only-visible-to-print">
            {moment(deferredDate).format("l")}
          </div>
        </div>
      ) : null}
    </>
  );
};

const PrintedProcedureEventHistoryList = ({
  history,
}: {
  history: IHistoryItem[];
}): JSX.Element => {
  // Note: [...history] notation is to create a copy of read only array
  const list = [...history]
    // sort events by date
    .sort((a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())
    .map((event, index) => {
      const { isAccepted: eventIsAccepted, isDeferred: eventIsDeferred } =
        procedureStatus({
          procedureStatus: event.action,
        });
      return (
        <React.Fragment key={`event_list_for_print_${index}`}>
          <div>
            {/* Event list for printed form only */}
            <div
              className={`${eventIsAccepted && "is-success"} ${
                eventIsDeferred && "is-danger"
              }`}
            >
              <div className="timeline-item">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <span
                    className="has-text-weight-bold"
                    data-testid="printed-event-history-action"
                  >
                    {capitalizeFirstLetter(event.action)}{" "}
                  </span>
                  <span className="has-text-weight-light">by </span>
                  <span>
                    {event.performedBy?.firstName} {event.performedBy?.lastName}
                  </span>
                  <span className="has-text-weight-light"> on </span>
                  <span>
                    {/* Displays date in format: APRIL 13TH 2021, 10:56:48 AM */}
                    {moment(event.timestamp).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                  {event.comment && (
                    <p data-testid="printed-event-history-reason">
                      Reason: {capitalizeFirstLetter(event.comment)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  // The <></> fragment is required to return a single node else TS complains
  return <>{list}</>;
};

const ProcedureEventHistoryList = ({
  history,
}: {
  history: IHistoryItem[];
}): JSX.Element => {
  // Note: [...history] notation is to create a copy of read only array
  const list = [...history]
    // sort events by date
    .sort((a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())
    .map((event, index) => {
      const { isAccepted: eventIsAccepted, isDeferred: eventIsDeferred } =
        procedureStatus({
          procedureStatus: event.action,
        });
      return (
        <React.Fragment key={`event_list_for_screen_${index}`}>
          {/* Event list for screen */}
          <div
            className={`is-hidden-to-print ${eventIsAccepted && "is-success"} ${
              eventIsDeferred && "is-danger"
            }`}
          >
            <div className="timeline-item">
              <div
                className={`timeline-marker ${eventIsAccepted && "is-success"} ${
                  eventIsDeferred && "is-danger"
                }`}
              ></div>
              <div className="timeline-content">
                <p className="heading">
                  {/* Displays date in format: TUESDAY, APRIL 13TH 2021, 10:56:48 AM */}
                  {moment(event.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                </p>
                <div>
                  <span data-testid="event-history-action">
                    <strong>{capitalizeFirstLetter(event.action)}</strong>
                  </span>
                  <span className="has-text-weight-light"> by </span>
                  {event.performedBy?.firstName} {event.performedBy?.lastName}
                </div>
                {event.comment && (
                  <p data-testid="event-history-reason">
                    Reason: {capitalizeFirstLetter(event.comment)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  // The <></> fragment is required to return a single node else TS complains
  return <>{list}</>;
};

const PotDetail = ({ barcode }: { barcode?: string }): JSX.Element => {
  const { error, loading, data } = useQuery<GetPotDetailsQuery>(POT_DETAILS_QUERY, {
    variables: { barcode },
  });
  const {
    loading: user_query_loading,
    error: user_query_error,
    data: user_query_data,
  } = useQuery(ME_QUERY);

  const [isAcceptButtonDisabled, setAcceptButtonDisabled] = useState(false);
  const [requestFormCount, setRequestFormCount] = useState(0);

  // This section, useState, useRef, handlePrint, useEffect is all to print
  // the pot detail & request form, and add in the current timestamp on printing
  // See https://github.com/gregnb/react-to-print/issues/169#issuecomment-546142576
  const [currentDateTime, setCurrentDateTime] = useState<{
    date: string;
    resolve: ((value: null) => void) | undefined;
  }>({
    date: new Date(Date.now()).toString(),
    resolve: undefined,
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        setCurrentDateTime({ date: new Date(Date.now()).toUTCString(), resolve });
      }),
  });

  useEffect(() => {
    currentDateTime.resolve && currentDateTime.resolve(null);
  }, [currentDateTime]);

  const printed_at_by = (current_user: { firstName: string; lastName: string }) => {
    const { firstName, lastName } = current_user;
    return (
      <div className="is-size-7 my-2 has-text-left is-only-visible-to-print">
        <p>Printed at: {currentDateTime.date}</p>
        <p>
          By: {firstName} {lastName}
        </p>
      </div>
    );
  };

  // EndoSign pots don't belong to logistics orders. This flag is used to hide
  // order-related elements and information, like the 'View order' link and
  // healthcareProvider.
  const isPotInLogisticsOrder = !!data?.getProcedure?.order;

  if (loading || user_query_loading) return <LoadingSpinner />;

  if (error || user_query_error) {
    return (
      <div className="notification is-light is-warning">
        <h2 className="is-size-3">An error occurred</h2>
        <p data-testid="procedure-details-error-warning">
          {error?.message || user_query_error?.message}
        </p>
      </div>
    );
  }

  if (data && user_query_data) {
    const potBarcode = data.getProcedure.potBarcode;

    // EndoSign UDIs are unwieldy, so extract the 23P20001 part for display
    const displayBarcode = getPotSerialNumberFromBarcode(potBarcode);
    document.title = "Pot number: " + displayBarcode;

    return (
      <div ref={ref} data-testid={TEST_ID_PROCEDURE_DETAIL_VIEW}>
        <div className="container">
          {/* Timestamp for printed form only */}
          {printed_at_by(user_query_data.me)}

          {/* Heading for screen display (not printed)*/}
          <div className="level mt-4 is-hidden-to-print">
            <div className="level-left columns">
              <div className="column is-narrow">
                {/* Vial icon */}
                <div>
                  <span className={`icon is-large is-rounded tag is-dark`}>
                    <i className="fas fa-vial" />
                  </span>
                </div>
              </div>
              <div className="column">
                <p className="title is-size-4">
                  <span data-testid={TEST_ID_PROCEDURE_POT_BARCODE}>
                    {displayBarcode}
                  </span>{" "}
                  {isPotInLogisticsOrder && (
                    <>
                      <span className="has-text-grey">from</span>{" "}
                      <span data-testid={TEST_ID_PROCEDURE_HCP_NAME}>
                        {data?.getProcedure.order?.healthcareProvider.name}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Heading for printed form only */}
          {/* For some reason is-only-visible-to-print only works in a separate div to columns */}
          <div className="is-only-visible-to-print" data-testid={TEST_ID_HEADING_PRINTED}>
            <div className="columns">
              <div className="column is-two-thirds">
                <p className="is-size-4 has-text-weight-bold">
                  Cytosponge pathology request form
                </p>
                <p className="is-size-6">
                  <span
                    className="is-hidden-to-print"
                    data-testid={TEST_ID_PROCEDURE_POT_BARCODE_PRINTED}
                  ></span>{" "}
                  {isPotInLogisticsOrder && (
                    <>
                      <span className="has-text-grey">from</span>{" "}
                      <span data-testid={TEST_ID_PROCEDURE_HCP_NAME_PRINTED}>
                        {data?.getProcedure.order?.healthcareProvider.name}
                      </span>
                    </>
                  )}
                </p>
                {/* Box for pot barcode (for printed form only) */}
                <div data-testid={TEST_ID_PROCEDURE_ACTUAL_POT_BARCODE_PRINTED}>
                  <PrintablePotBarcode barcode={potBarcode} />
                </div>
              </div>
            </div>
          </div>

          {/* Status tags & links */}
          <div>
            <div className="ml-1 my-2 tags">
              <StatusTags getProcedure={data.getProcedure} />
              <div className="is-hidden-to-print">
                {isPotInLogisticsOrder && (
                  <Link
                    to={`/orders/${data.getProcedure.order?.orderId}`}
                    data-testid="procedure-view-order-button"
                    className="button tag is-text is-small"
                    target="_blank"
                  >
                    View Order
                  </Link>
                )}
                {data.getProcedure.batch !== null ? (
                  <Link
                    to={`/pots-management/${data.getProcedure.batch.batchId}`}
                    data-testid="procedure-view-batch-button"
                    className="button tag is-text is-small"
                    target="_blank"
                  >
                    View Batch
                  </Link>
                ) : (
                  <button className="button tag is-text-small" disabled>
                    No Batch
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Event timeline */}
          <div className="my-1 is-hidden-to-print timeline">
            {
              // If there are state change events show them
              !!data.getProcedure.history?.length && (
                <ProcedureEventHistoryList history={data.getProcedure.history} />
              )
            }
          </div>
          <div className="buttons mt-1 is-hidden-to-print">
            {/* Button to print request form */}
            <AcceptOrDeferAction
              getProcedure={data.getProcedure}
              isAcceptButtonDisabled={isAcceptButtonDisabled}
              requestFormCount={requestFormCount}
            />
            {/* We'll want to clarify the logic around when this button is enabled or disabled as of https://cyted.atlassian.net/browse/PLAT-1863 */}
            <button
              className="ml-1 button is-info is-outlined"
              onClick={handlePrint}
              data-testid={TEST_ID_PRINT_REQUEST_FORM_BUTTON}
              disabled={requestFormCount !== 1 || data.getProcedure.history?.length === 1}
            >
              <span className="icon is-small">
                <i className="far fa-print" />
              </span>
              <span>Print request form</span>
            </button>
          </div>
          {/* Request form table */}
          <div className="mt-4">
            <GetIncomingProcedureDetail
              barcode={potBarcode}
              setAcceptButtonDisabled={setAcceptButtonDisabled}
              setRequestFormCount={setRequestFormCount}
            />
          </div>

          {/* Macro report section (for printed form only) */}
          <div
            className="mt-4 is-only-visible-to-print"
            data-testid={TEST_ID_MACRO_REPORT_PRINTED}
          >
            <MacroReportForm />
          </div>

          {/* Event timeline */}
          <div className="timeline mt-5 is-only-visible-to-print">
            <div className="is-size-7">
              {
                // If there are state change events show them
                data.getProcedure.history?.length && (
                  <PrintedProcedureEventHistoryList history={data.getProcedure.history} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // This shouldn't ever happen (Apollo errors should come up first)
    return <div>This is an error!</div>;
  }
};

export default PotDetail;
