import React from "react";
import { withRouter } from "react-router-dom";
import ChangePasswordForm from "./ChangePasswordForm";
import { IMenuSections } from "../../types/menu";
import PageWithMenu from "../../atoms/PageWithMenu";

const settingsMenuObj: IMenuSections = {
  Security: {
    ChangePasswordForm: {
      text: "Change Password",
      className: "is-active",
      component: <ChangePasswordForm />,
    },
  },
};

function SettingsPage() {
  return (
    <PageWithMenu
      menuStructureObj={settingsMenuObj}
      defaultComponentName="Security-ChangePasswordForm"
      menuId="settings"
    />
  );
}

export default withRouter(SettingsPage);
