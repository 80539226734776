import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../atoms/Spinner";
import { ME_QUERY } from "../../atoms/UserAvatarMenu";

export const TEST_ID_CLOSE_ORDER_BUTTON = "CloseOrderButton";

export const CLOSE_ORDER_MUTATION = gql`
  mutation CloseOrder($order: CloseOrderInput!) {
    closeOrder(order: $order)
  }
`;

export function CloseOrderButton({
  isLoading,
  closeOrder,
}: {
  isLoading?: boolean;
  closeOrder: () => unknown;
}): JSX.Element {
  const { loading, error, data } = useQuery(ME_QUERY);
  if (loading) return <LoadingSpinner />;

  if (error)
    return (
      <span className="tag is-rounded icon is-large is-warning">
        <i className="fas fa-user-times" />
      </span>
    );
  const { isSuperuser } = data.me;
  return (
    <>
      {isSuperuser && (
        /* Only visible if a user is a superuser */
        <div className="field">
          <div className="control">
            <button
              className={`button is-primary ${isLoading ? "is-loading" : ""}`}
              onClick={closeOrder}
              data-testid={TEST_ID_CLOSE_ORDER_BUTTON}
            >
              <span>Close Order</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

interface OrderCloseCardProps {
  onClose: () => void;
  isLoading: boolean;
}

const OrderCloseCard = (props: OrderCloseCardProps): JSX.Element => {
  const { onClose, isLoading } = props;

  return (
    // <!-- Main container -->
    // use bulma box class for the cards
    <div className="box">
      <div className="field is-grouped">
        <CloseOrderButton is-loading={isLoading} closeOrder={onClose} />
        <div className="control">
          <Link className="button is-danger is-inverted" to="/orders">
            Back to orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderCloseCard;
