import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  ServerError,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { getCurrentUser } from "./auth.service";
import { graphqlClientCacheConfig } from "./graphql.config";
import { onError } from "@apollo/client/link/error";
import { showLoginModal } from "./auth.service";

export const graphqlPath = "/api/procedures/graphql";
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}${graphqlPath}`,
});

const authLink = setContext((_, { headers }) => {
  // TODO: This happens on page load, and can cause the login modal to appear (if it doesn't fail)

  // get the authentication token from local storage if it exists
  const user = getCurrentUser();
  if (user) {
    const token = user.token;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  } else {
    return {};
  }
});

const errorLink = onError(({ networkError, operation, forward }) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    showLoginModal();

    console.error({ networkError });
  }

  return forward(operation);
});

const additiveLink = from([new RetryLink(), errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(graphqlClientCacheConfig),
});

// For use by Cypress
export function getNewClient(backendUrl: string): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([
      new RetryLink(),
      authLink,
      createHttpLink({
        uri: `${backendUrl}${graphqlPath}`,
      }),
    ]),
    cache: new InMemoryCache(graphqlClientCacheConfig),
  });
}
