import React from "react";
import Barcode from "react-barcode";

export const PrintablePotBarcode = ({ barcode }: { barcode: string }): JSX.Element => {
  return (
    <Barcode
      value={barcode}
      background={"#ffffff"}
      width={1.5} // (default is 2)
      height={33} // % of default
      margin={5} // on all sides (default is 10)
      fontSize={14} // (default is 20)
    />
  );
};

export default PrintablePotBarcode;
